/**
 * Renders non-reversal line items that are not listed in the
 * `LINE_ITEMS` array in util/types.js
 *
 * The line items are rendered so that the line item code is formatted to human
 * readable form and the line total is printed as price.
 *
 * If you require another kind of presentation for your line items, add them to
 * the `LINE_ITEMS` array in util/types.js and create a specific line item
 * component for them that can be used in the `OrderBreakdown` component.
 */
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faXmarkCircle } from '@fortawesome/free-solid-svg-icons';

import { intlShape, FormattedMessage } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { humanizeLineItemCode } from '../../util/data';
import { LINE_ITEM_GIFT_CARD, propTypes } from '../../util/types';

import { Button, Modal, SecondaryButton } from '../../components';

import css from './OrderBreakdown.module.css';

const LineItemGiftCardMaybe = props => {
  const { lineItems, isProvider, intl, onManageDisableScrolling, onDeleteGiftCard } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedGiftCard, setSelectedGiftCard] = useState(null);

  const allItems = lineItems.filter(item => LINE_ITEM_GIFT_CARD === item.code && !item.reversal);
  const items = isProvider
    ? allItems.filter(item => item.includeFor.includes('provider'))
    : allItems.filter(item => item.includeFor.includes('customer'));

  const openModal = () => {
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const deleteGiftCard = () => {
    onDeleteGiftCard()
    closeModal();

  };
  return items.length > 0 ? (
    <React.Fragment>
      {items.map((item, i) => {
        const label = `${humanizeLineItemCode(item.code)}`;

        const formattedTotal = formatMoney(intl, item.lineTotal);
        return (
          <div key={`${i}-item.code`} className={css.lineItem}>
            <span className={css.itemLabel}>{label}</span>
            <span className={css.itemValue}>{formattedTotal}</span>

            {onDeleteGiftCard && (
              <button onClick={openModal} className={css.itemRemove}>
                <FontAwesomeIcon icon={faXmarkCircle} />
              </button>
            )}
          </div>
        );
      })}

      <Modal
        id="LineItemGiftCardMaybeModal"
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
        }}
        usePortal
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <div className={css.modalDiv}>
          <div className={css.modalContainer}>
            <FontAwesomeIcon className={css.modalIcon} icon={faTrash} />
            <h4 className={css.modalTitle}>
              <FormattedMessage id="LineItemGiftCardMaybe.modalTitle" />
            </h4>
          </div>
          <p className={css.modalMessage}>
            <FormattedMessage id="LineItemGiftCardMaybe.modalContent" />
          </p>
          <div className={css.modalButtonsWrapper}>
            <SecondaryButton onClick={closeModal} className={css.cancelGiftCard} tabIndex="0">
              <FormattedMessage id="LineItemGiftCardMaybe.modalCancel" />
            </SecondaryButton>
            <Button className={css.submitButton} type="button" onClick={deleteGiftCard}>
              <FormattedMessage id="LineItemGiftCardMaybe.modalDelete" />
            </Button>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  ) : null;
};

LineItemGiftCardMaybe.propTypes = {
  lineItems: propTypes.lineItems.isRequired,
  code: propTypes.lineItemUnitType.isRequired,
  intl: intlShape.isRequired,
};

LineItemGiftCardMaybe.defaultProps = {
  showGiftDelete: false,
};
export default LineItemGiftCardMaybe;
