const storageKey = 'CheckoutPage';

export const getProductsFromStorage = () => {
  if (typeof window !== 'undefined' && window.sessionStorage && window.localStorage) {
    let data = sessionStorage.getItem(storageKey) || localStorage.getItem(storageKey);
    if (data) {
      try {
        return JSON.parse(data);
      } catch (error) {
        console.error('Error parsing data:', error);
        return null;
      }
    } else {
      return null;
    }
  } else {
    return null;
  }
};
