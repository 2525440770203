import { getFeatures } from '../../util/api';
import { storableError } from '../../util/errors';

// ================ Action types ================ //

export const FETCH_FEATURES_REQUEST = 'app/FeatureControlPage/FETCH_FEATURES_REQUEST';
export const FETCH_FEATURES_SUCCESS = 'app/FeatureControlPage/FETCH_FEATURES_SUCCESS';
export const FETCH_FEATURES_ERROR = 'app/FeatureControlPage/FETCH_FEATURES_ERROR';

export const UPDATE_FEATURES_REQUEST = 'app/FeatureControlPage/UPDATE_FEATURES_REQUEST';
export const UPDATE_FEATURES_SUCCESS = 'app/FeatureControlPage/UPDATE_FEATURES_SUCCESS';
export const UPDATE_FEATURES_ERROR = 'app/FeatureControlPage/UPDATE_FEATURES_ERROR';

// ================ Reducer ================ //

const initialState = {
  features: null,
  fetchFeaturesInProgress: false,
  fetchFeaturesError: null,
  updateFeaturesInProgress: false,
  updateFeaturesError: null,
};

const featureControlPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;

  switch (type) {
    case FETCH_FEATURES_REQUEST:
      return {
        ...state,
        fetchFeaturesInProgress: true,
        fetchFeaturesError: null,
      };
    case FETCH_FEATURES_SUCCESS:
      return {
        ...state,
        features: payload.data,
        fetchFeaturesInProgress: false,
      };
    case FETCH_FEATURES_ERROR:
      console.error(payload);
      return {
        ...state,
        fetchFeaturesInProgress: false,
        fetchFeaturesError: payload,
      };

    case UPDATE_FEATURES_REQUEST:
      return {
        ...state,
        updateFeaturesInProgress: true,
        updateFeaturesError: null,
      };
    case UPDATE_FEATURES_SUCCESS:
      return {
        ...state,
        features: payload.data,
        updateFeaturesInProgress: false,
      };
    case UPDATE_FEATURES_ERROR:
      console.error(payload);
      return {
        ...state,
        updateFeaturesInProgress: false,
        updateFeaturesError: payload,
      };
    default:
      return state;
  }
};

export default featureControlPageReducer;

// ================ Action creators ================ //

export const fetchFeaturesRequest = () => ({
  type: FETCH_FEATURES_REQUEST,
});

export const fetchFeaturesSuccess = response => ({
  type: FETCH_FEATURES_SUCCESS,
  payload: { data: response.data },
});

export const fetchFeaturesError = e => ({
  type: FETCH_FEATURES_ERROR,
  error: true,
  payload: e,
});

export const updateFeaturesRequest = () => ({
  type: UPDATE_FEATURES_REQUEST,
});

export const updateFeaturesSuccess = response => ({
  type: UPDATE_FEATURES_SUCCESS,
  payload: { data: response.data.attributes.profile.privateData.features },
});

export const updateFeaturesError = e => ({
  type: UPDATE_FEATURES_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

export const fetchFeatures = () => (dispatch, getState, sdk) => {
  dispatch(fetchFeaturesRequest());

  return getFeatures()
    .then(response => {
      dispatch(fetchFeaturesSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(fetchFeaturesError(storableError(e)));
      throw e;
    });
};

export const updateFeatures = features => (dispatch, getState, sdk) => {
  dispatch(updateFeaturesRequest());

  return sdk.currentUser
    .updateProfile(
      { privateData: { features: features } },
      {
        expand: true,
      }
    )
    .then(response => {
      dispatch(updateFeaturesSuccess(response.data));
      return response;
    })
    .catch(e => {
      dispatch(updateFeaturesError(storableError(e)));
      throw e;
    });
};

export const loadData = (params, search, config) => {
  return fetchFeatures();
};
