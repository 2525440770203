import React from 'react';
import PropTypes from 'prop-types';
import css from './PrivacyPolicy.module.css';
const PrivacyPolicy = () => {
  return (
    <div className={css.root}>
      <h2 className={css.title}>Privacy Policy</h2>
      <h5>Version 1.1</h5>
      <h5>Effective Date: June 1, 2020</h5>
      <h5>Last Revised: June 1, 2020</h5>
      <p>
        RareWaters. <b>( “RareWaters” ) </b>is committed to protecting your privacy. We have
        prepared this Privacy Policy (the “Policy”) to describe to you our practices regarding the
        Personal Data (as defined below) we collect from users of our Website, located at
        <a href="https://rarewaters.com/">www.rarewaters.com</a> (the “Website”) and Services which
        we provide. Any terms not defined herein shall have the meaning given to them in the
        associated Terms of Use available at
        <a href="https://rarewaters.com/terms-of-use/"> https://rarewaters.com/terms-of-use/</a>.
      </p>
      <p>
        This Policy describes the types of information we may collect from you or that you may
        provide when you visit our Website and our practices for collecting, using, maintaining,
        protecting, and disclosing that information.
      </p>
      <div className={css.listDiv}>
        <p>This policy applies to information we collect:</p>
        <ul>
          <li>On this Website.</li>
          <li>In email, text, and other electronic messages between you and this website.</li>
          <li>
            Through mobile and desktop applications you download from this Website or an application
            store, which provide dedicated non-browser-based interaction between you and this
            Website.
          </li>
          <li>
            When you interact with our advertising and applications on third-party websites and
            services, if those applications or advertising include links to the Website and this
            Policy.
          </li>
        </ul>
      </div>
      <div className={css.listDiv}>
        <p>This policy does not apply to information collected by:</p>
        <ul>
          <li>
            Us offline or through any other means, including on any other website operated by
            Company or any third party (including our affiliates and subsidiaries); or
          </li>
          <li>
            Any third party (including our affiliates and subsidiaries), including through any
            application or content (including advertising) that may link to or be accessible from or
            on the Website.
          </li>
        </ul>
      </div>
      <p>
        Please read this policy carefully to understand our policies and practices regarding your
        information and how we will treat it. If you do not agree with our policies and practices,
        your choice is not to use our Website. By accessing or using this Website, you agree to this
        privacy policy. This policy may change from time to time (see Changes to Our Privacy
        Policy). Your continued use of this Website after we make changes is deemed to be acceptance
        of those changes, so please check the policy periodically for updates.
      </p>
      <div className={css.mailAdressDiv}>
        <p>
          <b>Questions; Contacting RareWaters; Reporting Violations.</b> If you have any questions
          or concerns or complaints about our Privacy Policy, or our data collection and processing
          practices, or if you want to report any security violations to us, please contact us at
          the following e-mail or address:
        </p>
        <ul>
          <li>
            <a href="mailto: support@rarewaters.com">support@rarewaters.com</a>
          </li>
          <li>RareWaters</li>
          <li>Attn: Brenden Stucky</li>
          <li>3234 Navajo Street, Unit 510</li>
          <li>Denver, Colorado 80211</li>
        </ul>
      </div>
      <ul style={{ listStyleType: 'decimal' }}>
        <li>
          <b>User Consent.</b>By submitting Personal Data through our Website or Services, you agree
          to the terms of this Privacy Policy and you expressly consent to the collection, use and
          disclosure of your Personal Data in accordance with this Privacy Policy.
        </li>
        <li>
          <b>A Note About Children.</b>We do not intentionally gather Personal Data from visitors
          who are under the age of 13. If a child under 13 submits Personal Data to RareWaters and
          we learn that the Personal Data is the information of a child under 13, we will attempt to
          delete the information as soon as possible. If you are under 13, do not use or provide any
          information on this Website, or through any of its features, register on the website, make
          any purchases through the Website, use any of the interactive or public comment feature of
          this Website, or provide any information about yourself to us, including your name,
          address, telephone number, email address, or any screen name or user name you may use. If
          you believe that we might have any Personal Data from a child under 13, please contact us
          at
          <a href="mailto: support@rarewaters.com">support@rarewaters.com</a>.
        </li>
        <li>
          <b>A Note to Users Outside of the United States.</b>If you are a non U.S. user of the
          Website, by visiting the Website and providing us with data, you acknowledge and agree
          that your Personal Data may be processed for the purposes identified in the Privacy
          Policy. We may want to reword to comply with EU GDPR requirements. Even though Ops are
          based entirely in the US, if there are any EU users or even if the site is accessible or
          viewable from the EU by one of their citizens, they assert jurisdiction. By providing your
          data, you consent to such transfer.
        </li>
        <li>
          <b>Types of Data We Collect.</b>“Personal Data” means data that allows someone to identify
          or contact you, including, for example, your name, address, telephone number, e-mail
          address, as well as any other non-public information about you that is associated with or
          linked to any of the foregoing data. For Anglers, Personal Data also means: [EXPAND ON
          DATA COLLECTION]. “Anonymous Data” means data that is not associated with or linked to
          your Personal Data; Anonymous Data does not, by itself, permit the identification of
          individual persons. We collect Personal Data and Anonymous Data, as described below.
        </li>
      </ul>
      <ul style={{ listStyleType: 'disclosure-closed' }}>
        <li>
          <b> Information You Provide to Us.</b> <br></br>
          <ul style={{ listStyleType: 'square', marginLeft: '30px' }}>
            <li>
              We may collect Personal Data from you, such as your first and last name, gender,
              e-mail and mailing addresses, company name, and password when you create an account to
              log in to our network <b>( “Account”)</b>.
            </li>
            <li>
              If you use our Services on your mobile device, we may collect your phone number and
              the unique device id number.
            </li>
            <li>
              If you tell us where you are (e.g., by allowing your mobile device to send us your
              location), we may store and use that information to provide you with location-based
              information and advertising. If you want to deactivate this feature, you can
              deactivate GPS on your mobile device.
            </li>
            <li>
              When connecting to our Services via a service provider that uniquely identifies your
              mobile device, we may receive this identification and use it to offer extended
              services and/or functionality.
            </li>
            <li>
              When you order services on our Website, we will collect all information necessary to
              complete the transaction, including your name, credit card information, billing
              information, and shipping information. We do not store this information directly on
              our servers, but this information may be shared with third parties who will help
              process and fulfill your purchases. When you submit credit card numbers, we encrypt
              that information using industry-standard technology.
            </li>
            <li>
              We retain information on your behalf, such as files and messages that you store using
              your Account.
            </li>
            <li>
              If you provide us feedback or contact us via e-mail, we will collect your name and
              e-mail address, as well as any other content included in the e-mail, in order to send
              you a reply.
            </li>
            <li>
              When you post content (text, images, photographs, messages, comments, or any other
              kind of content that is not your e-mail address) on our Website, the information
              contained in your posting will be stored on our servers, and other users will be able
              to see it, along with your profile photo and any other information that you choose to
              make public on your public profile page <b>("Profile")</b> . The information that you
              provide in your Profile will be visible to others, including anonymous visitors to the
              Website.
            </li>
            <li>
              When you post messages on the message boards of our Website, the information contained
              in your posting will be stored on our servers, and other users will be able to see it.
            </li>
            <li>
              When you participate in one of our surveys, we may collect additional profile
              information.
            </li>
            <li>
              We also collect other types of Personal Data that you provide to us voluntarily, such
              as your operating system and version, product registration number, and other requested
              information if you contact us via e-mail regarding support for the Services.
            </li>
            <li>
              If you participate in a sweepstakes, contest, or giveaway on our Website, we may ask
              you for your e-mail address and/or home number (to notify you if you win or not). We
              may also ask for first and last names, and sometimes post office addresses to verify
              your identity. In some situations, we may need additional information as a part of the
              entry process, such as a prize selection choice. These sweepstakes and contests are
              voluntary. We recommend that you read the rules for each sweepstakes and contest that
              you enter.
            </li>
            <li>
              We may also collect Personal Data at other points on our Website that state that
              Personal Data is being collected.
            </li>
          </ul>
        </li>
        <li>
          <b> Information You Provide to Facebook and other Social Networking Websites.</b>
          <br></br>The Services allow users to access Facebook to interact with friends and to share
          on Facebook through Wall and friends’ News Feeds. If you are already logged into the
          Services and Facebook or another SNS, when you click on “Connect with Facebook,” or a
          similar connection on another SNS, you will be prompted to merge your profiles. If you are
          already logged into the Services but not logged into Facebook or another SNS that we
          support, when you click on “Connect with Facebook,” or a similar connection on another
          SNS, you will be prompted to enter your SNS credentials or to “Sign Up” for the SNS. By
          proceeding, you are allowing the Services to access your information and you are agreeing
          to the Facebook or other SNS’s Terms of Use in your use of the Services. Conversely, if
          you are not currently registered as a user of the Services, and you click on “Sign in”
          using Facebook or another SNS that we support, you will first be asked to enter your
          Facebook or SNS credentials and then be given the option to register for the Services. In
          this case, we may receive information from Facebook or another SNS to make it easier for
          you to create an Account on the Website and show our relevant content from your Facebook
          or SNS friends. Once you register on the Website and connect with Facebook or another SNS,
          you will be able to automatically post recent activity back to Facebook or the other SNS.
          Any information that we collect from your Facebook or other SNS account may depend on the
          privacy settings you have with that SNS, so please consult the SNS’s privacy and data
          practices. You have the option to disable Facebook Connect at any time by logging into
          your Account through the Website and going to “Manage account” from the drop-down menu,
          and then clicking “remove” next to Facebook. Further, you can edit privacy settings for
          the reviews that appear on Facebook or disconnect your Services activity stream by
          visiting the Facebook Applications Settings page.
        </li>
        <li>
          <b> Information Collected via Technology.</b>
          <ul style={{ listStyleType: 'square', marginLeft: '30px' }}>
            <li>
              <b>Information Collected by Our Servers.</b>
              To make our Website and Services more useful to you, our servers (which may be hosted
              by a third party service provider) collect information from you, including your
              browser type, operating system, Internet Protocol (“IP”) address (a number that is
              automatically assigned to your computer when you use the Internet, which may vary from
              session to session), domain name, and/or a date/time stamp for your visit.
            </li>
            <li>
              <b>Log Files.</b>
              As is true of most websites, we gather certain information automatically and store it
              in log files. This information includes IP addresses, browser type, Internet service
              provider (“ISP”), referring/exit pages, operating system, date/time stamp, and
              clickstream data. We use this information to analyze trends, administer the Website,
              track users’ movements around the Website, gather demographic information about our
              user base as a whole, and better tailor our Services to our users’ needs. For example,
              some of the information may be collected so that when you visit the Website or the
              Services again, it will recognize you and the information could then be used to serve
              advertisements and other information appropriate to your interests. Except as noted in
              this Privacy Policy, we do not link this automatically-collected data to Personal
              Data.
            </li>
            <li>
              Like many online services, we use cookies to collect information.
              <b>“Cookies” </b>are small pieces of information that a website sends to your
              computer’s hard drive while you are viewing the website. We may use both session
              Cookies (which expire once you close your web browser) and persistent Cookies (which
              stay on your computer until you delete them) to provide you with a more personal and
              interactive experience on our Website. This type of information is collected to make
              the Website more useful to you and to tailor the experience with us to meet your
              special interests and needs.
            </li>
            <li>
              <b>Pixel Tags.</b>
              In addition, we use <b> “Pixel Tags”</b> (also referred to as clear Gifs, Web beacons,
              or Web bugs). Pixel Tags are tiny graphic images with a unique identifier, similar in
              function to Cookies, that are used to track online movements of Web users. In contrast
              to Cookies, which are stored on a user’s computer hard drive, Pixel Tags are embedded
              invisibly in Web pages. Pixel Tags also allow us to send e-mail messages in a format
              users can read, and they tell us whether e-mails have been opened to ensure that we
              are sending only messages that are of interest to our users. We may use this
              information to reduce or eliminate messages sent to a user. We do not tie the
              information gathered by Pixel Tags to our users’ Personal Data.
            </li>
          </ul>
        </li>
        <li>
          <b>How We Respond to Do Not Track Signals.</b>
          <br></br>We do not currently respond to “do not track” signals or other mechanisms that
          might enable Users to opt out of tracking on our site.
        </li>
      </ul>
      <ul style={{ listStyleType: 'disclosure-closed' }}>
        <li>
          <b>Analytics Services and Interest-Based Advertising.</b>
          <br></br>In addition to the tracking technologies we place, other companies may set their
          own cookies or similar tools when you visit our Website. This includes third party
          analytics services, including but not limited to Google Analytics
          <b>( “Analytics Services” )</b> , that we engage to help analyze how users use the
          Website, as well as third parties that deliver content or offers. We may receive reports
          based on these parties’ use of these tools on an individual or aggregate basis. We use the
          information we get from Analytics Services only to improve our Website and Services. The
          information generated by the Cookies or other technologies about your use of our Website
          and Services <b>(the “Analytics Information” )</b> is transmitted to the Analytics
          Services. The Analytics Services use Analytics Information to compile reports on user
          activity. The Analytics Services may also transfer information to third parties where
          required to do so by law, or where such third parties process Analytics Information on
          their behalf. Each Analytics Services’ ability to use and share Analytics Information is
          restricted by such Analytics Services’ Terms of Use and Privacy Policy. By using our
          Website and Services, you consent to the processing of data about you by Analytics
          Services in the manner and for the purposes set out above. For a full list of Analytics
          Services, please contact us at{' '}
          <a href="mailto: support@rarewaters.com">support@rarewaters.com</a>. We may also partner
          with ad companies to support out marketing efforts, including by serving you ads better
          tailored to your likely interests. If you don’t want to take advantage of these services,
          you may be able to opt-out by visiting
          <a href="http://www.aboutads.info">http://www.aboutads.info</a> or
          <a href="http://networkadvertising.org/choices">http://networkadvertising.org/choices</a>.
        </li>
        <li>
          <b>Information Collected from You About Others.</b>
          <br></br>
          If you decide to invite a third party to create an Account, we will collect your and the
          third party’s names and e-mail addresses in order to send an e-mail and follow up with the
          third party. We rely upon you to obtain whatever consents from the third party that may be
          required by law to allow us to access and upload the third party’s names and e-mail
          addresses as required above. You or the third party may contact us at
          <a href="mailto: support@rarewaters.com">support@rarewaters.com</a> to request the removal
          of this information from our database. As part of your use of the Services, we provide you
          the opportunity to connect automatically with your friends. We employ various techniques
          in order to facilitate friends finding each other on our service, including offering
          contact importer tools to facilitate adding to your contacts (including Facebook Friends
          and contacts in your address book) so that you can more readily ask your contacts to join
          and communicate with you through the Services. With your permission, we will access your
          address book, call log and SMS log, and import your contacts’ names, e-mail addresses,
          phone numbers, image, geographic location and Facebook IDs to facilitate automatic
          connection with your friends. We do this only for contact matching to help your friends
          and you find each other. Also, when you invite friends to the Services, we will access
          your Address Book and we will import your contacts names and phone numbers in order to
          facilitate the invitation. The e-mail that is sent to your friends will come from your
          e-mail address so that your friends know that you want to invite them to visit the
          Website. We do not store any passwords you provide as part of the contact import process.
          We also collect some settings that help us to interpret your data, such as the language
          and keyboard settings that you have established, and the phone’s carrier, mobile network
          code and mobile country code.
          <ul style={{ listStyleType: 'square', marginLeft: '30px' }}>
            <li>
              <b>Google ID.</b>
              <br></br>We allow you to use your Google ID to set up an Account. If you wish to use
              your Google account you are taken to the Google website to login to your account and
              this information is then shared with us for the sole purpose of setting up your
              Account.
            </li>
            <li>
              <b>Location Information.</b>
              <br></br>If you have enabled location services on your phone, we collect your location
              information to make a map more relevant to your location. If you do not want this
              information collected by us, you can disable location services on your phone.
            </li>
          </ul>
        </li>
      </ul>
      <ul style={{ listStyleType: 'decimal' }}>
        <li>
          <b>Use of Your Personal Data</b> <br></br>
          <ul style={{ listStyleType: 'circle', marginLeft: '20px' }}>
            <li>
              <b>General Use.</b> <br></br>
              In general, Personal Data you submit to us is used either to respond to requests that
              you make, or to aid us in serving you better. We use your Personal Data in the
              following ways:
              <ul style={{ listStyleType: 'square', marginLeft: '20px' }}>
                <li>Facilitate the creation of and secure your Account on our network;</li>

                <li>Identify you as a user in our system;</li>
                <li>Provide improved administration of our Website and Services;</li>
                <li>Provide the Services you request;</li>
                <li>
                  Improve the quality of experience when you interact with our Website and Services;
                </li>
                <li>
                  Send you a welcome e-mail to verify ownership of the e-mail address provided when
                  your Account was created;
                </li>
                <li>
                  Send you administrative e-mail notifications, such as security or support and
                  maintenance advisories;
                </li>
                <li>
                  Respond to your inquiries related to employment opportunities or other requests;
                </li>
                <li>
                  Make telephone calls to you, from time to time, as a part of secondary fraud
                  protection or to solicit your feedback;
                </li>
                <li>
                  Put you in touch with landowners and/or have landowners get in touch with you
                  about your booking;
                </li>
                <li>
                  Send newsletters, surveys, offers, and other promotional materials related to our
                  Services and for other marketing purposes of RareWaters;
                </li>
                <li>
                  Provide necessary information to relevant parties involved in a booking you’ve
                  made on the RareWaters marketplace.
                </li>
              </ul>
            </li>
            <li>
              <b>User Testimonials and Feedback.</b> <br></br>
              We often receive testimonials and comments from users who have had positive
              experiences with our Services. We occasionally publish such content. When we publish
              this content, we may identify our users by their first and last name and may also
              indicate their home city. We obtain the user’s consent prior to posting his or her
              name along with the testimonial. We may post user feedback on the Website from time to
              time. We will share your feedback with your first name and last initial only. If we
              choose to post your first and last name along with your feedback, we will obtain your
              consent prior to posting you name with your feedback. If you make any comments on a
              blog or forum associated with your Website, you should be aware that any Personal Data
              you submit there can be read, collected, or used by other users of these forums, and
              could be used to send you unsolicited messages. We are not responsible for the
              personally identifiable information you choose to submit in these blogs and forums.
            </li>
            <li>
              <b>Creation of Anonymous Data.</b> <br></br>
              We may create Anonymous Data records from Personal Data by excluding information (such
              as your name) that makes the data personally identifiable to you. We use this
              Anonymous Data to analyze request and usage patterns so that we may enhance the
              content of our Services and improve Website navigation. We reserve the right to use
              Anonymous Data and aggregated and other de-identified information for any purpose and
              disclose Anonymous Data to third parties in our sole discretion.
            </li>
          </ul>
        </li>
        <li>
          <b>Disclosure of Your Personal Data.</b>
          <br></br>We disclose your Personal Data as described below and as described elsewhere in
          this Privacy Policy.
          <ul style={{ listStyleType: 'circle', marginLeft: '20px' }}>
            <li>
              We will share your Personal data with other Users solely for the purpose of providing
              the Services.
            </li>
            <li>
              <b>Third Party Service Providers.</b>We may share your Personal Data with third party
              service providers, contractors, and other third parties to: provide you with the
              Services that we offer you through our Website; to conduct quality assurance testing;
              to facilitate creation of accounts; to provide technical support; and/or to provide
              other services to the RareWaters. These third party service providers are required not
              to use your Personal Data other than to provide the services requested by RareWaters.
            </li>
            <li>
              We may share some or all of your Personal Data with aparent company, subsidiaries,
              joint ventures, or other companies under a common control <b>( “Affiliates” )</b>, in
              which case we will require our Affiliates to honor this Privacy Policy.
            </li>
            <li>
              <b>Corporate Restructuring.</b> We may share some or all of your Personal Data in
              connection with or during negotiation of any merger, financing, acquisition or
              dissolution transaction or proceeding involving sale, transfer, divestiture, or
              disclosure of all or a portion of our business or assets. In the event of an
              insolvency, bankruptcy, or receivership, Personal Data may also be transferred as a
              business asset. If another company acquires our company, business, or assets, that
              company will possess the Personal Data collected by us and will assume the rights and
              obligations regarding your Personal Data as described in this Privacy Policy.
            </li>
            <li>
              <b> Other Third Parties. </b>
              We may provide your Personal Data with third parties to market their services or
              products to you if you have not opted out of these disclosures. We contractually
              require these third parties to keep personal information confidential and use it only
              for the purposes for which we disclose it to them.
            </li>
            <li>
              <b> Social Networking Websites. </b>
              Some of our Services may enable you to post content to SNSs (e.g., Facebook or
              Twitter). If you choose to do this, we will provide information to such SNSs in
              accordance with your elections. You acknowledge and agree that you are solely
              responsible for your use of those websites and that it is your responsibility to
              review the terms of use and privacy policy of the third party provider of such SNSs.
              We will not be responsible or liable for: (i) the availability or accuracy of such
              SNSs; (ii) the content, products or services on or availability of such SNSs; or (iii)
              your use of any such SNSs.
            </li>
            <li>
              <b>Public Profile.</b>Certain portions of the information you provide to us may also
              be displayed in your Profile. As an essential element of the Services, most of the
              Personal Data you explicitly provide to us when you register or update your Profile is
              displayed on your Profile. In order for your Profile to be made public, you must go to
              your profile settings and then to profile visibility. By default, your Profile is not
              for public viewing. Your photos, posts, friends, and other content you post to the
              Website are also meant for public consumption. We may display this content on the
              Website and further distribute it to a wider audience through third party sites and
              services. Once displayed on publicly viewable web pages, that information can be
              collected and used by others. We cannot control who reads your postings or what other
              users may do with the information that you voluntarily post, so it is very important
              that you do not put Personal Data in your posts. Once you have posted information
              publicly, while you will still be able to edit and delete it on the Website, you will
              not be able to edit or delete such information cached, collected, and stored elsewhere
              by others (e.g., search engines).
            </li>
            <li>
              <b> Other Disclosures.</b>
              Regardless of any choices you make regarding your Personal Data (as described below),
              RareWaters may disclose Personal Data if it believes in good faith that such
              disclosure is necessary (a) in connection with any legal investigation; (b) to comply
              with relevant laws or to respond to subpoenas or warrants served on RareWaters; (c) to
              protect or defend the rights or property of RareWaters or users of the Website or
              Services (this includes exchanging information with other companies and organizations
              for the purposes of fraud protection and credit risk reduction; and/or (d) to
              investigate or assist in preventing any violation or potential violation of the law,
              this Privacy Policy, or our Terms of Use.
            </li>
            <li>
              {' '}
              <b> Third Party Websites.</b>
              Our Website may contain links to third party websites. When you click on a link to any
              other website or location, you will leave our Website and go to another site, and
              another entity may collect Personal Data or Anonymous Data from you. We have no
              control over, do not review, and cannot be responsible for, these outside websites or
              their content. Please be aware that the terms of this Privacy Policy do not apply to
              these outside websites or content, or to any collection of your Personal Data after
              you click on links to such outside websites. We encourage you to read the privacy
              policies of every website you visit. The links to third party websites or locations
              are for your convenience and do not signify our endorsement of such third parties or
              their products, content or websites.
            </li>
          </ul>
        </li>
        <li>
          <b>Your Choices Regarding Information.</b>
          <br></br>You have several choices regarding the use of information on our Service:
          <ul style={{ listStyleType: 'circle', marginLeft: '20px' }}>
            <li>
              <b>Email Communications.</b>We will periodically send you free newsletters and e-mails
              that directly promote the use of our Website or Services. When you receive newsletters
              or promotional communications from us, you may indicate a preference to stop receiving
              further communications from us and you will have the opportunity to “opt-out” by
              following the unsubscribe instructions provided in the e-mail you receive or by
              contacting us directly (please see contact information below). Despite your indicated
              e-mail preferences, we may send you service related communications, including notices
              of any updates to our Terms of Use or Privacy Policy.
            </li>
            <li>
              If you decide at any time that you no longer wish to accept Cookies from our Service
              for any of the purposes described above, then you can instruct your browser, by
              changing its settings, to stop accepting Cookies or to prompt you before accepting a
              Cookie from the websites you visit. Consult your browser’s technical information. If
              you do not accept Cookies, however, you may not be able to use all portions of the
              Service or all functionality of the Service. If you have any questions about how to
              disable or modify Cookies, please let us know at the contact information provided
              below.
            </li>
            <li>
              <b>De-Linking SNS.</b>If you decide at any time that you no longer wish to have your
              SNS account (e.g., Facebook) linked to your Account, then you may de-link the SNS
              account in the “preferences” section in your account settings. You may also manage the
              sharing of certain Personal Data with us when you connect with us through an SNS, such
              as through Facebook Connect. Please refer to the privacy settings of the SNS to
              determine how you may adjust our permissions and manage the interactivity between the
              Services and your social media account or mobile device.
            </li>
            <li>
              <b>Changing or Deleting Your Personal Data.</b>All users may review, update, or
              correct the Personal Data in their user account (including any imported contacts) by
              editing within the system or by making a request to{' '}
              <a href="mailto: support@rarewaters.com">support@rarewaters.com</a>. You may delete
              your personal data and account by making a request to
              <a href="mailto: support@rarewaters.com">support@rarewaters.com</a>. If you completely
              delete all of your Personal Data, then your user account may become deactivated. We
              will use commercially reasonable efforts to honor your request. We may retain an
              archived copy of your records as required by law or for legitimate business purposes.
            </li>
            <li>
              <b>Disclosure of Your Information for Third-Party Advertising.</b> If you do not want
              us to share your personal information with unaffiliated or non-agent third parties for
              promotional purposes, you can opt-out by checking or unchecking the relevant boxesor
              bysending us an email with your request to{' '}
              <a href="mailto: support@rarewaters.com">support@rarewaters.com</a>.
            </li>
            <li>
              <b>Security of Credit Card.</b>For online payments, we use the payment services of
              Stripe, Inc. <a href="https://stripe.com">(https://stripe.com)</a> . We do not
              process, record or maintain your credit card or bank account information. For more
              information on how payments are handled, or to understand the data security and
              privacy afforded such information, please refer to{' '}
              <a href="https://stripe.com/us/privacy">https://stripe.com/us/privacy</a>.
            </li>
          </ul>
        </li>
        <li>
          <b>Changes to This Privacy Policy.</b>
          <br></br>This Privacy Policy may be updated from time to time for any reason. We will
          notify you of any changes to our Privacy Policy by posting the new Privacy Policy here
          <a href="https://rarewaters.com/privacypolicy">https://rarewaters.com/privacypolicy</a>
          and we will change the “Last Updated” date above. You should consult this Privacy Policy
          regularly for any changes.
        </li>
      </ul>
    </div>
  );
};

PrivacyPolicy.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

PrivacyPolicy.propTypes = {
  rootClassName: string,
  className: string,
};

export default PrivacyPolicy;
