import {
  assingGiftCard,
  getSelectedUserGiftCard,
  getUsers,
  modifySelectedGiftCard,
} from '../../util/api';
import { convertListingsDataToCardModel } from '../../util/data';
import { storableError } from '../../util/errors';

// ================ Action types ================ //
export const FETCH_USERS_REQUEST = 'app/AssignGiftCardPage/FETCH_USERS_REQUEST';
export const FETCH_USERS_SUCCESS = 'app/AssignGiftCardPage/FETCH_USERS_SUCCESS';
export const FETCH_USERS_ERROR = 'app/AssignGiftCardPage/FETCH_USERS_ERROR';

export const ASSIGN_GIFT_CARD_REQUEST = 'app/AssignGiftCardPage/ASSIGN_GIFT_CARD_REQUEST';
export const ASSIGN_GIFT_CARD_SUCCESS = 'app/AssignGiftCardPage/ASSIGN_GIFT_CARD_SUCCESS';
export const ASSIGN_GIFT_CARD_ERROR = 'app/AssignGiftCardPage/ASSIGN_GIFT_CARD_ERROR';

export const FETCH_USER_GIFT_CARDS_REQUEST = 'app/AssignGiftCardPage/FETCH_USER_GIFT_CARDS_REQUEST';
export const FETCH_USER_GIFT_CARDS_SUCCESS = 'app/AssignGiftCardPage/FETCH_USER_GIFT_CARDS_SUCCESS';
export const FETCH_USER_GIFT_CARDS_ERROR = 'app/AssignGiftCardPage/FETCH_USER_GIFT_CARDS_ERROR';

export const MODIFY_GIFT_CARD_REQUEST = 'app/AssignGiftCardPage/MODIFY_GIFT_CARD_REQUEST';
export const MODIFY_GIFT_CARD_SUCCESS = 'app/AssignGiftCardPage/MODIFY_GIFT_CARD_SUCCESS';
export const MODIFY_GIFT_CARD_ERROR = 'app/AssignGiftCardPage/MODIFY_GIFT_CARD_ERROR';
// ================ Reducer ================ //

const initialPagination = { page: 1, perPage: 10 };

const initialState = {
  users: [],
  giftCards: [],
  meta: null,
  fetchUsersInProgress: false,
  fetchUsersError: null,
  assignGiftCardInProgress: false,
  assignGiftCardError: null,
  fetchGiftCardsInProgress: false,
  fetchGiftCardsError: null,
  modifyGiftCardRequestInProgress: false,
  modifyGiftCardRequestError: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;

  switch (type) {
    case FETCH_USERS_REQUEST:
      return {
        ...state,
        meta: null,
        fetchUsersInProgress: true,
        fetchUsersError: null,
      };
    case FETCH_USERS_SUCCESS:
      return {
        ...state,
        users: payload.users,
        meta: payload.meta,
        fetchUsersInProgress: false,
      };
    case FETCH_USERS_ERROR:
      return {
        ...state,
        fetchUsersError: payload,
        fetchUsersInProgress: false,
      };

    case ASSIGN_GIFT_CARD_REQUEST:
      return {
        ...state,
        assignGiftCardInProgress: true,
        assignGiftCardError: null,
      };

    case ASSIGN_GIFT_CARD_SUCCESS:
      return {
        ...state,
        assignGiftCardInProgress: false,
      };
    case ASSIGN_GIFT_CARD_ERROR:
      return {
        ...state,
        assignGiftCardInProgress: false,
        assignGiftCardError: payload,
      };

    case FETCH_USER_GIFT_CARDS_REQUEST:
      return {
        ...state,
        giftCards: [],
        fetchGiftCardsInProgress: true,
        fetchGiftCardsError: null,
      };
    case FETCH_USER_GIFT_CARDS_SUCCESS:
      return {
        ...state,
        giftCards: payload,
        fetchGiftCardsInProgress: false,
      };
    case FETCH_USER_GIFT_CARDS_ERROR:
      return {
        ...state,
        fetchGiftCardsError: payload,
        fetchGiftCardsInProgress: false,
      };

    case MODIFY_GIFT_CARD_REQUEST:
      return {
        modifyGiftCardRequestInProgress: true,
        modifyGiftCardRequestError: null,
      };
    case FETCH_USER_GIFT_CARDS_SUCCESS:
      return {
        modifyGiftCardRequestInProgress: false,
      };
    case FETCH_USER_GIFT_CARDS_ERROR:
      return {
        modifyGiftCardRequestInProgress: false,
        modifyGiftCardRequestError: payload,
      };

    default:
      return state;
  }
}

// ================ Action creators ================ //
export const fetchUsersRequest = () => ({
  type: FETCH_USERS_REQUEST,
});

export const fetchUsersSuccess = payload => ({
  type: FETCH_USERS_SUCCESS,
  payload,
});

export const fetchUsersError = error => ({
  type: FETCH_USERS_ERROR,
  payload: error,
});

export const assignGiftCardRequest = () => ({
  type: ASSIGN_GIFT_CARD_REQUEST,
});

export const assignGiftCardSuccess = () => ({
  type: ASSIGN_GIFT_CARD_SUCCESS,
});

export const assignGiftCardError = payload => ({
  type: ASSIGN_GIFT_CARD_ERROR,
  payload,
});

export const fetchGiftCardsRequest = () => ({
  type: FETCH_USER_GIFT_CARDS_REQUEST,
});

export const fetchGiftCardsSuccess = payload => ({
  type: FETCH_USER_GIFT_CARDS_SUCCESS,
  payload,
});

export const fetchGiftCardsError = error => ({
  type: FETCH_USER_GIFT_CARDS_ERROR,
  payload: error,
});

export const modifyGiftCardRequest = () => ({
  type: MODIFY_GIFT_CARD_REQUEST,
});

export const modifyGiftCardSuccess = () => ({
  type: MODIFY_GIFT_CARD_SUCCESS,
});

export const modifyGiftCardError = error => ({
  type: MODIFY_GIFT_CARD_ERROR,
  payload: error,
});

export const modifyGiftCard = (code, newRemaininBalance = 0, deactivate = false) => dispatch => {
  dispatch(modifyGiftCardRequest());

  return modifySelectedGiftCard({ code, remainingBalance: newRemaininBalance, deactivate })
    .then(() => {
      dispatch(modifyGiftCardSuccess());
    })
    .catch(err => {
      dispatch(modifyGiftCardError(storableError(err)));
    });
};

export const fetchUserGiftCards = email => dispatch => {
  dispatch(fetchGiftCardsRequest());
  return getSelectedUserGiftCard({ email })
    .then(res => {
      dispatch(fetchGiftCardsSuccess(res.data));
    })
    .catch(e => {
      dispatch(fetchGiftCardsError(storableError(e)));
    });
};

export const assignGiftCardToUser = (message, to, amount) => dispatch => {
  dispatch(assignGiftCardRequest());

  return assingGiftCard({ message, to, amount })
    .then(() => {
      dispatch(assignGiftCardSuccess());
    })
    .catch(e => assignGiftCardError(storableError(e)));
};

export const getUsersRequest = ({ page = 1, perPage = 10, searchValue = '' }) => dispatch => {
  dispatch(fetchUsersRequest());
  return getUsers({ page, perPage, searchValue })
    .then(res => {
      dispatch(fetchUsersSuccess(res.data));
    })
    .catch(e => {
      dispatch(fetchUsersError(storableError(e)));
    });
};

export const loadData = () => dispatch => {
  dispatch(fetchUsersRequest());
  return getUsers({ ...initialPagination })
    .then(res => {
      dispatch(fetchUsersSuccess(res.data));
    })
    .catch(e => {
      dispatch(fetchUsersError(storableError(e)));
    });
};
