import React from 'react';
import { string } from 'prop-types';
import css from './IconPlay.module.css';

const IconPlay = () => {
  return (
    <svg
      className={css.container}
      xmlns="http://www.w3.org/2000/svg"
      width="41"
      height="51"
      viewBox="0 0 41 51"
      fill="none"
    >
      <path
        d="M39.308 23.807C40.555 24.5912 40.555 26.4088 39.308 27.193L3.06474 49.9864C1.73285 50.824 8.7754e-07 49.8667 9.61767e-07 48.2933L3.40214e-06 2.70666C3.48637e-06 1.13328 1.73286 0.176014 3.06474 1.01364L39.308 23.807Z"
        fill="#0094FF"
      />
    </svg>
  );
};

IconPlay.defaultProps = { className: null };

IconPlay.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconPlay;
