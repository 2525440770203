import React from 'react';
import { string } from 'prop-types';

const IconBathroom = props => {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="22"
    fill="none"
    viewBox="0 0 64 64"
  >
    <path
      fill="#CFF09E"
      d="M26 19a4 4 0 01-4-4 4 4 0 00-4 4v2h18v-2H26z"
    ></path>
    <path fill="#A8DBA8" d="M37 21H17a2 2 0 100 4h20a2 2 0 100-4z"></path>
    <path
      fill="#CFF09E"
      d="M54 38V24a3 3 0 00-3 3v18a3 3 0 003 3h7v-4h-1a6 6 0 01-6-6z"
    ></path>
    <path fill="#79BD9A" d="M51 34h-4v6h4v-6z"></path>
    <path
      fill="#A8DBA8"
      d="M25 7v8h4V7h28v17h4V7a4 4 0 00-4-4H29a4 4 0 00-4 4zM61 48h-4v13h4V48z"
    ></path>
    <path fill="#79BD9A" d="M57 55h-4v4h4v-4z"></path>
    <path fill="#A8DBA8" d="M16 49a2 2 0 012 2v6h-4v-6a2 2 0 012-2z"></path>
    <path
      fill="#CFF09E"
      d="M10 44v-3H8v6h4v-1a2 2 0 01-2-2zM8 51v-4H6v10h8v-2h-2a4 4 0 01-4-4z"
    ></path>
    <path
      fill="#3B8686"
      d="M57 2H29a5 5 0 00-5 5v7h-2a5 5 0 00-5 5v1a3 3 0 000 6h20a3 3 0 000-6v-1a5 5 0 00-5-5h-2V8h26v15h-2a4 4 0 00-4 4v6h-3a1 1 0 00-1 1v6a1 1 0 001 1h3v4a4 4 0 004 4h2v5h-2v-1a1 1 0 00-2 0v8a1 1 0 002 0v-1h2v1a1 1 0 001 1h4a1 1 0 001-1V7a5 5 0 00-5-5zM38 23a1 1 0 01-1 1H17a1 1 0 010-2h20a1 1 0 011 1zm-3-4v1H19v-1a3 3 0 013-3h10a3 3 0 013 3zM57 6H29a1 1 0 00-1 1v7h-2V7a3 3 0 013-3h28a3 3 0 013 3v16h-2V7a1 1 0 00-1-1zm-9 33v-4h2v4h-2zm4 6V27a2 2 0 012-2h6v22h-6a2 2 0 01-2-2zm2 13v-2h2v2h-2zm6 2h-2V49h2v11z"
    ></path>
    <path
      fill="#3B8686"
      d="M21 28a1 1 0 00-1 1v3a1 1 0 002 0v-3a1 1 0 00-1-1zM25 30a1 1 0 00-1 1v2a1 1 0 002 0v-2a1 1 0 00-1-1zM33 28a1 1 0 00-1 1v4a1 1 0 002 0v-4a1 1 0 00-1-1zM37 28a1 1 0 00-1 1v3a1 1 0 002 0v-3a1 1 0 00-1-1zM29 34a1 1 0 00-1 1v2a1 1 0 002 0v-2a1 1 0 00-1-1zM29 28a1 1 0 00-1 1v2a1 1 0 002 0v-2a1 1 0 00-1-1zM17 32a1 1 0 001-1v-2a1 1 0 00-2 0v2a1 1 0 001 1zM17 36a1 1 0 100-2 1 1 0 000 2zM19 56v-5a3 3 0 00-4-2.82V47a1 1 0 00-1-1h-1v-5a1 1 0 00-1-1H8a1 1 0 00-1 1v5H6a1 1 0 00-1 1v9H3a1 1 0 000 2h16a1 1 0 000-2zM9 42h2v4H9v-4zm4 6v2H7v-2h6zm-6 4h6v4H7v-4zm8 4v-5a1 1 0 012 0v5h-2zM33 37a1 1 0 00-1 1v3a1 1 0 002 0v-3a1 1 0 00-1-1zM33 44a1 1 0 00-1 1v2a1 1 0 002 0v-2a1 1 0 00-1-1zM37 35a1 1 0 00-1 1v3a1 1 0 002 0v-3a1 1 0 00-1-1zM37 41a1 1 0 00-1 1v3a1 1 0 002 0v-3a1 1 0 00-1-1zM25 36a1 1 0 00-1 1v2a1 1 0 002 0v-2a1 1 0 00-1-1zM33 52a1 1 0 100-2 1 1 0 000 2zM29 42a1 1 0 100-2 1 1 0 000 2zM25 50a1 1 0 100-2 1 1 0 000 2zM29 46a1 1 0 100-2 1 1 0 000 2zM21 43a1 1 0 100-2 1 1 0 000 2zM25 42a1 1 0 00-1 1v2a1 1 0 002 0v-2a1 1 0 00-1-1zM21 35a1 1 0 00-1 1v2a1 1 0 002 0v-2a1 1 0 00-1-1zM55 27a1 1 0 00-1 1v6a1 1 0 002 0v-6a1 1 0 00-1-1z"
    ></path>
  </svg>
  );
};

IconBathroom.defaultProps = { className: null };

IconBathroom.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconBathroom;


