import React from 'react';
import PropTypes from 'prop-types';

import css from './TermsOfService.module.css';

const TermsOfService = () => {
  return (
    <div className={css.root}>
      <h2 className={css.title}>Cancellation Policy</h2>
      <div className={css.warningContainer}>
        <p>
          Cancellations made ten (10) days or more prior to the booked date will receive a full
          refund. Cancellations made five (5) to ten (10) days prior to the booked date will receive
          a credit for future use. Cancellations made five (5) days or less before the booked date
          will receive no refund or credit. By submitting a booking request for any RareWaters
          property, you agree to this cancellation policy. If you request to book a property within
          10 days of the request date, and the booking is approved and you cancel the booking, you
          will receive a credit for future use. RareWaters is not responsible for weather, river
          flows or fishing conditions. Anglers are responsible for determining weather, river flows
          and fishing conditions. Refunds and credits will not be issued for weather, river flows or
          fishing conditions.
        </p>
        <hr></hr>
        <p>
          Cancellation policies may be superseded by extenuating circumstances as deemed appropriate
          by RareWaters staff or for any other reason permitted under the Terms of Use.
        </p>
      </div>
      <h2 className={css.title}>Terms of Use, Version 1.3</h2>
      <h5>Terms of Use, Version 1.3</h5>
      <h5>Last Updated Date: May 16, 2023</h5>
      <h5>Effective Date: May 16, 2023</h5>
      <p style={{ color: 'var(--colorAttention)' }}>
        Please Read These Terms Of Service (“Terms”) Carefully As They Contain Important Information
        About Your Legal Rights, Remedies, And Obligations. By Accessing Or Using The Rarewaters
        Platform, You Agree To Comply With And Be Bound By These Terms.
      </p>
      <p style={{ color: 'var(--colorAttention)' }}>
        The Terms of Use include a class action waiver and require binding arbitration on an
        individual basis to resolve disputes, rather than jury trials. By accepting these Terms, you
        agree to be bound by this arbitration clause and class action waiver. Please read it
        carefully.
      </p>
      <p>
        <i>Last Updated: May 16, 2023</i>
      </p>
      <p>
        These Terms constitute a legally binding agreement <b>(“Agreement“)</b> between you and
        Rarewaters, Inc. a Delaware corporation <b>(“Rarewaters”)</b> governing your access to and
        use of the Rarewaters website (the <b>“Site“</b> ), our mobile applications, and application
        program interfaces (collectively,<b> “Application“</b>) and all associated services
        (collectively,
        <b>“Rarewaters Services“</b> ). The Site, Application and Rarewaters Services together are
        hereinafter collectively referred to as the <b>“Rarewaters Platform”</b> .
      </p>
      <p>
        When these Terms mention <b>“Rarewaters”</b> , <b>“we”</b> , <b>“us”</b> , or <b>“our”</b> ,
        it refers to Rarewaters.
      </p>
      <p>
        Our collection and use of personal information in connection with your access to and use of
        the Rarewaters Platform is described in our Privacy Policy.
      </p>
      <p>
        Any and all payment processing services in connection with your use of the Rarewaters
        Platform <b>(“Payment Services“)</b> are provided by a third-party payment processor, and
        subject to such third-party’s terms of use.
      </p>
      <p>
        Landowners alone are responsible for identifying, understanding, and complying with all
        laws, rules and regulations that apply to their Listings and Landowner Services (as defined
        below). If you have questions about how local laws apply to your Listing(s) and Landowner
        Service(s) on Rarewaters, you should always seek legal guidance.
      </p>
      <ul style={{ listStyleType: 'decimal' }}>
        <li>
          <b> Scope of Rarewaters Services</b>
          <ol>
            <li>
              The Rarewaters Platform is an online marketplace that enables registered users
              <b> (“Users”)</b> and certain third parties who offer services (Users and third
              parties who offer services are <b> “Landowners” </b>and the services they offer are
              <b>“Landowner Services”</b> ) to publish such Landowner Services on the Rarewaters
              Platform <b>(“Listings”)</b> and to communicate and transact directly with Users who
              are seeking to book such Landowner Services (Users using Landowner Services are
              <b>“Guests”</b> ). Landowner Services include the offering of single or multi-day
              access to Landowner’s property for Landowner-specified fishing or other outdoor
              related activities <b> (“Activities”)</b>. A Landowner may also provide Guests access
              to public lands through the Landowner’s land.
            </li>
            <li>
              As the provider of the Rarewaters Platform, Rarewaters does not own, create, sell,
              resell, provide, control, manage, offer, deliver, or supply any Listings or Landowner
              Services. Landowners alone are responsible for their Listings and Landowner Services.
              When Users make or accept a booking, they are entering into a contract directly with
              each other. Rarewaters is not, and does not become, a party to or otherwise
              participant in any contractual relationship between Users. Rarewaters is not acting as
              an agent in any capacity for any User. By using the Rarewaters Platform, you agree
              that any legal remedy or liability that you seek to obtain for actions or omissions of
              another User or other third parties will be limited to a claim against the particular
              User or other third parties who caused you harm. You agree not to attempt to impose
              liability on or seek any legal remedy from Rarewaters with respect to such actions or
              omissions. Accordingly, we encourage you to communicate directly with the other User
              regarding any Listings.
            </li>
            <li>
              While we may help facilitate the resolution of disputes, Rarewaters has no control
              over and does not guarantee (i) the existence, quality, safety, suitability, or
              legality of any Listings or Landowner Services, (ii) the truth or accuracy of any
              Listing descriptions or other User Content (as defined below), or (iii) the
              performance or conduct of any User or third party. Rarewaters does not endorse any
              User, Listing or Landowner Services. Any references to a User being “verified” (or
              similar language) only indicate that the User has completed a relevant verification or
              identification process and nothing else. Any such description is not an endorsement,
              certification or guarantee by Rarewaters about any User, including the User’s identity
              or background or whether the User is trustworthy, safe or suitable. You should always
              exercise due diligence and care when deciding whether to use Landowner Services,
              accept a booking request from a Guest, or communicating and interacting with other
              Users, whether online or in person.
            </li>
            <li>
              If you choose to use the Rarewaters Platform as a Landowner, your relationship with
              Rarewaters is limited to being an independent, third-party contractor, and not an
              employee, agent, joint venturer or partner of Rarewaters for any reason, and you act
              exclusively on your own behalf and for your own benefit, and not on behalf, or for the
              benefit, of Rarewaters. Rarewaters does not, and shall not be deemed to, direct or
              control you generally or in your performance under these Terms specifically, including
              in connection with your provision of the Landowner Services. You acknowledge and agree
              that you have complete discretion whether to list Landowner Services or otherwise
              engage in other business or employment activities.
            </li>
            <li>
              To promote the Rarewaters Platform and to increase the exposure of Listings to
              potential Guests, Listings and other User Content may be displayed on other websites,
              in applications, within emails, and in online and offline advertisements.
            </li>
            <li>
              The Rarewaters Platform may contain links to third-party websites or resources
              <b> (“Third-Party Services”)</b>. Such Third-Party Services may be subject to
              different terms and conditions and privacy practices. Rarewaters is not responsible or
              liable for the availability or accuracy of such Third-Party Services, or the content,
              products, or services available from such Third-Party Services. Links to such
              Third-Party Services are not an endorsement by Rarewaters of such Third-Party
              Services.
            </li>
            <li>
              Due to the nature of the Internet, Rarewaters cannot guarantee the continuous and
              uninterrupted availability and accessibility of the Rarewaters Platform. Rarewaters
              may restrict the availability of the Rarewaters Platform or certain areas or features
              thereof, if this is necessary in view of capacity limits, the security or integrity of
              our servers, or to carry out maintenance measures that ensure the proper or improved
              functioning of the Rarewaters Platform. Rarewaters may improve, enhance and modify the
              Rarewaters Platform and introduce new Rarewaters Services from time to time.
            </li>
          </ol>
        </li>
        <li>
          <b>Eligibility, Using the Rarewaters Platform, User Verification</b>
          <ol>
            <li>Eligibility, Using the Rarewaters Platform, User Verification</li>
            <li>
              Rarewaters may make access to and use of the Rarewaters Platform, or certain areas or
              features of the Rarewaters Platform, subject to certain conditions or requirements.
            </li>
            <li>
              Although Rarewaters uses third party identity verification processes, User
              verification on the Internet is difficult and we cannot assure Landowners that such
              processes will result in accurate or complete information about a particular User.
            </li>
          </ol>
        </li>
        <li>
          <b>Modification of these Terms</b> <br></br>
          Rarewaters reserves the right to modify these Terms at any time in accordance with this
          provision. If we make changes to these Terms, we will post the revised Terms on the
          Rarewaters Platform and update the “Last Updated” date at the top of these Terms. We will
          also provide you with notice of the modifications by email at least fifteen (15) days
          before the date they become effective. If you disagree with the revised Terms, you may
          terminate this Agreement with immediate effect. We will inform you about your right to
          terminate the Agreement in the notification email. If you do not terminate your Agreement
          before the date the revised Terms become effective, your continued access to or use of the
          Rarewaters Platform will constitute acceptance of the revised Terms.
        </li>
        <li>
          <b>Account Registration</b>
          <ol>
            <li>
              You must register an account <b>(“Rarewaters Account“)</b> to access and use certain
              features of the Rarewaters Platform, such as publishing or booking a Listing. If you
              are registering a Rarewaters Account for a business, organization or other legal
              entity, you represent and warrant that you have the authority to legally bind that
              entity and grant us all permissions and licenses provided in these Terms.
            </li>
            <li>
              You can register a Rarewaters Account using an email address and creating a password,
              or through your account with certain third-party social networking services, such as
              Facebook or Google <b>(“SNS Account“)</b>. You have the ability to disable the
              connection between your Rarewaters Account and your SNS Account at any time, by
              accessing the “Settings” section of the Rarewaters Platform.
            </li>
            <li>
              You must provide accurate, current and complete information during the registration
              process and keep your Rarewaters Account and public Rarewaters Account profile page
              information up-to-date at all times.
            </li>
            <li>
              You may not register more than one (1) Rarewaters Account unless Rarewaters authorizes
              you to do so. You may not assign or otherwise transfer your Rarewaters Account to
              another party.
            </li>
            <li>
              You are responsible for maintaining the confidentiality and security of your
              Rarewaters Account credentials and may not disclose your credentials to any third
              party. You are liable for any and all activities conducted through your Rarewaters
              Account, unless you immediately notify Rarewaters if you know, or have any reason to
              suspect, that your credentials have been lost, stolen, misappropriated, or otherwise
              compromised or in case of any actual or suspected unauthorized use of your Rarewaters
              Account.
            </li>
          </ol>
        </li>
        <li>
          <b>Content</b>
          <ol>
            <li>
              Rarewaters may, in its sole discretion, enable Users to (i) create, upload, post,
              send, receive and store content, such as text, photos, audio, video, or other
              materials and information on or through the Rarewaters Platform
              <b>(“User Content“)</b> ; and (ii) access and view User Content and any content that
              Rarewaters itself makes available on or through the Rarewaters Platform, including
              proprietary Rarewaters content and any content licensed or authorized for use by or
              through Rarewaters from a third party (<b>“Rarewaters Content”</b> and together with
              User Content, <b>“Collective Content“</b>).
            </li>
            <li>
              The Rarewaters Platform, Rarewaters Content, and User Content may in its entirety or
              in part be protected by copyright, trademark, and/or other laws of the United States
              and other countries. You acknowledge and agree that the Rarewaters Platform and
              Rarewaters Content, including all associated intellectual property rights, are the
              exclusive property of Rarewaters and/or its licensors or authorizing third parties.
              You will not remove, alter or obscure any copyright, trademark, service mark or other
              proprietary rights notices incorporated in or accompanying the Rarewaters Platform,
              Rarewaters Content or User Content. All trademarks, service marks, logos, trade names,
              and any other source identifiers of Rarewaters used on or in connection with the
              Rarewaters Platform and Rarewaters Content are trademarks or registered trademarks of
              Rarewaters in the United States and abroad. Trademarks, service marks, logos, trade
              names and any other proprietary designations of third parties used on or in connection
              with the Rarewaters Platform, Rarewaters Content, and/or Collective Content are used
              for identification purposes only and may be the property of their respective owners.
            </li>
            <li>
              You will not use, copy, adapt, modify, prepare derivative works of, distribute,
              license, sell, transfer, publicly display, publicly perform, transmit, broadcast or
              otherwise exploit the Rarewaters Platform or Collective Content, except to the extent
              you are the legal owner of certain User Content or as expressly permitted in these
              Terms. No licenses or rights are granted to you by implication or otherwise under any
              intellectual property rights owned or controlled by Rarewaters or its licensors,
              except for the licenses and rights expressly granted in these Terms.
            </li>
            <li>
              Subject to your compliance with these Terms, Rarewaters grants you a limited,
              non-exclusive, non-sublicensable, revocable, non-transferable license to (i) download
              and use the Application on your personal device(s); and (ii) access and view any
              Collective Content made available on or through the Rarewaters Platform and accessible
              to you, solely for your personal and non-commercial use.
            </li>
            <li>
              By creating, uploading, posting, sending, receiving, storing, or otherwise making
              available any User Content on or through the Rarewaters Platform, you grant to
              Rarewaters a non-exclusive, worldwide, royalty-free, irrevocable, perpetual (or for
              the term of the protection), sub-licensable and transferable license to such User
              Content to access, use, store, copy, modify, prepare derivative works of, distribute,
              publish, transmit, stream, broadcast, and otherwise exploit in any manner such User
              Content to provide and/or promote the Rarewaters Platform, in any media or platform.
              Insofar as User Content (including Verified Images) includes personal information,
              such User Content will only be used for these purposes if such use complies with
              applicable data protection laws in accordance with our
              <a href="https://rarewaters.com/privacy-policy/"> Privacy Policy</a> . Unless you
              provide specific consent, Rarewaters does not claim any ownership rights in any User
              Content and nothing in these Terms will be deemed to restrict any rights that you may
              have to use or exploit your User Content.
            </li>
            <li>
              You are solely responsible for all User Content that you make available on or through
              the Rarewaters Platform. Accordingly, you represent and warrant that: (i) you either
              are the sole and exclusive owner of all User Content that you make available on or
              through the Rarewaters Platform or you have all rights, licenses, consents and
              releases that are necessary to grant to Rarewaters the rights in and to such User
              Content, as contemplated under these Terms; and (ii) neither the User Content nor your
              posting, uploading, publication, submission or transmittal of the User Content or
              Rarewaters’s use of the User Content (or any portion thereof) as contemplated under
              these Terms will infringe, misappropriate or violate a third party’s patent,
              copyright, trademark, trade secret, moral rights or other proprietary or intellectual
              property rights, or rights of publicity or privacy, or result in the violation of any
              applicable law or regulation.
            </li>
            <li>
              You will not post, upload, publish, submit or transmit any User Content that: (i) is
              fraudulent, false, misleading (directly or by omission or failure to update
              information) or deceptive; (ii) is defamatory, libelous, obscene, pornographic, vulgar
              or offensive; (iii) promotes discrimination, bigotry, racism, hatred, harassment or
              harm against any individual or group; (iv) is violent or threatening or promotes
              violence or actions that are threatening to any other person; (v) promotes illegal or
              harmful activities or substances; or (vi) violates Rarewaters policy. Rarewaters may,
              without prior notice, remove or disable access to any User Content that Rarewaters
              finds to be in violation of applicable law, these Terms, or otherwise may be harmful
              or objectionable to Rarewaters, its Users, third parties, or property.
            </li>
            <li>
              Rarewaters respects copyright law and expects its Users to do the same. If you believe
              that any content on the Rarewaters Platform infringes copyrights you own, please
              notify us in accordance with our Copyright Policy.
            </li>
          </ol>
        </li>
        <li>
          <b>Service Fees</b>
          <ol>
            <li>
              Rarewaters may charge fees to Landowners <b> (“Landowner Fees“)</b> and/or Guests
              <b>(“Guest Fees“)</b> (collectively, <b>“Service Fees“</b> ) in consideration for the
              use of the Rarewaters Platform. More information about when Service Fees apply and how
              they are calculated can be found on our Service Fees page.
            </li>
            <li>
              Any applicable Service Fees (including any applicable Taxes) will be displayed to a
              Landowner or Guest prior to publishing or booking a Listing. Rarewaters reserves the
              right to change the Service Fees at any time, and will provide Users adequate notice
              of any fee changes before they become effective. Such fee changes will not affect any
              bookings made prior to the effective date of the fee change.
            </li>
            <li>You are responsible for paying any Service Fees that you owe to Rarewaters.</li>
            <li>
              You will not use the Rarewaters Platform, Rarewaters Content, and User Content, in an
              effort to contravene any rights of Rarewaters to collect Services Fees, including,
              without limitation, encouraging any Guest or Landowner to book or list a Listing other
              than through the Rarewaters Platform. If you make any booking other than on the
              Rarewaters Platform, that booking will not be covered by these Terms of Service or
              Rarewaters’s insurance.
            </li>
          </ol>
        </li>
        <li>
          <b>Terms Specific for Landowners</b>
          <ol style={{ listStyleType: 'square' }}>
            <li>
              <b> Terms Applicable to all Listings</b>
              <ol>
                <li>
                  When creating a Listing through the Rarewaters Platform you must (i) provide
                  complete and accurate information about your Landowner Service (such as listing
                  description, location, and calendar availability), (ii) disclose any deficiencies,
                  restrictions and requirements that apply and (iii) provide any other pertinent
                  information requested by Rarewaters. You are responsible for keeping your Listing
                  information (including calendar availability) up-to-date at all times.
                </li>
                <li>
                  When creating a listing, you represent and warrant that you are the owner of the
                  property, or have the right to list the property in the Rarewaters Platform.
                </li>
                <li>
                  Landowner hereby grants to Company, its agents, independent contractors,
                  employees, and Anglers, an exclusive license to enter upon and use the Property
                  (in common with Landowner) for the sole purpose of conducting fly-fishing trips
                  and lodging subject to the terms and conditions set forth herein (the “License”).
                </li>
                <li>
                  Term. The License takes effect upon the Effective Date and shall continue for a
                  period of five (5) years thereafter (the “Term”) unless revoked and terminated
                  sooner by Landowner pursuant to Section 8 below. Unless revoked or terminated by
                  Landowner sooner, his License shall automatically renew and the Term shall
                  automatically be extended for one (1) year terms perpetually upon the expiration
                  of the initial Term (the “Renewal Term).
                </li>
                <li>
                  Exclusivity. Use of the Property for the Permitted Use shall be exclusive to the
                  Company during the Term or any Renewal Term. Landowner covenants and agrees that
                  during the Term (and any Renewal Term), Landowner shall not enter into any
                  agreement or grant any other license, permission, tenancy or other authorization
                  to any other person or entity to use the Property for the Permitted Use.
                </li>
                <li>
                  Fees & Payment. As consideration for the License and access to the Property,
                  Landowner shall charge and collect (“Private Access Fees”) from Company. Company
                  reserves the right to determine and set the associated angling fees at its
                  discretion, including based on holidays or off-season demand. Payments shall be
                  sent to Landowner monthly, based on the total number of anglers who booked the
                  property during the month. Agents working on behalf of the Company shall have
                  reasonable access to the property during reasonable hours for fly fishing and
                  marketing purposes at no charge to gather marketing content and gain intimate
                  knowledge of the property so the Company can adequately communicate information to
                  anglers.
                </li>
                <li>
                  Conditions of Use. The Company’s use of the Property is expressly conditioned upon
                  following:
                  <ol style={{ listStyleType: 'disc' }}>
                    <li>
                      <b>Scheduling & Reservations.</b> All angling and lodging days and
                      accommodation rentals shall be reserved and scheduled through the Company’s
                      website and/or mobile application (“App”). If Landowner does not accept or
                      deny a booking request within 48 hours of receiving the email, Company may
                      assume that Landowner approves and, therefore, retains the right to send the
                      angler to the property for the desired booking date.
                    </li>
                    <li>
                      <b> Compliance with Rules.</b> Company agrees to comply, and cause its agents,
                      independent contractors, employees and Anglers to comply, with the Property
                      Rules set forth on the attached Exhibit B. Company understands that Landowner
                      reserves the right to modify, amend or otherwise at Landowner’s sold
                      discretion alter the Property Rules at any time during the Term or any Renewal
                      Term and shall provide a copy of any such amended rules to Company prior to
                      enforcement.
                    </li>
                    <li>
                      <b>Release and Assumption of Risk.</b> Company agrees to cause ALL PERSONS who
                      access the Property pursuant to this Agreement, including Company, its agents,
                      independent contractors, employees and Anglers, to adhere to the Angler
                      Release and Assumption of Risk attached as Exhibit C (the “Release”), prior to
                      allowing any such person to access the Property. Company shall furnish the
                      Landowner with copies of the signed Releases upon reasonable advance written
                      request. Release is signed by anglers digitally via the Company website.
                    </li>
                    <li>
                      <b>Termination.</b> The License granted pursuant to this Agreement is
                      revocable by Landowner. Landowner may terminate this Agreement and the
                      Licenses as follows: (i) in its sole and absolute discretion for any reason,
                      with or without cause, upon ninety (90) days advance written notice to
                      Company; or (ii) upon Company’s material breach of this Agreement after
                      providing Company with fourteen (14) days advance written notice and
                      opportunity to cure.
                    </li>
                    <li>
                      <b> Compliance. </b>In the event the Landowner is offering lodging to Anglers
                      or other guests, Landowner is responsible and liable for your Lodging and any
                      content or listings you create and post on the Rarewaters Platform, including
                      any listing or Content created on your behalf by a third-party, agent or
                      representative, and any third-party claims based on your listings or content.
                      Accordingly, you represent and warrant that any listings you create, including
                      any listings created on your behalf by a third-party, agent or representative,
                      and the accommodation(s) or lodging in such listings: (i) will not breach any
                      agreements you have entered into with any third parties, such as homeowners
                      association, condominium, lease or rental agreements; and, (ii) will (a) be in
                      compliance with all applicable laws (such as zoning laws and laws governing
                      rentals of residential and other properties), tax requirements, and rules and
                      regulations that may apply to any Accommodation included in your listing
                      (including having all required permits, licenses, and registrations), and (b)
                      not conflict with the rights of third parties.
                    </li>
                  </ol>
                </li>
                <li>
                  Pictures, animations or videos (collectively, <b>“Images“</b> ) used in your
                  Listings must accurately reflect the quality and condition of your Landowner
                  Services.
                </li>
                <li>
                  The placement and ranking of Listings in search results on the Rarewaters Platform
                  may vary and depend on a variety of factors, such as Guest search parameters and
                  preferences, Landowner requirements, price and calendar availability, number and
                  quality of Images, customer service and cancellation history, Reviews and Ratings,
                  type of Landowner Service, and/or ease of booking.
                </li>
                <li>
                  When you accept or have pre-approved a booking request by a Guest, you are
                  entering into a legally binding agreement with the Guest and are required to
                  provide your Landowner Service(s) to the Guest as described in your Listing when
                  the booking request is made. You also agree to pay the applicable Landowner Fee
                  and any applicable Taxes.
                </li>
                <li>
                  Although Rarewaters has obtained certain insurance, Rarewaters recommends that
                  Landowners obtain appropriate insurance for their Landowner Services. Please
                  review any respective insurance policy carefully, and in particular make sure that
                  you are familiar with and understand any exclusions to, and any deductibles that
                  may apply for, such insurance policy, including, but not limited to, whether or
                  not your insurance policy will cover the actions or inactions of Guests while
                  participating in your Landowner Service.
                </li>
                <li>
                  When listing an Activity, you must, where applicable, fully educate and inform
                  Guests about (i) any risks inherent to the Activity, (ii) any requirements for
                  participation, such as the minimum age, related skills, level of fitness or other
                  requirements, and (iii) anything else they may need to know to safely participate
                  in the Activity (including special certifications or licenses).
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          <b>Terms Specific for Guests</b>
          <ol style={{ listStyleType: 'square' }}>
            <li>
              <b>Terms Applicable to all Bookings</b>
              <ol>
                <li>
                  Subject to meeting any requirements (such as completing any verification
                  processes) set by Rarewaters and/or the Landowner, you can book a Listing
                  available on the Rarewaters Platform by following the respective booking process.
                  All applicable fees, including the Listing Fee, Security Deposit (if applicable),
                  Guest Fee and any applicable Taxes (collectively, <b>“Total Fees”</b> ) will be
                  presented to you prior to booking a Listing. You agree to pay the Total Fees for
                  any booking requested in connection with your Rarewaters Account.
                </li>
                <li>
                  Upon receipt of a booking confirmation from Rarewaters, a legally binding
                  agreement is formed between you and your Landowner, subject to any additional
                  terms and conditions of the Landowner that apply, including in particular the
                  applicable cancellation policy and any rules and restrictions specified in the
                  Listing.
                </li>
                <li>
                  If you book a Landowner Service on behalf of additional Guests, you are required
                  to ensure that every additional Guest meets any requirements set by the Landowner,
                  and is made aware of and agrees to these Terms and any terms and conditions, rules
                  and restrictions set by the Landowner. If you are booking for an additional Guest
                  who is a minor, you represent and warrant that you are legally authorized to act
                  on behalf of the minor. Minors may only participate in the Landowner Service if
                  accompanied by an adult who is responsible for them.
                </li>
                <li>
                  You should carefully review the description of any Landowner Service you intend to
                  book to ensure you (and any additional Guests you are booking for) meet any
                  minimum age, proficiency, fitness or other requirements which the Landowner has
                  specified in their Listing.
                </li>
                <li>
                  Before and during an Activity you must at all times adhere to the Landowners’
                  instructions and all applicable laws.
                </li>
                <li>
                  Each Guest is responsible for ensuring that he and all members of his party have
                  all appropriate permits, licenses, and tags. Should the Guest realize he, or his
                  party, has performed an Activity without the appropriate permit, license, or tag,
                  it is the Guest’s responsibility to report the occurrence to the appropriate
                  authority.
                </li>
                <li>
                  Each Guest is responsible for his own safety and for handling an emergency, and
                  should have a plan of what to do in case he needs assistance. Each Guest should
                  have a method of communication and check-in process, but should also be aware that
                  cell coverage is spotty and not always reliable. Guests cannot expect Landowners
                  to rescue them. For a medical emergency, Guests should contact emergency services.
                </li>
                <li>
                  Although Rarewaters has obtained certain insurance, Rarewaters recommends that
                  Users obtain appropriate insurance, including appropriate vehicle and health
                  insurance. Please review any respective insurance policy carefully, and in
                  particular make sure that you are familiar with and understand any exclusions to,
                  and any deductibles that may apply for, such insurance policy, including, but not
                  limited to, whether or not your insurance policy will cover the provide sufficient
                  medical coverage in the event you or a member of your party are injured while
                  participating in an activity booked on the Rarewaters Platform. You should not
                  consider Rarewaters as an insurer, and you should not rely on any insurance that
                  Rarewaters may provide as being sufficient or an alternative to insurance you
                  maintain, or should maintain.
                </li>
                <li>User Release and Assumption of Risk</li>
                <ul>
                  <li>
                    In consideration of being permitted access to any of RareWaters’ properties (the
                    “Properties”) and participating in fishing, hiking, guided excursions, camping
                    or any other activity provided or organized by RareWaters LLC, LLC (“Company”)
                    on or about the Properties, hereinafter referred to as the “Activity”,
                    <b>
                      all Users do hereby knowingly and voluntarily release, indemnify and discharge
                    </b>
                    Company, all of the Company’s landowner partners, and their respective agents,
                    owners, managers, members, officers, partners, employees, independent
                    contractors, volunteers, affiliates, subsidiaries, related entities, successors
                    and assigns (“Released Parties”) on behalf of the User, their spouse, their
                    children, parents, heirs, personal representative, estate and assigns, from
                    liability as follows:
                  </li>
                  <li>
                    Users acknowledge that accessing the Properties and the participation in the
                    Activity entails known and unknown risks that could result in physical or
                    emotional injury, paralysis, death, or damage to themselves, to property, or to
                    third parties. Users understand that such risks simply cannot be eliminated
                    without jeopardizing the essential qualities of the Activity, regardless of the
                    care taken to avoid injuries. <b> The risks include among other things: </b>
                    slipping, tripping, falling or stumbling, scratches, lacerations, punctures,
                    bruises, sprains, broken bones, concussions, eye injury, loss of sight, joint or
                    back injuries, heart attacks, adverse weather, lighting strikes, rock falls,
                    floods, attacks or trampling by wild or domestic animals, dangerous conditions
                    existing on the Properties, drowning, paralysis and death. Company and the
                    Released Parties have difficult jobs to perform and while they seek safety, they
                    are not infallible. They may be unaware of a Client’s medical condition,
                    well-being, fitness, or abilities. They may give incomplete warnings or
                    instructions, and the equipment being used may become loose, out of adjustment,
                    or malfunction. There is also a risk that Company and the Released Parties may
                    be negligent in, among other things, monitoring and supervising use of
                    equipment, in the maintenance and repair of the equipment, and in the
                    maintenance of the Properties.
                  </li>
                  <li>
                    <b>
                      USERS HAVE READ THE PREVIOUS PARAGRAPHS AND I KNOW, UNDERSTAND, AND APPRECIATE
                      THE RISKS THAT ARE INHERENT TO THE ACTIVITY. USERS HEREBY EXPRESSLY AGREE AND
                      PROMISE TO ACCEPT AND ASSUME ALL OF THE RISKS AND UNDERSTAND THEIR
                      PARTICIPATION IN THE ACTIVITY IS PURELY VOLUNTARY AND USERS ELECT TO
                      PARTICIPATE IN SPITE OF THESE RISKS.
                    </b>
                  </li>
                  <li>
                    <b>
                      USERS HEREBY VOLUNTARILY RELEASE, WAIVE, FOREVER DISCHARGE, AND AGREE TO
                      DEFEND, INDEMNIFY, AND HOLD HARMLESS, COMPANY AND THE RELEASED PARTIES, FROM
                      ANY AND ALL CLAIMS, DEMANDS, OR CAUSES OF ACTION, WHICH ARE IN ANY WAY
                      CONNECTED WITH THEIR PARTICIPATION IN THE ACTIVITY, ACCESS TO OR USE OF THE
                      PROPERTIES, AND/OR USE OF COMPANY’S OR THE RELEASED PARTIES’, EQUIPMENT OR
                      FACILITIES, INCLUDING, WITHOUT LIMITATION ANY SUCH CLAIMS WHICH ALLEGE
                      NEGLIGENT ACTS OR OMISSIONS OF COMPANY OF THE RELEASED PARTIES, OR RELATING TO
                      A DANGEROUS CONDITION EXISTING ON THE PROPERTIES.
                    </b>
                  </li>
                  <li>
                    <b>Indemnification and Hold Harmless:</b> Users agree to indemnify and hold
                    harmless Company and the Released Parties from any and all claims, actions,
                    suits, procedures, costs, expenses, damages, and liabilities, including
                    attorney’s fees and court costs incurred, as a result of my participation in the
                    Activity, access to or use of the Properties, and/or use of Company’s or the
                    Released Parties’ equipment or facilities. In the event User files a lawsuit
                    against Company or any of the Released Parties Users agree to do so exclusively
                    in the State District Court in the City and County of Denver Court and hereby
                    waive all rights to a trial by jury. Colorado law shall apply.
                  </li>
                  <li>
                    <b>Severability:</b>The undersigned further expressly agrees that the foregoing
                    Release and Assumption of Risk agreement is intended to be as broad and
                    inclusive as is permitted by the laws of the State of Colorado and that if any
                    portion hereof is found to be void or unenforceable, it is agreed that the
                    balance shall, notwithstanding, continue in full legal force and effect.
                  </li>
                  <li>
                    <b>Acknowledgment of Understanding:</b> Users have read this Release and
                    Assumption of Risk, fully understand its terms, and understand that they are
                    giving up substantial rights, including their rights to sue and to a jury. Users
                    acknowledge that they are agreeing to this Release and Assumption of Risk freely
                    and voluntarily,
                    <b>
                      and intend by their agreement to this Release to be a complete and
                      unconditional release of ALL liability of Company the Released Parties
                    </b>
                    to the greatest extent allowed by law.
                  </li>
                  <li>
                    By accepting RareWaters Terms of Use all Users agree to this Release and
                    Assumption of Risk, Users also grant Company and the Released Parties the
                    irrevocable right and permission to photograph and/or record, to use any such
                    photographs and/or recordings for all purposes, including advertising and
                    promotional purposes, in any lawful manner in any and all media now or hereafter
                    known, in perpetuity throughout the world, without restriction as to alteration.
                    Users hereby waive any right to inspect or approve the use of any such materials
                    and acknowledge and agree that the rights granted to this release are without
                    compensation of any kind.
                  </li>
                </ul>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          <b>Booking Modifications, Cancellations and Refunds</b>
          <ol>
            <li>
              Landowners and Guests are responsible for any modifications to a booking that they
              make via the Rarewaters Platform or direct Rarewaters customer service to make
              <b> (“Booking Modifications“)</b>, and agree to pay any additional Listing Fees,
              Landowner Fees or Guest Fees and/or Taxes associated with such Booking Modifications.
            </li>
            <li>
              Subject to Section 9.4, below, Guests can cancel a confirmed booking at any time
              pursuant to the Listing’s cancellation policy set by the Landowner.
            </li>
            <li>
              If a Landowner cancels a confirmed booking, the Guest will receive a full refund of
              the Total Fees for such booking.
            </li>
            <li>
              Unless otherwise set forth in the Listing, If a Guest cancels a confirmed booking more
              than 48 hours from the time a booking has been confirmed by the Landowner, the Guest
              will not receive a refund. This policy is to protect Landowners from potentially
              losing their only opportunities to make money from their properties due to the nature
              of certain Activities and the limited time windows available for them.
            </li>
          </ol>
        </li>
        <li>
          <b>Ratings and Reviews</b>
          <ol>
            <li>
              Within a certain time frame after completing a booking, Guests and Landowners can
              leave a public review <b>(“Review”)</b> and submit a star rating <b> (“Rating”)</b>
              about each other. Ratings or Reviews reflect the opinions of individual Users and do
              not reflect the opinion of Rarewaters. Ratings and Reviews are not verified by
              Rarewaters for accuracy and may be incorrect or misleading.
            </li>
            <li>
              Ratings and Reviews by Guests and Landowners must be accurate and may not contain any
              offensive or defamatory language.
            </li>
            <li>
              Users are prohibited from manipulating the Ratings and Reviews system in any manner,
              such as instructing a third party to write a positive or negative Review about another
              User.
            </li>
            <li>
              Ratings and Reviews are part of a User’s public profile and may also be surfaced
              elsewhere on the Rarewaters Platform (such as the Listing page) together with other
              relevant information such as number of bookings, number of cancellations, average
              response time and other information.
            </li>
          </ol>
        </li>
        <li>
          <b>Damage to Property, Disputes between Users</b>
          <ol>
            <li>
              As a Guest, you are responsible for leaving the Landowner’s property in the condition
              it was in when you arrived. You are responsible for your own acts and omissions and
              are also responsible for the acts and omissions of any member of your party.
            </li>
          </ol>
        </li>
        <li>
          <b>Taxes</b>
          <ol>
            <li>
              As a Landowner you are solely responsible for determining your obligations to report,
              collect, remit or include in your Listing Fees any applicable VAT or other indirect
              sales taxes, occupancy tax, tourist or other visitor taxes or income taxes
              <b>(“Taxes“)</b> .
            </li>
          </ol>
        </li>
        <li>
          <b>Prohibited Activities</b>
          <ol>
            <li>
              You are solely responsible for compliance with any and all laws, rules, regulations,
              and Tax obligations that may apply to your use of the Rarewaters Platform. In
              connection with your use of the Rarewaters Platform, you will not and will not assist
              or enable others to:
              <ol style={{ listStyleType: 'disc' }}>
                <li>
                  breach or circumvent any applicable laws or regulations, agreements with
                  third-parties, third-party rights, or our Terms;
                </li>
                <li>
                  use the Rarewaters Platform or Collective Content for any commercial or other
                  purposes that are not expressly permitted by these Terms or in a manner that
                  falsely implies Rarewaters endorsement, partnership or otherwise misleads others
                  as to your affiliation with Rarewaters;
                </li>
                <li>
                  copy, store or otherwise access or use any information, including personally
                  identifiable information about any other User, contained on the Rarewaters
                  Platform in any way that is inconsistent with Rarewaters’s Privacy Policy, these
                  Terms or that otherwise violates the privacy rights of Users or third parties;
                </li>
                <li>
                  use the Rarewaters Platform in connection with the distribution of unsolicited
                  commercial messages (“spam”);
                </li>
                <li>
                  offer, as a Landowner, access to property that you do not yourself own or have
                  permission to make available through the Rarewaters Platform;
                </li>
                <li>
                  contact another User for any purpose other than asking a question related to your
                  own booking, Listing, or the User’s use of the Rarewaters Platform, including, but
                  not limited to, recruiting or otherwise soliciting any User to join third-party
                  services, applications or websites, without our prior written approval;
                </li>
                <li>
                  use the Rarewaters Platform to request, make or accept a booking independent of
                  the Rarewaters Platform, to circumvent any Service Fees or for any other reason;
                </li>
                <li>
                  request, accept or make any payment for Listing Fees outside of the Rarewaters
                  Platform. If you do so, you acknowledge and agree that you: (i) would be in breach
                  of these Terms; (ii) accept all risks and responsibility for such payment, and
                  (iii) hold Rarewaters harmless from any liability for such payment;
                </li>
                <li>
                  discriminate against or harass anyone on the basis of race, national origin,
                  religion, gender, gender identity, physical or mental disability, medical
                  condition, marital status, age or sexual orientation, or otherwise engage in any
                  violent, harmful, abusive or disruptive behavior;
                </li>
                <li>
                  use, display, mirror or frame the Rarewaters Platform or Collective Content, or
                  any individual element within the Rarewaters Platform, Rarewaters’s name, any
                  Rarewaters trademark, logo or other proprietary information, or the layout and
                  design of any page or form contained on a page in the Rarewaters Platform, without
                  Rarewaters’s express written consent;
                </li>
                <li>
                  dilute, tarnish or otherwise harm the Rarewaters brand in any way, including
                  through unauthorized use of Collective Content, registering and/or using
                  Rarewaters or derivative terms in domain names, trade names, trademarks or other
                  source identifiers, or registering and/or using domain names, trade names,
                  trademarks or other source identifiers that closely imitate or are confusingly
                  similar to Rarewaters domains, trademarks, taglines, promotional campaigns or
                  Collective Content;
                </li>
                <li>
                  use any robot, spider, crawler, scraper or other automated means or processes to
                  access, collect data or other content from or otherwise interact with the
                  Rarewaters Platform for any purpose;
                </li>
                <li>
                  avoid, bypass, remove, deactivate, impair, descramble, or otherwise attempt to
                  circumvent any technological measure implemented by Rarewaters or any of
                  Rarewaters’s providers or any other third party to protect the Rarewaters
                  Platform;
                </li>
                <li>
                  attempt to decipher, decompile, disassemble or reverse engineer any of the
                  software used to provide the Rarewaters Platform;
                </li>
                <li>
                  take any action that damages or adversely affects, or could damage or adversely
                  affect the performance or proper functioning of the Rarewaters Platform;
                </li>
                <li>
                  export, re-export, import, or transfer the Application except as authorized by
                  United States law, the export control laws of your jurisdiction, and any other
                  applicable laws; or
                </li>
                <li>violate or infringe anyone else’s rights or otherwise cause harm to anyone.</li>
              </ol>
            </li>
            <li>
              You acknowledge that Rarewaters has no obligation to monitor the access to or use of
              the Rarewaters Platform by any User or to review, disable access to, or edit any User
              Content, but has the right to do so to (i) operate, secure and improve the Rarewaters
              Platform (including without limitation for fraud prevention, risk assessment,
              investigation and customer support purposes); (ii) ensure Users’ compliance with these
              Terms; (iii) comply with applicable law or the order or requirement of a court, law
              enforcement or other administrative agency or governmental body; (iv) respond to User
              Content that it determines is harmful or objectionable; or (v) as otherwise set forth
              in these Terms. Users agree to cooperate with and assist Rarewaters in good faith, and
              to provide Rarewaters with such information and take such actions as may be reasonably
              requested by Rarewaters with respect to any investigation undertaken by Rarewaters or
              a representative of Rarewaters regarding the use or abuse of the Rarewaters Platform.
            </li>
            <li>
              If you feel that any User you interact with, whether online or in person, is acting or
              has acted inappropriately, including but not limited to anyone who (i) engages in
              offensive, violent or sexually inappropriate behavior, (ii) you suspect of stealing
              from you, or (iii) engages in any other disturbing conduct, you should immediately
              report such person to the appropriate authorities and then to Rarewaters by contacting
              us with your police station and report number (if available). You agree that any
              report you make will not obligate us to take any action (beyond that required by law,
              if any).
            </li>
          </ol>
        </li>
        <li>
          <b>Term and Termination, Suspension and other Measures</b>
          <ol>
            <li>
              This Agreement shall be effective until such time when you or Rarewaters terminate the
              Agreement in accordance with this Section 14.
            </li>
            <li>
              You may terminate this Agreement at any time by sending us an email. If you cancel
              your Rarewaters Account as a Landowner, your remaining obligations are outlined in
              Section 7. If you cancel your Rarewaters Account as a Guest, any confirmed booking(s)
              will be automatically cancelled and any refund will depend upon the terms of the
              Listing’s cancellation policy.
            </li>
            <li>
              Without limiting our rights specified below, Rarewaters may terminate this Agreement
              for convenience at any time by giving you thirty (30) days’ notice via email to your
              registered email address.
            </li>
            <li>
              Rarewaters may immediately, without notice, terminate this Agreement and/or stop
              providing access to the Rarewaters Platform if (i) you have materially breached your
              obligations under these Terms or our Privacy Policy, (ii) you have violated applicable
              laws, regulations, or third party rights, or (iii) Rarewaters believes in good faith
              that such action is reasonably necessary to protect the personal safety or property of
              Rarewaters, its Users, or third parties (for example in the case of fraudulent
              behavior of a User).
            </li>
            <li>
              In addition, Rarewaters may take any of the following measures (i) to comply with
              applicable law, or the order or request of a court, law enforcement or other
              administrative agency or governmental body, or if (ii) you have breached these Terms,
              our Privacy Policy, applicable laws, regulations, or third party rights, (iii) you
              have provided inaccurate, fraudulent, outdated or incomplete information during the
              Rarewaters Account registration, Listing process or thereafter, (iv) you and/or your
              Listings or Landowner Services at any time fail to meet any applicable quality or
              eligibility criteria, (v) you have repeatedly received poor Ratings or Reviews or
              Rarewaters otherwise becomes aware of or has received complaints about your
              performance or conduct, (vi) you have repeatedly cancelled confirmed bookings or
              failed to respond to booking requests without a valid reason, or (vii) Rarewaters
              believes in good faith that such action is reasonably necessary to protect the
              personal safety or property of Rarewaters, its Users, or third parties, or to prevent
              fraud or other illegal activity:
              <ol style={{ listStyleType: 'disc' }}>
                <li>cancel any pending or confirmed bookings;</li>
                <li>limit your access to or use of the Rarewaters Platform;</li>
                <li>
                  temporarily or permanently suspend your Rarewaters Account and stop providing
                  access to the Rarewaters Platform.
                </li>
              </ol>
            </li>
            <li>
              When this Agreement has been terminated, you are not entitled to a restoration of your
              Rarewaters Account or any of your User Content. If your access to or use of the
              Rarewaters Platform has been limited or your Rarewaters Account has been suspended or
              this Agreement has been terminated by us, you may not register a new Rarewaters
              Account or access and use the Rarewaters Platform through a Rarewaters Account of
              another User.
            </li>
            <li>
              This Section 14.7 and Sections 5 and 15 to 21 of these Terms shall survive any
              termination or expiration of this Agreement.
            </li>
          </ol>
        </li>
        <li>
          <b>Disclaimers</b>
          <ul>
            <li>
              <b>
                If you choose to use the Rarewaters Platform or Collective Content, you do so
                voluntarily and at your sole risk. The Rarewaters Platform and Collective Content is
                provided “as is”, without warranty of any kind, either express or implied.
              </b>
            </li>
            <li>
              <b>
                You agree that you have had whatever opportunity you deem necessary to investigate
                the Rarewaters Services, laws, rules, or regulations that may be applicable to your
                Listings and/or Landowner Services you are receiving and that you are not relying
                upon any statement of law or fact made by Rarewaters relating to a Listing. provided
                “as is”, without warranty of any kind, either express or implied.
              </b>
            </li>
            <li>
              <b>
                YOU AGREE THAT SOME LANDOWNER SERVICES MAY CARRY INHERENT RISK, AND BY PARTICIPATING
                IN SUCH SERVICES, YOU CHOOSE TO ASSUME THOSE RISKS VOLUNTARILY. FOR EXAMPLE, SOME
                LANDOWNER SERVICES MAY CARRY RISK OF ILLNESS, BODILY INJURY, DISABILITY, OR DEATH,
                AND YOU FREELY AND WILLFULLY ASSUME THOSE RISKS BY CHOOSING TO PARTICIPATE IN THOSE
                LANDOWNER SERVICES. YOU ASSUME FULL RESPONSIBILITY FOR THE CHOICES YOU MAKE BEFORE,
                DURING AND AFTER YOUR PARTICIPATION IN A LANDOWNER SERVICE. IF YOU ARE BRINGING A
                MINOR AS AN ADDITIONAL GUEST, YOU ARE SOLELY RESPONSIBLE FOR THE SUPERVISION OF THAT
                MINOR THROUGHOUT THE DURATION OF YOUR LANDOWNER SERVICE AND TO THE MAXIMUM EXTENT
                PERMITTED BY LAW, YOU AGREE TO RELEASE AND HOLD HARMLESS RAREWATERS FROM ALL
                LIABILITIES AND CLAIMS THAT ARISE IN ANY WAY FROM ANY INJURY, DEATH, LOSS OR HARM
                THAT OCCURS TO THAT MINOR DURING THE LANDOWNER SERVICE OR IN ANY WAY RELATED TO YOUR
                LANDOWNER SERVICE. YOU FURTHER AGREE TO RELEASE AND HOLD HARMLESS LANDOWNER FROM ALL
                LIABILITY AND CLAIMS THAT ARISE IN ANY WAY FROM ANY INJURY, DEATH, LOSS OR HARM THAT
                OCCURS TO YOU OR A MINOR DURING YOUR PARTICIPATION IN THE LANDOWNER SERVICES, OTHER
                THAN ANY SUCH INJURY, DEATH, LOSS OR HARM ARISING FROM LANDOWNER’S GROSS NEGLIGENCE
                OR WILLFUL MISCONDUCT.
              </b>
            </li>
            <li>
              <b>
                The foregoing disclaimers apply to the maximum extent permitted by law. You may have
                other statutory rights. However, the duration of statutorily required warranties, if
                any, shall be limited to the maximum extent permitted by law.
              </b>
            </li>
          </ul>
        </li>
        <li>
          <b>Liability</b> <br></br>
          <ul>
            <li>
              <b>
                YOU ACKNOWLEDGE AND AGREE THAT, TO THE MAXIMUM EXTENT PERMITTED BY LAW, THE ENTIRE
                RISK ARISING OUT OF YOUR ACCESS TO AND USE OF THE RAREWATERS PLATFORM AND COLLECTIVE
                CONTENT, YOUR PUBLISHING OR BOOKING OF ANY LISTING VIA THE RAREWATERS PLATFORM, YOUR
                PARTICIPATION LANDOWNER SERVICE, OR ANY OTHER INTERACTION YOU HAVE WITH OTHER USERS
                WHETHER IN PERSON OR ONLINE REMAINS WITH YOU. NEITHER RAREWATERS NOR ANY OTHER
                PARTY, INCLUDING ANY RETAIL LOCATIONS AT WHICH YOU MADE AN ACCOUNT OR LANDOWNER
                SERVICE RESERVATION, INVOLVED IN CREATING, PRODUCING, OR DELIVERING THE RAREWATERS
                PLATFORM OR COLLECTIVE CONTENT WILL BE LIABLE FOR ANY INCIDENTAL, SPECIAL, EXEMPLARY
                OR CONSEQUENTIAL DAMAGES, INCLUDING LOST PROFITS, LOSS OF DATA OR LOSS OF GOODWILL,
                SERVICE INTERRUPTION, COMPUTER DAMAGE OR SYSTEM FAILURE OR THE COST OF SUBSTITUTE
                PRODUCTS OR SERVICES, OR FOR ANY DAMAGES FOR PERSONAL OR BODILY INJURY OR EMOTIONAL
                DISTRESS ARISING OUT OF OR IN CONNECTION WITH (I) THESE TERMS, (II) FROM THE USE OF
                OR INABILITY TO USE THE RAREWATERS PLATFORM OR COLLECTIVE CONTENT, (III) FROM ANY
                COMMUNICATIONS, INTERACTIONS OR MEETINGS WITH OTHER USERS OR OTHER PERSONS WITH WHOM
                YOU COMMUNICATE, INTERACT OR MEET WITH AS A RESULT OF YOUR USE OF THE RAREWATERS
                PLATFORM, OR (IV) FROM YOUR PUBLISHING OR BOOKING OF A LISTING, INCLUDING THE
                PROVISION OR USE OF A LISTING’S LANDOWNER SERVICES, WHETHER BASED ON WARRANTY,
                CONTRACT, TORT (INCLUDING NEGLIGENCE), PRODUCT LIABILITY OR ANY OTHER LEGAL THEORY,
                AND WHETHER OR NOT RAREWATERS HAS BEEN INFORMED OF THE POSSIBILITY OF SUCH DAMAGE,
                EVEN IF A LIMITED REMEDY SET FORTH HEREIN IS FOUND TO HAVE FAILED OF ITS ESSENTIAL
                PURPOSE. In no event will Rarewaters’s aggregate liability arising out of or in
                connection with these Terms and your use of the Rarewaters Platform including, but
                not limited to, from your publishing or booking of any Listings via the Rarewaters
                Platform, or from the use of or inability to use the Rarewaters Platform or
                Collective Content and in connection with any Landowner Service or any interactions
                with any other Users, exceed the amounts you have paid or owe for bookings via the
                Rarewaters Platform as a Guest in the twelve (12) month period prior to the event
                giving rise to the liability, or if you are a Landowner, the amounts paid by
                Rarewaters to you in the twelve (12) month period prior to the event giving rise to
                the liability, or one hundred U.S. dollars (US$100), if no such payments have been
                made, as applicable. The assumption of liability and limitations of damages set
                forth above are fundamental elements of the basis of the bargain between Rarewaters
                and you. Some jurisdictions do not allow the exclusion or limitation of liability
                for consequential or incidental damages, so the above limitation may not apply to
                you.
              </b>
            </li>
          </ul>
        </li>
        <li>
          <b>Indemnification</b>
          <ul>
            <li>
              To the maximum extent permitted by applicable law, you agree to release, defend (at
              Rarewaters’s option), indemnify, and hold Rarewaters, its affiliates, and their
              respective officers, directors, employees and agents, harmless from and against any
              claims, liabilities, damages, losses, and expenses, including, without limitation,
              reasonable legal and accounting fees, arising out of or in any way connected with (i)
              your breach of these Terms or our Privacy Policy, (ii) your improper use of the
              Rarewaters Platform or any Rarewaters Services, (iii) your interaction with any User
              or participation in any Landowner Service, including without limitation any injuries,
              losses or damages (whether compensatory, direct, incidental, consequential or
              otherwise) of any kind arising in connection with or as a result of such interaction,
              participation, or use, or (iv) your breach of any laws, regulations or third party
              rights.
            </li>
            <li>
              In addition, each Guest, on behalf of himself and each member and/or guest of Guest,
              agrees to indemnify, defend, and save and hold the Landowner, its affiliates, and all
              of their agents, contractors, and employees (collectively, the “Landowner Indemnified
              Parties”) free and harmless for, from, and against any and all liens, claims, loss,
              injury, costs, liability, demands, causes of action, and/or damages including any
              incidental and consequential expenses/damages incurred by the Landowner Indemnified
              Parties associated with or at all attributable to (directly or indirectly) any injury
              to or death of persons or damage to property (including but not in any way limited to
              the Landowner’s property and costs and attorney fees incurred in defense), in whole or
              in part caused by, associated with, or in any way attributable to (directly or
              indirectly), the condition of the Landowner’s property and/or by acts of commission,
              omission or negligence on the part of the Guest, his guests, agents or employees,
              (including trespass onto neighboring lands), arising in conjunction with access to the
              Landowner Services, whether such loss or liability is caused or contributed to by any
              act of the Landowner Indemnified Parties or by the condition of the Landowner’s
              property, including the conditions of any private roads, bridges, drainage structures,
              gates, or other infrastructure installed or maintained by any of the Landowner
              Indemnified Parties
            </li>
          </ul>
        </li>
        <li>
          <b>Dispute Resolution</b>
          <ul>
            <li>
              You agree that all matters relating to your access to or use of the Rarewaters
              Platform, including all disputes, will be governed by the laws of the United States
              and by the laws of the State of Delaware without regard to its conflicts of laws
              provisions. You agree to the personal jurisdiction by and venue in the state and
              federal courts in Denver County, Colorado and waive any objection to such jurisdiction
              or venue. Any claim under these Terms must be brought within one (1) year after the
              cause of action arises, or such claim or cause of action is barred. No recovery may be
              sought or received for damages other than out-of-pocket expenses, except that the
              prevailing party will be entitled to costs and attorneys’ fees. In the event of any
              controversy or dispute between Rarewaters and you arising out of or in connection with
              your use of the Rarewaters Platform, the parties shall attempt, promptly and in good
              faith, to resolve any such dispute. If we are unable to resolve any such dispute
              within a reasonable time (not to exceed thirty (30) days), then either party may
              submit such controversy or dispute to mediation. If the dispute cannot be resolved
              through mediation, then the parties shall be free to pursue any right or remedy
              available to them under applicable law.
            </li>
          </ul>
        </li>
        <li>
          <b>Feedback</b>
          <ul>
            <li>
              We welcome and encourage you to provide feedback, comments and suggestions for
              improvements to the Rarewaters Platform <b>(“Feedback“)</b>. You may submit Feedback
              by emailing us, through the “Contact” section of the Rarewaters Platform, or by other
              means of communication. Any Feedback you submit to us will be considered
              non-confidential and non-proprietary to you. By submitting Feedback to us, you grant
              us a non-exclusive, worldwide, royalty-free, irrevocable, sub-licensable, perpetual
              license to use and publish those ideas and materials for any purpose, without
              compensation to you.
            </li>
          </ul>
        </li>
        <li>
          <b>Applicable Law</b>
          <ul>
            <li>
              These Terms will be interpreted in accordance with the laws of the State of Colorado
              and the United States of America, without regard to conflict-of-law provisions
            </li>
          </ul>
        </li>
        <li>
          <b>General Provisions</b>
          <ol>
            <li>
              Except as they may be supplemented by additional terms and conditions, policies,
              guidelines or standards, these Terms constitute the entire Agreement between
              Rarewaters and you pertaining to the subject matter hereof, and supersede any and all
              prior oral or written understandings or agreements between Rarewaters and you in
              relation to the access to and use of the Rarewaters Platform.
            </li>
            <li>
              No joint venture, partnership, employment, or agency relationship exists between you
              and Rarewaters as a result of this Agreement or your use of the Rarewaters Platform.
            </li>
            <li>
              These Terms do not and are not intended to confer any rights or remedies upon any
              person other than the parties.
            </li>
            <li>
              If any provision of these Terms is held to be invalid or unenforceable, such provision
              will be struck and will not affect the validity and enforceability of the remaining
              provisions.
            </li>
            <li>
              Rarewaters’s failure to enforce any right or provision in these Terms will not
              constitute a waiver of such right or provision unless acknowledged and agreed to by us
              in writing. Except as expressly set forth in these Terms, the exercise by either party
              of any of its remedies under these Terms will be without prejudice to its other
              remedies under these Terms or otherwise permitted under law.
            </li>
            <li>
              You may not assign, transfer or delegate this Agreement and your rights and
              obligations hereunder without Rarewaters’s prior written consent. Rarewaters may
              without restriction assign, transfer or delegate this Agreement and any rights and
              obligations hereunder, at its sole discretion, with 30 days prior notice. Your right
              to terminate this Agreement at any time remains unaffected.
            </li>
            <li>
              Unless specified otherwise, any notices or other communications to Users permitted or
              required under this Agreement, will be provided electronically and given by Rarewaters
              via email, Rarewaters Platform notification, or messaging service (including SMS and
              WeChat).
            </li>
            <li>
              It is Rarewaters’s policy to terminate membership privileges of any User who
              repeatedly infringes copyright upon prompt notification to Rarewaters by the copyright
              owner or the copyright owner’s legal agent. Without limiting the foregoing, if you
              believe that your work has been copied and posted on the Rarewaters Platform in a way
              that constitutes copyright infringement, please provide our Copyright Agent with the
              following information: (1) an electronic or physical signature of the person
              authorized to act on behalf of the owner of the copyright interest; (2) a description
              of the copyrighted work that you claim has been infringed; (3) a description of the
              location on the Rarewaters Platform of the material that you claim is infringing; (4)
              your address, telephone number and e-mail address; (5) a written statement by you that
              you have a good faith belief that the disputed use is not authorized by the copyright
              owner, its agent or the law; and (6) a statement by you, made under penalty of
              perjury, that the above information in your notice is accurate and that you are the
              copyright owner or authorized to act on the copyright owner’s behalf. Contact
              information for Rarewaters’s Copyright Agent for notice of claims of copyright
              infringement is as follows: Rarewaters, LLC,
              <a href="mailto: support@rarewaters.com">support@rarewaters.com</a>.
            </li>
            <li>
              If you have any questions about these Terms please email us at
              <a href="mailto: support@rarewaters.com">support@rarewaters.com</a>.
            </li>
          </ol>
        </li>
      </ul>
    </div>
  );
};

TermsOfService.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

TermsOfService.propTypes = {
  rootClassName: string,
  className: string,
};

export default TermsOfService;
