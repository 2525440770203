import { getArticles, getTopRatedListings, listingReviews } from '../../util/api';
import { convertListingsDataToCardModel } from '../../util/data';
import { storableError } from '../../util/errors';

// ================ Action types ================ //
export const FETCH_ARTICLES_REQUEST = 'app/ArticlesPage/FETCH_ARTICLES_REQUEST';
export const FETCH_ARTICLES_SUCCESS = 'app/ArticlesPage/FETCH_ARTICLES_SUCCESS';
export const FETCH_ARTICLES_ERROR = 'app/ArticlesPage/FETCH_ARTICLES_ERROR';

export const FETCH_TOP_RATED_LISTINGS_REQUEST = 'app/ArticlesPage/FETCH_TOP_RATED_LISTINGS_REQUEST';
export const FETCH_TOP_RATED_LISTINGS_SUCCESS = 'app/ArticlesPage/FETCH_TOP_RATED_LISTINGS_SUCCESS';
export const FETCH_TOP_RATED_LISTINGS_ERROR = 'app/ArticlesPage/FETCH_TOP_RATED_LISTINGS_ERROR';

// ================ Reducer ================ //

const initialState = {
  queryInProgress: false,
  queryListingsError: null,
  articlesData: [],
  topRatedListings: [],
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;

  switch (type) {
    case FETCH_ARTICLES_REQUEST:
      return {
        ...state,
        queryInProgress: true,
      };
    case FETCH_ARTICLES_SUCCESS:
      return {
        ...state,
        articlesData: payload,
        queryInProgress: false,
      };
    case FETCH_ARTICLES_ERROR:
      return {
        ...state,
        queryListingsError: payload,
        queryInProgress: false,
      };
    case FETCH_TOP_RATED_LISTINGS_REQUEST:
      return { ...state, queryListingsError: null, queryInProgress: true };
    case FETCH_TOP_RATED_LISTINGS_SUCCESS: {
      const convertedTopRatedListings = convertListingsDataToCardModel(payload);
      return { ...state, queryInProgress: false, topRatedListings: convertedTopRatedListings };
    }
    case FETCH_TOP_RATED_LISTINGS_ERROR:
      return {
        ...state,
        queryInProgress: false,
      };

    default:
      return state;
  }
}

// ================ Action creators ================ //
export const queryArticlesRequest = queryParams => ({
  type: FETCH_ARTICLES_REQUEST,
  payload: { queryParams },
});

export const queryArticlesSuccess = payload => ({
  type: FETCH_ARTICLES_SUCCESS,
  payload: payload,
});

export const queryArticlesError = e => ({
  type: FETCH_ARTICLES_ERROR,
  error: true,
  payload: e,
});
export const fetchTopRatedListingsRequest = () => ({ type: FETCH_TOP_RATED_LISTINGS_REQUEST });
export const fetchTopRatedListingsSuccess = listings => ({
  type: FETCH_TOP_RATED_LISTINGS_SUCCESS,
  payload: listings,
});
export const fetchTopRatedListingsError = error => ({
  type: FETCH_TOP_RATED_LISTINGS_ERROR,
  error: true,
  payload: error,
});

export const fetchTopRatedListings = () => (dispatch, getState, sdk) => {
  dispatch(fetchTopRatedListingsRequest());
  return getTopRatedListings({})
    .then(async response => {
      const allIncluded = response.data.included;
      response.data.data.forEach(item =>
        item.relationships?.images?.data.forEach(image => {
          image.included = allIncluded.find(includedItem => includedItem.id.uuid === image.id.uuid);
        })
      );
      dispatch(fetchTopRatedListingsSuccess(response.data.data || []));
    })
    .catch(e => {
      dispatch(fetchTopRatedListingsError(storableError(e)));
    });
};

export const loadData = (params, search) => dispatch => {
  return getArticles()
    .then(res => {
      dispatch(queryArticlesSuccess(res.data));
      dispatch(fetchTopRatedListings());
    })
    .catch(() => {
      dispatch(queryArticlesError(storableError(e)));
    });
};
