import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { useConfiguration } from '../../context/configurationContext';
import loadable from '@loadable/component';
import { isScrollingDisabled, manageDisableScrolling } from '../../ducks/ui.duck';

const SectionBuilder = loadable(
  () => import(/* webpackChunkName: "SectionBuilder" */ '../PageBuilder/PageBuilder'),
  {
    resolveComponent: components => components.SectionBuilder,
  }
);

const FooterComponent = props => {
  const { footer = {} } = useConfiguration();

  // If footer asset is not set, let's not render Footer at all.
  if (Object.keys(footer).length === 0) {
    return null;
  }

  // The footer asset does not specify sectionId or sectionType. However, the SectionBuilder
  // expects sectionId and sectionType in order to identify the section. We add those
  // attributes here before passing the asset to SectionBuilder.
  const footerSection = {
    ...footer,
    sectionId: 'footer',
    sectionType: 'footer',
    onManageDisableScrolling: props.onManageDisableScrolling,
  };

  return <SectionBuilder sections={[footerSection]} />;
};

const mapStateToProps = state => {
  return {
    scrollingDisabled: isScrollingDisabled(state),
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
});

const FooterContainer = compose(connect(mapStateToProps, mapDispatchToProps))(FooterComponent);
export default FooterContainer;
