import React from 'react';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { string } from 'prop-types';
import classNames from 'classnames';
import { propTypes } from '../../util/types';

import css from './RiverDataChart.module.css';

const RiverData = props => {
  const { riverData, intl } = props;

  const date = riverData.dateTime;
  const dateString = intl.formatDate(date, { month: 'long', year: 'numeric' });
  const { value, qualifiers } = riverData;

  return (
    <div className={css.riverData}>
      {value} - {qualifiers} - {dateString}
    </div>
  );
};

RiverData.propTypes = {
  riverData: propTypes.riverData.isRequired,
  intl: intlShape.isRequired,
};

const RiverDataChartComponent = props => {
  const { className, rootClassName, riverDataObject, intl } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      {riverDataObject?.streamflow?.map((riverData, index) => {
        return <RiverData key={`RiverData_${index}`} riverData={riverData} intl={intl} />;
      })}
    </div>
  );
};

RiverDataChartComponent.defaultProps = {
  className: null,
  rootClassName: null,
  riverDataObject: null,
};

RiverDataChartComponent.propTypes = {
  className: string,
  rootClassName: string,
  riverDataObject: propTypes.riverDataObject,

  // from injectIntl
  intl: intlShape.isRequired,
};

const RiverDataChart = injectIntl(RiverDataChartComponent);

export default RiverDataChart;
