import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './IconFavouriteHeart.module.css';

const IconFavouriteHeart = props => {
  const { className, rootClassName, alt, small, large } = props;
  const classes = classNames(rootClassName || css.root, className, {
    [css.large]: large,
    [css.small]: small,
  });

  if (alt) {
    return (
      <svg
        className={classes}
        clipRule="evenodd"
        fillRule="evenodd"
        version="1.1"
        viewBox="0 0 26 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="m18.192 0.5439c-2.203 0-4.446 1.042-5.726 3.238-1.285-2.206-3.522-3.248-5.719-3.248-3.183 0-6.281 2.187-6.281 6.191 0 4.661 5.571 9.429 12 15.809 6.43-6.38 12-11.148 12-15.809 0-4.011-3.095-6.181-6.274-6.181" />
      </svg>
    );
  } else {
    return (
      <svg
        className={classes}
        clipRule="evenodd"
        fillRule="evenodd"
        version="1.1"
        viewBox="0 0 26 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="m12.473 21.12c-5.63-5.539-11-10.297-11-14.402 0-3.791 3.068-5.191 5.281-5.191 1.312 0 4.151 0.501 5.719 4.457 1.59-3.968 4.464-4.447 5.726-4.447 2.54 0 5.274 1.621 5.274 5.181 0 4.069-5.136 8.625-11 14.402m5.726-20.583c-2.203 0-4.446 1.042-5.726 3.238-1.285-2.206-3.522-3.248-5.719-3.248-3.183 0-6.281 2.187-6.281 6.191 0 4.661 5.571 9.429 12 15.809 6.43-6.38 12-11.148 12-15.809 0-4.011-3.095-6.181-6.274-6.181" />
      </svg>
    );
  }
};
IconFavouriteHeart.defaultProps = {
  className: null,
  rootClassName: null,
};

IconFavouriteHeart.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconFavouriteHeart;
