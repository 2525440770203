import { fetchCurrentUser } from '../../ducks/user.duck';
import { onBookmark, userOwnBookmarkedListings } from '../../util/api';
import { storableError } from '../../util/errors';

// ================ Action types ================ //

export const FETCH_BOOKMARKED_LISTINGS_REQUEST =
  'app/BookmarkedListingsPage/FETCH_BOOKMARKED_LISTINGS_REQUEST';
export const FETCH_BOOKMARKED_LISTINGS_SUCCESS =
  'app/BookmarkedListingsPage/FETCH_BOOKMARKED_LISTINGS_SUCCESS';
export const FETCH_BOOKMARKED_LISTINGS_ERROR =
  'app/BookmarkedListingsPage/FETCH_BOOKMARKED_LISTINGS_ERROR';

export const UPDATE_BOOKMARK_REQUEST = 'app/BookmarkedListingsPage/UPDATE_BOOKMARK_REQUEST';
export const UPDATE_BOOKMARK_SUCCESS = 'app/BookmarkedListingsPage/UPDATE_BOOKMARK_SUCCESS';
export const UPDATE_BOOKMARK_ERROR = 'app/BookmarkedListingsPage/UPDATE_BOOKMARK_ERROR';

// ================ Reducer ================ //

const initialState = {
  bookmarkedListings: [],
  fetchBookmarkedListingsInProgress: false,
  fetchBookmarkedListingsError: null,
  totalPages: 1,
  updateBookmarkInProgress: false,
  updateBookmarkError: null,
};

const bookmarkedListingsPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_BOOKMARKED_LISTINGS_REQUEST:
      return {
        ...state,
        fetchBookmarkedListingsInProgress: true,
        fetchBookmarkedListingsError: null,
      };
    case FETCH_BOOKMARKED_LISTINGS_SUCCESS:
      return {
        ...state,
        fetchBookmarkedListingsInProgress: false,
        bookmarkedListings: payload.listings,
        totalPages: payload.totalPages || 1,
      };
    case FETCH_BOOKMARKED_LISTINGS_ERROR:
      return {
        ...state,
        fetchBookmarkedListingsInProgress: false,
        fetchBookmarkedListingsError: payload,
      };

    case UPDATE_BOOKMARK_REQUEST:
      return { ...state, updateBookmarkInProgress: true, updateBookmarkError: null };
    case UPDATE_BOOKMARK_SUCCESS:
      return { ...state, updateBookmarkInProgress: false };
    case UPDATE_BOOKMARK_ERROR:
      return { ...state, updateBookmarkInProgress: false, updateBookmarkError: payload };

    default:
      return state;
  }
};

export default bookmarkedListingsPageReducer;

// ================ Action creators ================ //

export const fetchBookmarkedListingsRequest = () => ({ type: FETCH_BOOKMARKED_LISTINGS_REQUEST });
export const fetchBookmarkedListingsSuccess = data => ({
  type: FETCH_BOOKMARKED_LISTINGS_SUCCESS,
  payload: data,
});
export const fetchBookmarkedListingsError = error => ({
  type: FETCH_BOOKMARKED_LISTINGS_ERROR,
  error: true,
  payload: error,
});

export const updateBookmarkRequest = () => ({ type: UPDATE_BOOKMARK_REQUEST });
export const updateBookmarkSuccess = () => ({ type: UPDATE_BOOKMARK_SUCCESS });
export const updateBookmarkError = e => ({ type: UPDATE_BOOKMARK_ERROR, error: true, payload: e });

// ================ Thunks ================ //

export const fetchBookmarkedListings = ({ currentUserId, page }) => (dispatch, getState, sdk) => {
  dispatch(fetchBookmarkedListingsRequest());
  return userOwnBookmarkedListings({ currentUserId, page })
    .then(response => {
      dispatch(fetchBookmarkedListingsSuccess(response.data));
    })
    .catch(e => {
      dispatch(fetchBookmarkedListingsError(storableError(e)));
    });
};

export const removeBookmark = (listingId, currentUserId) => {
  return (dispatch, _getState, _sdk) => {
    dispatch(updateBookmarkRequest());
    onBookmark({ liked: false, listingId, currentUserId })
      .then(async _res => {
        await dispatch(fetchBookmarkedListings({ listingId, currentUserId }));
        dispatch(updateBookmarkSuccess());
      })
      .catch(error => {
        dispatch(updateBookmarkError(error));
      });
  };
};

export const loadData = (params, search, config) => (dispatch, getState, sdk) => {
  return dispatch(fetchCurrentUser()).then(_response => {
    const currentUser = getState().user.currentUser;
    const currentUserId = currentUser.id;
    Promise.all([dispatch(fetchBookmarkedListings({ currentUserId, page: 0 }))]);
  });
};
