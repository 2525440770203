import React from 'react';
import { string } from 'prop-types';

const IconCamping = props => {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="22"
    fill="none"
    viewBox="0 0 64 64"
  >
    <path
      fill="#A03A0D"
      d="M16.64 29.49a1 1 0 00-1 1v18.72a1 1 0 002 0V30.49a1 1 0 00-1-1zM32 35a1 1 0 00-1 1v6.75a1 1 0 002 0V36a1 1 0 00-1-1zM7.87 47.09a1 1 0 00-1 1v10.82a1 1 0 102 0V48.09a1 1 0 00-1-1z"
    ></path>
    <path
      fill="#31BDED"
      d="M53.46 4.09a6 6 0 00-5.29 3.21 4.37 4.37 0 000 8.74h5.33a6 6 0 000-11.95h-.04z"
    ></path>
    <path
      fill="#67D7EF"
      d="M39.9 13.75a1.001 1.001 0 00.239-1.953 1.005 1.005 0 00-.389-.047l-13.16 1 13.16 1h.15zM58.43 18.49l-16.93 1 16.93 1h.12a1.002 1.002 0 10-.12-2zM35.18 6.91a1.002 1.002 0 00-.12-2l-16.93 1 16.93 1h.12zM55.33 24.08l-17.25 1 17.25 1h.12a1.003 1.003 0 00-.12-2z"
    ></path>
    <path
      fill="#F56500"
      d="M60 57.91h-1.5l-12-15.81h-.05a1.529 1.529 0 00-.31-.24l-.17-.06H21.59a1 1 0 00-.42.1.998.998 0 00-.32.25l-12 15.81H4a1 1 0 000 2h56a1 1 0 100-2v-.05zm-39.41 0h-9.32l9.32-12.24v12.24zm2 0V45.67l9.32 12.24h-9.32z"
    ></path>
    <path fill="#A03A0D" d="M55.4 59.91v-17.6a1 1 0 10-2 0v17.6h2z"></path>
    <path
      fill="#4F7F0A"
      d="M23.39 16.65a6.75 6.75 0 10-12.66 3.25A8.52 8.52 0 008 26.2a8.64 8.64 0 1017.27 0 8.52 8.52 0 00-2.73-6.29 6.72 6.72 0 00.85-3.26zM59.06 34.5a5.22 5.22 0 10-9.33 0 6.512 6.512 0 00-1.94 4.66 6.6 6.6 0 1013.2 0 6.511 6.511 0 00-1.93-4.66zM37.07 26.16a5.07 5.07 0 10-9.61 2.25 6.319 6.319 0 00-1.86 4.51 6.41 6.41 0 0012.81 0 6.32 6.32 0 00-1.86-4.51c.344-.7.522-1.47.52-2.25zM11.79 41.08a3.92 3.92 0 10-7.5 1.55A4.81 4.81 0 003 45.92a4.87 4.87 0 009.74 0 4.81 4.81 0 00-1.29-3.29 3.9 3.9 0 00.34-1.55z"
    ></path>
  </svg>
  );
};

IconCamping.defaultProps = { className: null };

IconCamping.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconCamping;
