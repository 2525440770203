import merge from 'lodash/merge';
import { denormalisedResponseEntities } from '../../util/data';
import { storableError } from '../../util/errors';
import { fetchCurrentUser, currentUserShowSuccess } from '../../ducks/user.duck';

// ================ Action types ================ //

export const SAVE_GOOGLE_CALENDAR_REQUEST =
  'app/GoogleCalendarPage/SAVE_GOOGLE_CALENDAR_DETAILS_REQUEST';
export const SAVE_GOOGLE_CALENDAR_SUCCESS = 'app/GoogleCalendarPage/SAVE_GOOGLE_CALENDAR_SUCCESS';
export const SAVE_GOOGLE_CALENDAR_ERROR = 'app/GoogleCalendarPage/SAVE_GOOGLE_CALENDAR_ERROR';

// ================ Reducer ================ //

const initialState = {
  saveGoogleCalendarInProgress: false,
  saveGoogleCalendarError: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SAVE_GOOGLE_CALENDAR_REQUEST:
      return { ...state, saveGoogleCalendarInProgress: true };
    case SAVE_GOOGLE_CALENDAR_SUCCESS:
      return { ...state, saveGoogleCalendarError: null, saveGoogleCalendarInProgress: false };
    case SAVE_GOOGLE_CALENDAR_ERROR:
      return {
        ...state,
        saveGoogleCalendarInProgress: false,
        saveGoogleCalendarError: payload,
      };
    default:
      return state;
  }
}

// ================ Action creators ================ //

export const saveGoogleCalendarRequest = () => ({ type: SAVE_GOOGLE_CALENDAR_REQUEST });
export const saveGoogleCalendarSuccess = () => ({ type: SAVE_GOOGLE_CALENDAR_SUCCESS });
export const saveGoogleCalendarError = payload => ({ type: SAVE_GOOGLE_CALENDAR_ERROR, payload });

// ================ Thunks ================ //

export const requestSaveGoogleCalendar = params => (dispatch, getState, sdk) => {
  dispatch(saveGoogleCalendarRequest());
  return sdk.currentUser
    .updateProfile(
      { privateData: { googleCalendar: { ...params } } },
      {
        expand: true,
        include: ['profileImage'],
        'fields.image': ['variants.square-small', 'variants.square-small2x'],
      }
    )
    .then(response => {
      const entities = denormalisedResponseEntities(response);
      if (entities.length !== 1) {
        throw new Error('Expected a resource in the sdk.currentUser.updateProfile response');
      }
      dispatch(saveGoogleCalendarSuccess());
      const currentUser = entities[0];
      return currentUser;
    })
    .catch(e => {
      dispatch(saveGoogleCalendarError(storableError(e)));
   
      throw e;
    });
};

export const loadData = () => {
  return fetchCurrentUser();
};
