import { denormalisedResponseEntities } from '../../util/data';
import { storableError } from '../../util/errors';

// ================ Action types ================ //

export const UPDATE_DISCOUNT_CODES_REQUEST = 'app/DiscountCodePage/UPDATE_DISCOUNT_CODES_REQUEST';
export const UPDATE_DISCOUNT_CODES_SUCCESS = 'app/DiscountCodePage/UPDATE_DISCOUNT_CODES_REQUEST';

export const DELETE_DISCOUNT_CODE_SUCCESS = 'app/DiscountCodePage/UPDATE_DISCOUNT_CODES_REQUEST';

export const FETCH_DISCOUNT_CODES_SUCCESS = 'app/DiscountCodePage/FETCH_DISCOUNT_CODES_SUCCESS';

// ================ Reducer ================ //

const initialState = {
  discountCodes: [],
  updateInProgress: false,
  updateError: null,
  fetchDiscountCodesInProgress: true,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;

  switch (type) {
    case UPDATE_DISCOUNT_CODES_REQUEST:
      return {
        updateInProgress: true,
        fetchDiscountCodesInProgress: true,
        updateError: null,
      };
    case UPDATE_DISCOUNT_CODES_SUCCESS:
      return {
        ...state,
        updateInProgress: false,
        fetchDiscountCodesInProgress: false,
      };
    case FETCH_DISCOUNT_CODES_SUCCESS:
      return {
        ...state,
        discountCodes: payload.discountCodes,
        fetchDiscountCodesInProgress: false,
      };

    case DELETE_DISCOUNT_CODE_SUCCESS:
      return {
        ...state,
        discountCodes: payload.discountCodes,
        fetchDiscountCodesInProgress: false,
      };
    default:
      return state;
  }
}

// ================ Action creators ================ //
export const updateDiscountCodeRequest = () => ({
  type: UPDATE_DISCOUNT_CODES_REQUEST,
});

export const fetchDiscountCodesSuccess = params => ({
  type: FETCH_DISCOUNT_CODES_SUCCESS,
  payload: params,
});

export const deleteDiscountCodeSuccess = params => ({
  type: DELETE_DISCOUNT_CODE_SUCCESS,
  payload: params,
});

export const loadData = () => {
  return (dispatch, getState, sdk) => {
    return sdk.currentUser
      .show()
      .then(res => {
        const { attributes } = res.data.data;
        const { discountCodes } = attributes.profile.privateData;
        if (discountCodes) {
          dispatch(
            fetchDiscountCodesSuccess({
              discountCodes: discountCodes.map((code, index) => ({ ...code, index })),
            })
          );
        }
      })
      .catch(e => storableError(e));
  };
};

export const updateDiscountCodes = (actionPayload, cb) => {
  return (dispatch, getState, sdk) => {
    let { discountCodes } = getState().DiscountCodePage;
    if (typeof actionPayload.index !== 'undefined') {
      discountCodes = discountCodes.map((code, index) => {
        if (index === actionPayload.index) {
          return actionPayload;
        } else return code;
      });
    } else {
      discountCodes.push(actionPayload);
    }
    const bodyParams = {
      privateData: {
        discountCodes: discountCodes.map(code => {
          delete code.index;
          return code;
        }),
      },
    };
    return sdk.currentUser.updateProfile(JSON.parse(JSON.stringify(bodyParams))).then(() => {
      dispatch(loadData());
      cb();
    });
  };
};

export const deleteDiscountCode = actionPayload => {
  return (dispatch, getState, sdk) => {
    const { discountCodes } = getState().DiscountCodePage;

    const bodyParams = {
      privateData: {
        discountCodes: discountCodes.filter(code => code.code !== actionPayload.code),
      },
    };
    return sdk.currentUser
      .updateProfile(JSON.parse(JSON.stringify(bodyParams)))
      .then(() => dispatch(loadData()));
  };
};
