import { getSeasonPassOrderHistory, getUserSeasonPasses } from '../util/api';
import { storableError } from '../util/errors';

export const FETCH_CURRENT_USER_SEASON_PASS_REQUEST =
  'app/seasonPass/FETCH_CURRENT_USER_SEASON_PASS_REQUEST';
export const FETCH_CURRENT_USER_SEASON_PASS_SUCCESS =
  'app/seasonPass/FETCH_CURRENT_USER_GIFT_CARDS_SUCCESS';
export const FETCH_CURRENT_USER_SEASON_PASS_ERROR =
  'app/seasonPass/FETCH_CURRENT_USER_GIFT_CARDS_ERROR';

export const FETCH_SEASON_PASS_ORDER_HISTORY_REQUEST =
  'app/seasonPass/FETCH_SEASON_PASS_ORDER_HISTORY_REQUEST';
export const FETCH_SEASON_PASS_ORDER_HISTORY_SUCCESS =
  'app/seasonPass/FETCH_SEASON_PASS_ORDER_HISTORY_SUCCESS';
export const FETCH_SEASON_PASS_ORDER_HISTORY_ERROR =
  'app/seasonPass/FETCH_SEASON_PASS_ORDER_HISTORY_ERROR';

const initialState = {
  getUserSeasonPassInProgress: false,
  getUserSeasonPassError: null,
  seasonPasses: [],
  seasonPassHistory: [],
  getSeasonPassHistoryInProgress: false,
  getSeasonPassHistoryError: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;

  switch (type) {
    case FETCH_CURRENT_USER_SEASON_PASS_REQUEST:
      return {
        ...state,
        getUserSeasonPassInProgress: true,
        getUserSeasonPassError: null,
      };

    case FETCH_CURRENT_USER_SEASON_PASS_SUCCESS:
      return {
        ...state,
        getUserSeasonPassInProgress: false,
        seasonPasses: payload,
      };

    case FETCH_CURRENT_USER_SEASON_PASS_ERROR:
      return {
        ...state,
        getUserSeasonPassInProgress: false,
        getUserSeasonPassError: payload,
      };

    case FETCH_SEASON_PASS_ORDER_HISTORY_REQUEST:
      return {
        ...state,
        seasonPassHistory: [],
        getSeasonPassHistoryInProgress: true,
        getSeasonPassHistoryError: null,
      };

    case FETCH_SEASON_PASS_ORDER_HISTORY_SUCCESS:
      return {
        ...state,
        seasonPassHistory: payload,
        getSeasonPassHistoryInProgress: false,
      };

    case FETCH_SEASON_PASS_ORDER_HISTORY_ERROR:
      return {
        ...state,
        getSeasonPassHistoryInProgress: false,
        getSeasonPassHistoryError: payload,
      };

    default:
      return state;
  }
}

export const fetchCurrentUserSeasonPassRequest = () => ({
  type: FETCH_CURRENT_USER_SEASON_PASS_REQUEST,
});

export const fetchCurrentUserSeasonPassSuccess = payload => ({
  type: FETCH_CURRENT_USER_SEASON_PASS_SUCCESS,
  payload,
});

export const fetchCurrentUserSeasonPassError = e => ({
  type: FETCH_CURRENT_USER_SEASON_PASS_ERROR,
  payload: e,
});

export const fetchSeasonPassOrderHistoryRequest = e => ({
  type: FETCH_SEASON_PASS_ORDER_HISTORY_REQUEST,
});

export const fetchSeasonPassOrderHistorySuccess = payload => ({
  type: FETCH_SEASON_PASS_ORDER_HISTORY_SUCCESS,
  payload,
});

export const fetchSeasonPassOrderHistoryError = error => ({
  type: FETCH_SEASON_PASS_ORDER_HISTORY_REQUEST,
  payload: error,
});

export const getCurrentUserSeasonPasses = () => dispatch => {
  dispatch(fetchCurrentUserSeasonPassRequest());

  return getUserSeasonPasses({})
    .then(response => dispatch(fetchCurrentUserSeasonPassSuccess(response.data)))
    .catch(error => dispatch(fetchCurrentUserSeasonPassError(error)));
};

export const getSelectedSeasonPassOrderHistory = seasonPassId => dispatch => {
  dispatch(fetchSeasonPassOrderHistoryRequest());

  return getSeasonPassOrderHistory({ seasonPassId })
    .then(response => dispatch(fetchSeasonPassOrderHistorySuccess(response.data)))
    .catch(error => dispatch(fetchSeasonPassOrderHistoryError(storableError(error))));
};
