import reverse from 'lodash/reverse';
import sortBy from 'lodash/sortBy';
import { storableError } from '../../util/errors';
import { parse } from '../../util/urlHelpers';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { defaultApi, filterTransaction, getAnglers } from '../../util/api';
import { convertDateAsTimezoneDate } from '../../util/dates';
import { USER_TYPES } from '../../config/configDefault';
import { fetchCurrentUser } from '../../ducks/user.duck';
import moment from 'moment';

const sortedTransactions = txs =>
  reverse(
    sortBy(txs, tx => {
      return tx.attributes ? tx.attributes.lastTransitionedAt : null;
    })
  );

// ================ Action types ================ //

export const FETCH_ORDERS_OR_SALES_REQUEST = 'app/InboxPage/FETCH_ORDERS_OR_SALES_REQUEST';
export const FETCH_ORDERS_OR_SALES_SUCCESS = 'app/InboxPage/FETCH_ORDERS_OR_SALES_SUCCESS';
export const FETCH_ORDERS_OR_SALES_ERROR = 'app/InboxPage/FETCH_ORDERS_OR_SALES_ERROR';

export const FETCH_RECOMMENDED_LISTINGS_REQUEST =
  'app/InboxPage/FETCH_RECOMMENDED_LISTINGS_REQUEST';
export const FETCH_RECOMMENDED_LISTINGS_SUCCESS =
  'app/InboxPage/FETCH_RECOMMENDED_LISTINGS_SUCCESS';
export const FETCH_RECOMMENDED_LISTINGS_ERROR = 'app/InboxPage/FETCH_RECOMMENDED_LISTINGS_ERROR';

export const FETCH_ANGLERS_REQUEST = 'app/ManageListingsPage/FETCH_ANGLERS_REQUEST';
export const FETCH_ANGLERS_SUCCESS = 'app/ManageListingsPage/FETCH_ANGLERS_SUCCESS';
export const FETCH_ANGLERS_ERROR = 'app/ManageListingsPage/FETCH_ANGLERS_ERROR';

// ================ Reducer ================ //

const entityRefs = entities =>
  entities.map(entity => ({
    id: entity.id,
    type: entity.type,
  }));

const initialState = {
  fetchInProgress: false,
  fetchOrdersOrSalesError: null,
  pagination: null,
  transactionRefs: [],
  recommendedListings: [],
  fetchRecommendedListingsInProgress: false,
  fetchRecommendedListingsError: null,
  anglers: [],
  fetchAnglersInProgress: false,
  fetchAnglersError: null,
};

export default function inboxPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_ORDERS_OR_SALES_REQUEST:
      return { ...state, fetchInProgress: true, fetchOrdersOrSalesError: null };
    case FETCH_ORDERS_OR_SALES_SUCCESS: {
      const transactions = sortedTransactions(payload.data.data);
      return {
        ...state,
        fetchInProgress: false,
        transactionRefs: entityRefs(transactions),
        pagination: payload.data.meta,
      };
    }
    case FETCH_ORDERS_OR_SALES_ERROR:
      return { ...state, fetchInProgress: false, fetchOrdersOrSalesError: payload };

    case FETCH_RECOMMENDED_LISTINGS_REQUEST:
      return {
        ...state,
        fetchRecommendedListingsInProgress: true,
        fetchRecommendedListingsError: null,
      };
    case FETCH_RECOMMENDED_LISTINGS_SUCCESS: {
      return {
        ...state,
        recommendedListings: payload,
        fetchRecommendedListingsInProgress: false,
      };
    }
    case FETCH_RECOMMENDED_LISTINGS_ERROR:
      return {
        ...state,
        fetchRecommendedListingsInProgress: false,
        fetchRecommendedListingsError: payload,
      };

    case FETCH_ANGLERS_REQUEST:
      return {
        ...state,
        fetchAnglersInProgress: true,
        fetchAnglersError: null,
      };

    case FETCH_ANGLERS_SUCCESS:
      return {
        ...state,
        anglers: payload.data,
        fetchAnglersInProgress: false,
      };

    case FETCH_ANGLERS_ERROR:
      return { ...state, fetchAnglersInProgress: false, fetchAnglersError: payload };

    default:
      return state;
  }
}

// ================ Action creators ================ //

const fetchOrdersOrSalesRequest = () => ({ type: FETCH_ORDERS_OR_SALES_REQUEST });
const fetchOrdersOrSalesSuccess = response => ({
  type: FETCH_ORDERS_OR_SALES_SUCCESS,
  payload: response,
});
const fetchOrdersOrSalesError = e => ({
  type: FETCH_ORDERS_OR_SALES_ERROR,
  error: true,
  payload: e,
});

const fetchRecommendedListingsRequest = () => ({ type: FETCH_RECOMMENDED_LISTINGS_REQUEST });
const fetchRecommendedListingsSuccess = response => ({
  type: FETCH_RECOMMENDED_LISTINGS_SUCCESS,
  payload: response,
});
const fetchRecommendedListingsError = e => ({
  type: FETCH_RECOMMENDED_LISTINGS_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

const INBOX_PAGE_SIZE = 10;

export const fetchRecommendedListings = (keywords = null) => (dispatch, getState, sdk) => {
  dispatch(fetchRecommendedListingsRequest());

  const body = {
    keywords,
    pub_listingType: ['daily-booking', 'instant-booking'],
  };

  return sdk.listings
    .query(body)
    .then(response => {
      dispatch(fetchRecommendedListingsSuccess(response.data.data));
    })
    .catch(e => dispatch(fetchRecommendedListingsError(storableError(e))));
};

export const fetchAnglersRequest = queryParams => ({
  type: FETCH_ANGLERS_REQUEST,
  payload: { queryParams },
});

export const fetchAnglersSuccess = response => ({
  type: FETCH_ANGLERS_SUCCESS,
  payload: { ...response.data },
});

export const fetchAnglersError = error => ({
  type: FETCH_ANGLERS_ERROR,
  error: true,
  payload: error,
});

export const onUpdateTransaction = (transactionId, params, transition, search, config) => (
  dispatch,
  getState,
  sdk
) => {
  const { tab } = params;

  const onlyFilterValues = {
    orders: 'order',
    sales: 'sale',
  };

  const onlyFilter = onlyFilterValues[tab];
  if (!onlyFilter) {
    return Promise.reject(new Error(`Invalid tab for InboxPage: ${tab}`));
  }

  dispatch(fetchOrdersOrSalesRequest());

  return defaultApi
    .transactionTransition({
      id: transactionId,
      transition: transition,
      params: {},
    })
    .then(() => {
      dispatch(loadData(params, search, config));
    })
    .catch(e => {
      dispatch(fetchOrdersOrSalesError(storableError(e)));
      throw e;
    });
};

export const loadData = (params, search, config) => (dispatch, getState, sdk) => {
  return dispatch(fetchCurrentUser()).then(_ => {
    const user = getState().user.currentUser;

    const isCurrentUserAdminOrLandOwner = () =>
      user?.attributes?.profile?.metadata?.userType === USER_TYPES.admin ||
      user?.attributes?.profile?.metadata?.userType === USER_TYPES.landOwner;

    const loadDataCallback = async () => {
      const { tab } = params;
      const {
        page = 1,
        listingId,
        giftCardId,
        giftCardPaymentOnly,
        seasonPassId,
        seasonPassPaymentOnly,
        bookingStart,
        bookingEnd,
        bookingStatus,
        filterAllBookings,
        anglerId,
      } = parse(search);

      const timezone = config.localization.timezone;

      const now = new Date();
      now.setHours(0, 0, 0, 0);
      const upcomingStartMaybe = !filterAllBookings
        ? convertDateAsTimezoneDate(now, timezone)
        : undefined;

      const onlyFilterValues = {
        orders: 'order',
        sales: 'sale',
      };

      const onlyFilter = onlyFilterValues[tab];
      if (!onlyFilter) {
        return Promise.reject(new Error(`Invalid tab for InboxPage: ${tab}`));
      }

      dispatch(fetchOrdersOrSalesRequest());

      try {
        const response = await filterTransaction({
          bookingType: onlyFilterValues[tab],
          listingId: listingId,
          giftCardId: giftCardId,
          giftCardPaymentOnly: giftCardPaymentOnly,
          seasonPassId,
          seasonPassPaymentOnly,
          ...(bookingStart && bookingEnd
            ? {
                bookingStart: convertDateAsTimezoneDate(bookingStart, timezone),
                bookingEnd: convertDateAsTimezoneDate(moment(bookingEnd).add(1, 'd'), timezone),
              }
            : {}),
          bookingStatus: bookingStatus,
          filterAllBookings: filterAllBookings,
          ...(upcomingStartMaybe ? { upcomingStart: upcomingStartMaybe } : {}),
          anglerId: anglerId,
          page: page,
          perPage: INBOX_PAGE_SIZE,
        });

        dispatch(addMarketplaceEntities(response, { userFields: ['firstName', 'lastName'] }));
        dispatch(fetchOrdersOrSalesSuccess(response));
        dispatch(fetchRecommendedListings());
        return response;
      } catch (e) {
        dispatch(fetchOrdersOrSalesError(storableError(e)));
        throw e;
      }
    };

    if (isCurrentUserAdminOrLandOwner() && getState().InboxPage.anglers.length === 0) {
      dispatch(fetchAnglersRequest());
      return getAnglers()
        .then(response => {
          dispatch(fetchAnglersSuccess(response));
          return loadDataCallback();
        })
        .catch(error => {
          console.error(error);
          dispatch(fetchAnglersError(storableError(error)));
        });
    } else {
      return loadDataCallback();
    }
  });
};
