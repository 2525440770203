import { denormalisedResponseEntities } from '../../util/data';
import { storableError } from '../../util/errors';
import { types as sdkTypes, createImageVariantConfig } from '../../util/sdkLoader';

// ================ Action types ================ //

export const FETCH_LISTINGS_REQUEST = 'app/GiftCardFormPage/FETCH_LISTINGS_REQUEST';
export const FETCH_LISTINGS_SUCCESS = 'app/GiftCardFormPage/FETCH_LISTINGS_SUCCESS';
export const FETCH_LISTINGS_ERROR = 'app/GiftCardFormPage/FETCH_LISTINGS_ERROR';

// ================ Reducer ================ //

const initialState = {
  fetchListings: [],
  fetchListingsInProgress: false,
  fetchListingsError: null,
};

const giftCardFormPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_LISTINGS_REQUEST:
      return { ...state, fetchListingsInProgress: true, fetchListingsError: null };
    case FETCH_LISTINGS_SUCCESS: {
      return {
        ...state,
        fetchListingsInProgress: false,
        fetchListings: payload,
      };
    }
    case FETCH_LISTINGS_ERROR:
      return { ...state, fetchListingsInProgress: false, fetchListingsError: payload };

    default:
      return state;
  }
};

export default giftCardFormPageReducer;

// ================ Action creators ================ //

export const fetchListingsRequest = () => ({ type: FETCH_LISTINGS_REQUEST });
export const fetchListingsSuccess = listings => ({
  type: FETCH_LISTINGS_SUCCESS,
  payload: listings,
});
export const fetchListingsError = error => ({
  type: FETCH_LISTINGS_ERROR,
  error: true,
  payload: error,
});

// ================ Thunks ================ //

const getImageVariants = listingImageConfig => {
  const { aspectWidth = 1, aspectHeight = 1, variantPrefix = 'listing-card' } = listingImageConfig;
  const aspectRatio = aspectHeight / aspectWidth;
  return {
    'fields.image': [
      // Profile images
      'variants.square-small',
      'variants.square-small2x',

      // Listing images:
      `variants.${variantPrefix}`,
      `variants.${variantPrefix}-2x`,
    ],
    ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
  };
};

export const fetchListings = config => (dispatch, getState, sdk) => {
  dispatch(fetchListingsRequest());
  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;

  const aspectRatio = aspectHeight / aspectWidth;
  return sdk.listings
    .query({
      pub_listingType: 'gift-card',
      include: ['author', 'author.profileImage', 'images', 'currentStock'],
      'fields.image': [
        // Scaled variants for large images
        'variants.scaled-small',
        'variants.scaled-medium',
        'variants.scaled-large',
        'variants.scaled-xlarge',

        // Cropped variants for listing thumbnail images
        `variants.${variantPrefix}`,
        `variants.${variantPrefix}-2x`,
        `variants.${variantPrefix}-4x`,
        `variants.${variantPrefix}-6x`,

        // Social media
        'variants.facebook',
        'variants.twitter',

        // Avatars
        'variants.square-small',
        'variants.square-small2x',
      ],
      ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
      ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
      ...createImageVariantConfig(`${variantPrefix}-4x`, 1600, aspectRatio),
      ...createImageVariantConfig(`${variantPrefix}-6x`, 2400, aspectRatio),
    })
    .then(response => {
      response.data.data = response.data.data.map(item => {
        return {
          ...item,
          images: item.relationships.images.data,
          author: item.relationships.author.data,
          currentStock: item.relationships.currentStock.data,
        };
      });

      const entities = denormalisedResponseEntities(response);

      dispatch(fetchListingsSuccess(entities));
    })
    .catch(e => dispatch(fetchListingsError(storableError(e))));
};

export const loadData = (params, search, config) => dispatch => {
  return Promise.all([dispatch(fetchListings(config))]);
};
