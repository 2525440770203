import { getArticles, deleteArticle } from '../../util/api';
import { storableError } from '../../util/errors';
import { parse } from '../../util/urlHelpers';
// ================ Action types ================ //

export const FETCH_ARTICLES_REQUEST = 'app/ManageArticlesPage/FETCH_ARTICLES_REQUEST';
export const FETCH_ARTICLES_SUCCESS = 'app/ManageArticlesPage/FETCH_ARTICLES_SUCCESS';
export const FETCH_ARTICLES_ERROR = 'app/ManageArticlesPage/FETCH_ARTICLES_ERROR';

export const DELETE_ARTICLE_REQUEST = 'app/ManageArticlesPage/DELETE_ARTICLE_REQUEST';
export const DELETE_ARTICLE_SUCCESS = 'app/ManageArticlesPage/DELETE_ARTICLE_SUCCESS';
export const DELETE_ARTICLE_ERROR = 'app/ManageArticlesPage/DELETE_ARTICLE_ERROR';
// ================ Reducer ================ //

const initialState = {
  pagination: null,
  queryParams: null,
  queryInProgress: false,
  queryListingsError: null,
  articles: null,
  deleteArticleList: null,
  deleteArticleError: null,
};

const manageArticlesPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_ARTICLES_REQUEST:
      return {
        ...state,
        queryParams: payload.queryParams,
        queryInProgress: true,
        queryListingsError: null,
      };
    case FETCH_ARTICLES_SUCCESS:
      return {
        ...state,
        articles: payload.data,
        pagination: payload.meta,
        queryInProgress: false,
      };
    case FETCH_ARTICLES_ERROR:
      return { ...state, queryInProgress: false, queryListingsError: payload };

    case DELETE_ARTICLE_REQUEST:
      return {
        ...state,
        deleteArticleList: payload.listingId,
        deleteArticleError: null,
      };
    case DELETE_ARTICLE_SUCCESS:
      return {
        ...state,
        deleteArticleList: payload,
      };
    case DELETE_ARTICLE_ERROR: {
      return {
        ...state,
        deleteArticleList: null,
        deleteArticleError: {
          listingId: state.deleteArticle,
          error: payload,
        },
      };
    }

    default:
      return state;
  }
};

export default manageArticlesPageReducer;

// ================ Action creators ================ //

export const queryArticlesRequest = queryParams => ({
  type: FETCH_ARTICLES_REQUEST,
  payload: { queryParams },
});

export const queryArticlesSuccess = payload => ({
  type: FETCH_ARTICLES_SUCCESS,
  payload: payload,
});

export const queryArticlesError = e => ({
  type: FETCH_ARTICLES_ERROR,
  error: true,
  payload: e,
});

export const deleteArticleRequest = listingId => ({
  type: DELETE_ARTICLE_REQUEST,
  payload: { listingId },
});

export const deleteArticleSuccess = response => ({
  type: DELETE_ARTICLE_SUCCESS,
  payload: response.data,
});

export const deleteArticleError = e => ({
  type: DELETE_ARTICLE_ERROR,
  error: true,
  payload: e,
});

export const deleteArticleListing = listingId => (dispatch, getState, sdk) => {
  dispatch(deleteArticleRequest(listingId));

  return deleteArticle({ id: listingId })
    .then(res => {
      dispatch(loadData());
    })
    .catch(() => {
      dispatch(deleteArticleError(storableError(e)));
    });
};

export const loadData = (params, search) => dispatch => {
  const queryParams = parse(search);
  const page = queryParams.page || 1;
  return getArticles({ page })
    .then(res => {
      dispatch(queryArticlesSuccess(res));
    })
    .catch(() => {
      dispatch(queryArticlesError(storableError(e)));
    });
};
