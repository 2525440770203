import { createNewArticle, editSelectedArticle, getArticleById, getArticles } from '../../util/api';
import { storableError } from '../../util/errors';

// ================ Action types ================ //
export const SET_ARTICLE_DATA_REQUEST = 'app/EditArticlePage/SET_ARTICLE_DATA_REQUEST';
export const SET_ARTICLE_DATA_SUCCESS = 'app/EditArticlePage/SET_ARTICLE_DATA_SUCCESS';
export const SET_ARTICLE_DATA_ERROR = 'app/EditArticlePage/SET_ARTICLE_DATA_ERROR';

// ================ Reducer ================ //

export const SET_SELECTED_ARTICLE = 'app/editArticlePage/SET_SELECTED_ARTICLE';

const initialState = {
  articleData: null,
  loading: false,
  articles: [],
  selectedArticle: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_ARTICLE_DATA_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case SET_ARTICLE_DATA_SUCCESS:
      return {
        ...state,
        articles: payload,
        loading: false,
      };
    case SET_ARTICLE_DATA_ERROR:
      return {
        ...state,
        articles: payload,
        loading: false,
      };
    case SET_SELECTED_ARTICLE:
      return {
        ...state,
        selectedArticle: payload,
      };
    default:
      return state;
  }
}

// ================ Action creators ================ //
export const setArticleDataRequest = params => ({
  type: SET_ARTICLE_DATA_REQUEST,
  payload: params,
});

export const setArticleDataSuccess = params => ({
  type: SET_ARTICLE_DATA_SUCCESS,
  payload: params,
});
export const setArticleDataError = params => ({
  type: SET_ARTICLE_DATA_ERROR,
  error: true,
  payload: params,
});

export const setSelectedArticle = params => ({
  type: SET_SELECTED_ARTICLE,
  payload: params,
});

export const loadData = (params, search) => dispatch => {
  return getArticles()
    .then(res => {
      if (params.id !== '0') {
        dispatch(setSelectedArticle(res.data.find(data => data._id === params.id)));
      }
      dispatch(setArticleDataSuccess(res.data));
    })
    .catch(() => {
      dispatch(setArticleDataRequest(null));
    });
};

export const createArticle = (params, search) => dispatch => {
  if (params.imageFile) delete params.imageFile;
  return createNewArticle(params)
    .then(res => {
      dispatch(setArticleDataSuccess(res.data));
    })
    .catch(() => {
      dispatch(setArticleDataError(storableError(e)));
    });
};

export const editArticle = (params, search) => dispatch => {
  return editSelectedArticle(params)
    .then(res => {})
    .catch(() => {
      dispatch(setArticleDataError(storableError(e)));
    });
};
