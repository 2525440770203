

import React from 'react';
import { string } from 'prop-types';

const IconLake = props => {
  return (
<svg width={22} height={22} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
<g>
  <path fill="#5e8569" d="M280 360L24 224 24 360 280 360z"></path>
  <path
    fill="#5e8569"
    d="M488 360L488 288 344 208 200 360 488 360z"
  ></path>
  <path fill="#85bc94" d="M408 360L24 360 168 224 408 360z"></path>
  <circle cx="255.996" cy="119.984" r="32" fill="#fbb540"></circle>
  <path
    fill="#fbedbf"
    d="M256 64a8 8 0 01-8-8V40a8 8 0 0116 0v16a8 8 0 01-8 8zM224.008 72.576a8 8 0 01-6.936-4l-4-6.928a8 8 0 0113.856-8l4 6.928a8 8 0 01-6.92 12zM200.566 96a7.956 7.956 0 01-3.992-1.073l-6.928-4a8 8 0 018-13.856l6.928 4A8 8 0 01200.566 96zM192 128h-16a8 8 0 010-16h16a8 8 0 010 16zM193.654 164a8 8 0 01-4.008-14.929l6.928-4a8 8 0 118 13.856l-6.928 4a7.963 7.963 0 01-3.992 1.073zM219.992 190.355a8 8 0 01-6.92-12l4-6.928a8 8 0 1113.856 8l-4 6.928a8 8 0 01-6.936 4zM256 208a8 8 0 01-8-8v-16a8 8 0 0116 0v16a8 8 0 01-8 8zM292.008 190.355a8 8 0 01-6.936-4l-4-6.928a8 8 0 0113.856-8l4 6.928a8 8 0 01-6.92 12zM318.346 164a7.95 7.95 0 01-3.992-1.073l-6.928-4a8 8 0 118-13.856l6.928 4A8 8 0 01318.346 164zM336 128h-16a8 8 0 010-16h16a8 8 0 010 16zM311.434 96a8 8 0 01-4.008-14.929l6.928-4a8 8 0 018 13.856l-6.928 4A7.969 7.969 0 01311.434 96zM287.992 72.576a8 8 0 01-6.92-12l4-6.928a8 8 0 1113.856 8l-4 6.928a8 8 0 01-6.936 4z"
  ></path>
  <path
    fill="#c0f5f9"
    d="M488 392L488 488 24 488 24 360 232 360 488 392z"
  ></path>
  <path
    fill="#8f563b"
    d="M408 368a8 8 0 01-8-8v-24a8 8 0 0116 0v24a8 8 0 01-8 8z"
  ></path>
  <path
    fill="#9a826a"
    d="M488 392H232a16 16 0 01-16-16 16 16 0 0116-16h256z"
  ></path>
  <path
    fill="#746250"
    d="M312 432a8 8 0 01-8-8v-80a8 8 0 0116 0v80a8 8 0 01-8 8zM376 432a8 8 0 01-8-8v-80a8 8 0 0116 0v80a8 8 0 01-8 8z"
  ></path>
  <path
    fill="#aedee2"
    d="M328 432h-32a8 8 0 010-16h32a8 8 0 010 16z"
  ></path>
  <path
    fill="#746250"
    d="M248 432a8 8 0 01-8-8v-80a8 8 0 0116 0v80a8 8 0 01-8 8z"
  ></path>
  <path
    fill="#aedee2"
    d="M264 432h-32a8 8 0 010-16h32a8 8 0 010 16zM392 432h-32a8 8 0 010-16h32a8 8 0 010 16z"
  ></path>
  <path
    fill="#746250"
    d="M440 432a8 8 0 01-8-8v-80a8 8 0 0116 0v80a8 8 0 01-8 8z"
  ></path>
  <path
    fill="#aedee2"
    d="M456 432h-32a8 8 0 010-16h32a8 8 0 010 16zM200 432h-24a8 8 0 010-16h24a8 8 0 010 16zM144 432h-16a8 8 0 010-16h16a8 8 0 010 16zM112 424a8 8 0 01-8 8H24v-16h80a8 8 0 018 8zM80 400H56a8 8 0 010-16h24a8 8 0 010 16zM144 400h-32a8 8 0 010-16h32a8 8 0 010 16zM192 400h-16a8 8 0 010-16h16a8 8 0 010 16zM80 464H56a8 8 0 010-16h24a8 8 0 010 16zM200 464h-88a8 8 0 010-16h88a8 8 0 010 16zM256 464h-24a8 8 0 010-16h24a8 8 0 010 16zM328 464h-40a8 8 0 010-16h40a8 8 0 010 16zM400 464h-40a8 8 0 010-16h40a8 8 0 010 16zM488 464h-56a8 8 0 010-16h56z"
  ></path>
  <path
    fill="#8f563b"
    d="M168 336v24h-16v-24a8 8 0 0116 0zM104 336v24H88v-24a8 8 0 0116 0z"
  ></path>
  <path
    fill="#a8d6da"
    d="M128 80a16 16 0 01-16 16H40a16 16 0 110-32h1.01a32.008 32.008 0 0161.98 0H112a15.964 15.964 0 0116 16z"
  ></path>
  <path
    fill="#c0f5f9"
    d="M127.5 76A16.012 16.012 0 01112 88H40a15.987 15.987 0 01-15.5-12A16.012 16.012 0 0140 64h1.01a32.008 32.008 0 0161.98 0H112a15.987 15.987 0 0115.5 12z"
  ></path>
  <path
    fill="#a8d6da"
    d="M488 168a16 16 0 01-16 16h-72a16 16 0 110-32 24 24 0 0142.45-15.35A24.006 24.006 0 01470.63 152H472a15.964 15.964 0 0116 16z"
  ></path>
  <path
    fill="#c0f5f9"
    d="M487.5 164a16.012 16.012 0 01-15.5 12h-72a15.987 15.987 0 01-15.5-12 16.012 16.012 0 0115.5-12 24 24 0 0142.45-15.35A24.006 24.006 0 01470.63 152H472a15.987 15.987 0 0115.5 12z"
  ></path>
  <rect
    width="32"
    height="48"
    x="144"
    y="288"
    fill="#aac16b"
    rx="16"
    ry="16"
  ></rect>
  <rect
    width="32"
    height="48"
    x="392"
    y="288"
    fill="#aac16b"
    rx="16"
    ry="16"
  ></rect>
  <rect
    width="32"
    height="48"
    x="80"
    y="288"
    fill="#aac16b"
    rx="16"
    ry="16"
  ></rect>
</g>
</svg>
  );
};

IconLake.defaultProps = { className: null };

IconLake.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconLake;


