import { getUserGiftCards, registerGiftCard } from '../util/api';
import { storableError } from '../util/errors';
import { loadData } from '../containers/GiftCardTrackingPage/GiftCardsTrackingPage.duck';

export const FETCH_CURRENT_USER_GIFT_CARDS_REQUEST =
  'app/giftCard/FETCH_CURRENT_USER_ACTIVE_GIFT_CARDS_REQUEST';
export const FETCH_CURRENT_USER_GIFT_CARDS_SUCCESS =
  'app/giftCard/FETCH_CURRENT_USER_GIFT_CARDS_SUCCESS';
export const FETCH_CURRENT_USER_GIFT_CARDS_ERROR =
  'app/giftCard/FETCH_CURRENT_USER_GIFT_CARDS_ERROR';

export const REGISTER_GIFT_CARD_REQUEST = 'app/giftCard/REGISTER_GIFT_CARD_REQUEST';
export const REGISTER_GIFT_CARD_SUCCESS = 'app/giftCard/REGISTER_GIFT_CARD_SUCCESS';
export const REGISTER_GIFT_CARD_ERROR = 'app/giftCard/REGISTER_GIFT_CARD_ERROR';

export const CLEAR_REGISTER_GIFT_CARD_REQUEST = 'app/giftCard/CLEAR_REGISTER_GIFT_CARD_REQUEST';

const initialState = {
  getUserGiftCardsInProgress: false,
  getUserGiftCardsError: null,
  giftCards: [],
  registerGiftCardError: null,
  registerGiftCardSuccess: false,
  registerGiftCardInProgress: false,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;

  switch (type) {
    case FETCH_CURRENT_USER_GIFT_CARDS_REQUEST:
      return {
        ...state,
        getUserGiftCardsInProgress: true,
        getUserGiftCardsError: null,
      };

    case FETCH_CURRENT_USER_GIFT_CARDS_SUCCESS:
      return {
        ...state,
        getUserGiftCardsInProgress: false,
        giftCards: payload,
      };

    case FETCH_CURRENT_USER_GIFT_CARDS_ERROR:
      return {
        ...state,
        getUserGiftCardsInProgress: false,
        getUserGiftCardsError: payload,
      };

    case REGISTER_GIFT_CARD_REQUEST:
      return {
        ...state,
        registerGiftCardError: null,
        registerGiftCardSuccess: false,
        registerGiftCardInProgress: true,
      };
    case REGISTER_GIFT_CARD_ERROR:
      return {
        ...state,
        registerGiftCardError: payload,
        registerGiftCardSuccess: false,
        registerGiftCardInProgress: false,
      };
    case REGISTER_GIFT_CARD_SUCCESS:
      return {
        ...state,
        registerGiftCardError: null,
        registerGiftCardSuccess: true,
        registerGiftCardInProgress: false,
      };

    case CLEAR_REGISTER_GIFT_CARD_REQUEST:
      return {
        ...state,
        registerGiftCardError: null,
        registerGiftCardSuccess: false,
        registerGiftCardInProgress: false,
      };

    default:
      return state;
  }
}

export const fetchCurrentUserGiftCardsRequest = () => ({
  type: FETCH_CURRENT_USER_GIFT_CARDS_REQUEST,
});

export const fetchCurrentUserGiftCardsSuccess = payload => ({
  type: FETCH_CURRENT_USER_GIFT_CARDS_SUCCESS,
  payload,
});

export const fetchCurrentUserGiftCardsError = e => ({
  type: FETCH_CURRENT_USER_GIFT_CARDS_ERROR,
  payload: e,
});

export const registerGiftCardRequest = () => ({
  type: REGISTER_GIFT_CARD_REQUEST,
});

export const registerGiftCardError = error => ({
  type: REGISTER_GIFT_CARD_ERROR,
  payload: error,
});

export const registerGiftCardSuccess = () => ({
  type: REGISTER_GIFT_CARD_SUCCESS,
  payload: {},
});

export const clearRegisterGiftCardRequest = () => ({
  type: CLEAR_REGISTER_GIFT_CARD_REQUEST,
});

export const getCurrentUserGiftCards = () => dispatch => {
  dispatch(fetchCurrentUserGiftCardsRequest());

  return getUserGiftCards({ status: 'Active' })
    .then(response => dispatch(fetchCurrentUserGiftCardsSuccess(response.data)))
    .catch(error => dispatch(fetchCurrentUserGiftCardsError(error)));
};

export const registerCurrentUserGiftCard = card => dispatch => {
  dispatch(registerGiftCardRequest());

  return registerGiftCard(card)
    .then(() => {
      dispatch(getCurrentUserGiftCards());
      dispatch(registerGiftCardSuccess());
      dispatch(loadData());
    })
    .catch(error => {
      dispatch(registerGiftCardError(storableError(error)));
      return error;
    });
};

export const clearRegisterGiftCardState = () => dispatch => {
  return dispatch(clearRegisterGiftCardRequest());
};
