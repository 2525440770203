import { fetchPageAssets } from '../../ducks/hostedAssets.duck';
import {
  getHotPropertyListings,
  getHighRatedRandomListing,
  getTopRatedListings,
} from '../../util/api';
import { convertListingsDataToCardModel, denormalisedResponseEntities } from '../../util/data';
import { storableError } from '../../util/errors';
export const ASSET_NAME = 'landing-page';

// ================ Action types ================ //

export const FETCH_TOP_REVIEWED_REQUEST = 'app/LandingPage/FETCH_TOP_REVIEWED_REQUEST';
export const FETCH_TOP_REVIEWED_SUCCESS = 'app/LandingPage/FETCH_TOP_REVIEWED_SUCCESS';
export const FETCH_TOP_REVIEWED_ERROR = 'app/LandingPage/FETCH_TOP_REVIEWED_ERROR';

export const FETCH_HOT_PROPERTIES_REQUEST = 'app/LandingPage/FETCH_HOT_PROPERTIES_REQUEST';
export const FETCH_HOT_PROPERTIES_SUCCESS = 'app/LandingPage/FETCH_HOT_PROPERTIES_SUCCESS';
export const FETCH_HOT_PROPERTIES_ERROR = 'app/LandingPage/FETCH_HOT_PROPERTIES_ERROR';

export const FETCH_RANDOM_CAMPING_AVAILABLE_LISTINGS_REQUEST =
  'app/LandingPage/FETCH_RANDOM_CAMPING_AVAILABLE_LISTINGS_REQUEST';
export const FETCH_RANDOM_CAMPING_AVAILABLE_LISTINGS_SUCCESS =
  'app/LandingPage/FETCH_RANDOM_CAMPING_AVAILABLE_LISTINGS_SUCCESS';
export const FETCH_RANDOM_CAMPING_AVAILABLE_LISTINGS_ERROR =
  'app/LandingPage/FETCH_RANDOM_CAMPING_AVAILABLE_LISTINGS_ERROR';

// ================ Reducer ================ //

const initialState = {
  topReviewedListings: [],
  fetchTopReviewedInProgress: false,
  fetchTopReviewedError: null,
  hotPropertiesListings: [],
  fetchHotPropertiesInProgress: false,
  fetchHotPropertiesError: null,
  randomCampingAvailableListings: [],
  fetchRandomCampingAvailableListingsInProgress: false,
  fetchRandomCampingAvailableListingsError: null,
};

const landingPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_TOP_REVIEWED_REQUEST:
      return { ...state, fetchTopReviewedInProgress: true, fetchTopReviewedError: null };
    case FETCH_TOP_REVIEWED_SUCCESS: {
      const convertedTopRatedListings = convertListingsDataToCardModel(payload);
      return {
        ...state,
        fetchTopReviewedInProgress: false,
        topReviewedListings: convertedTopRatedListings,
      };
    }
    case FETCH_TOP_REVIEWED_ERROR:
      return { ...state, fetchTopReviewedInProgress: false, fetchTopReviewedError: payload };

    case FETCH_HOT_PROPERTIES_REQUEST:
      return { ...state, fetchHotPropertiesInProgress: true, fetchHotPropertiesError: null };
    case FETCH_HOT_PROPERTIES_SUCCESS: {
      const convertedHotProperties = convertListingsDataToCardModel(payload);
      return {
        ...state,
        fetchHotPropertiesInProgress: false,
        hotPropertiesListings: convertedHotProperties,
      };
    }
    case FETCH_HOT_PROPERTIES_ERROR:
      return { ...state, fetchHotPropertiesInProgress: false, fetchHotPropertiesError: payload };

    case FETCH_RANDOM_CAMPING_AVAILABLE_LISTINGS_REQUEST:
      return {
        ...state,
        fetchRandomCampingAvailableListingsInProgress: true,
        fetchRandomCampingAvailableListingsError: null,
      };
    case FETCH_RANDOM_CAMPING_AVAILABLE_LISTINGS_SUCCESS: {
      const convertedRandomCampingAvilableListings = convertListingsDataToCardModel(payload);

      return {
        ...state,
        fetchRandomCampingAvailableListingsInProgress: false,
        randomCampingAvailableListings: convertedRandomCampingAvilableListings,
      };
    }
    case FETCH_RANDOM_CAMPING_AVAILABLE_LISTINGS_ERROR:
      return {
        ...state,
        fetchRandomCampingAvailableListingsInProgress: false,
        fetchRandomCampingAvailableListingsError: payload,
      };

    default:
      return state;
  }
};

export default landingPageReducer;

// ================ Action creators ================ //

export const fetchTopReviewedRequest = () => ({ type: FETCH_TOP_REVIEWED_REQUEST });
export const fetchTopReviewedSuccess = listings => ({
  type: FETCH_TOP_REVIEWED_SUCCESS,
  payload: listings,
});
export const fetchTopReviewedError = error => ({
  type: FETCH_TOP_REVIEWED_ERROR,
  error: true,
  payload: error,
});

export const fetchHotPropertiesRequest = () => ({ type: FETCH_HOT_PROPERTIES_REQUEST });
export const fetchHotPropertiesSuccess = listings => ({
  type: FETCH_HOT_PROPERTIES_SUCCESS,
  payload: listings,
});
export const fetchHotPropertiesError = error => ({
  type: FETCH_HOT_PROPERTIES_ERROR,
  error: true,
  payload: error,
});

export const fetchRandomCampingAvailableListingsRequest = () => ({
  type: FETCH_RANDOM_CAMPING_AVAILABLE_LISTINGS_REQUEST,
});
export const fetchRandomCampingAvailableListingsSuccess = listings => ({
  type: FETCH_RANDOM_CAMPING_AVAILABLE_LISTINGS_SUCCESS,
  payload: listings,
});
export const fetchRandomCampingAvailableListingsError = error => ({
  type: FETCH_RANDOM_CAMPING_AVAILABLE_LISTINGS_ERROR,
  error: true,
  payload: error,
});

// ================ Thunks ================ //

export const fetchTopReviewed = () => (dispatch, getState, sdk) => {
  dispatch(fetchTopReviewedRequest());
  return getTopRatedListings({})
    .then(async response => {
      const entities = denormalisedResponseEntities(response);
      const allIncluded = response.data.included;
      response.data.data.forEach(currentItem =>
        currentItem.relationships?.images?.data.forEach(image => {
          image.included = allIncluded.find(item => item.id.uuid === image.id.uuid);
        })
      );
      dispatch(fetchTopReviewedSuccess(response.data.data || []));
    })
    .catch(e => {
      dispatch(fetchTopReviewedError(storableError(e)));
    });
};

export const fetchHotProperties = () => (dispatch, getState, sdk) => {
  dispatch(fetchHotPropertiesRequest());
  // TODO: prod-update
  // return getHotPropertyListings({})
  return getTopRatedListings()
    .then(async response => {
      const entities = denormalisedResponseEntities(response);
      const allIncluded = response.data.included;
      response.data.data.forEach(item =>
        item.relationships?.images?.data.forEach(image => {
          image.included = allIncluded.find(includedItem => includedItem.id.uuid === image.id.uuid);
        })
      );
      dispatch(fetchHotPropertiesSuccess(response.data.data || []));
    })
    .catch(e => {
      dispatch(fetchHotPropertiesError(storableError(e)));
    });
};

export const fetchRandomCampingAvailableListings = () => (dispatch, getState, sdk) => {
  dispatch(fetchRandomCampingAvailableListingsRequest());
  return getHighRatedRandomListing({})
    .then(async response => {
      const entities = denormalisedResponseEntities(response);
      const allIncluded = response.data.included;
      response.data.data.forEach(currentData =>
        currentData.relationships?.images?.data.forEach(image => {
          image.included = allIncluded.find(includedItem => includedItem.id.uuid === image.id.uuid);
        })
      );
      dispatch(fetchRandomCampingAvailableListingsSuccess(response.data.data || []));
    })
    .catch(e => {
      dispatch(fetchRandomCampingAvailableListingsError(storableError(e)));
    });
};

export const loadData = (params, search) => dispatch => {
  const pageAsset = { landingPage: `content/pages/${ASSET_NAME}.json` };

  return Promise.all([
    dispatch(fetchPageAssets(pageAsset, true)),
    dispatch(fetchTopReviewed()),
    dispatch(fetchHotProperties()),
    dispatch(fetchRandomCampingAvailableListings()),
  ]);
};
