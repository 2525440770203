export const routingLookUps = [
  {
    RecordID: '19721632826',
    CompanyName: 'Chalk Creek',
    WebsiteURL: '/listing/chalk-creek-retreat',
    CreateDate: '2024-03-27 12:33',
    LiveId: '66146537-a630-4405-ad01-1d1b78dd0b6a',
  },
  {
    RecordID: '18538030710',
    CompanyName: 'Upper Williams Fork Canyon',
    WebsiteURL: '/listing/upper-williams-fork-canyon',
    CreateDate: '2023-12-27 10:03',
    LiveId: '6602d3d4-5b9a-4aa5-a0e6-2efb5cbb354e',
  },
  {
    RecordID: '18502382876',
    CompanyName: 'Bar Star Lake',
    WebsiteURL: '/listing/bar-star-lake',
    CreateDate: '2023-12-22 10:28',
    LiveId: '6602d3d4-f2c8-4ee0-9e33-8f77e8b3a984',
  },
  {
    RecordID: '18383469119',
    CompanyName: 'Long Meadow on Big Hole/Pendergast',
    WebsiteURL: '/listing/long-meadow-on-the-big-hole-river',
    CreateDate: '2023-12-11 11:03',
    LiveId: '6602d3d4-e87b-47ae-a6bb-d0e3e0ed8a4a',
  },
  {
    RecordID: '18495183629',
    CompanyName: 'Big Hole Haven',
    WebsiteURL: '/listing/big-hole-haven',
    CreateDate: '2023-12-11 10:46',
    LiveId: '6602d3d4-4d4b-4325-b0c4-3e448702ec73',
  },
  {
    RecordID: '18112408910',
    CompanyName: 'Peaceful Pinedale Ranch',
    WebsiteURL: '/listing/peaceful-pinedale-ranch',
    CreateDate: '2023-11-16 16:39',
    LiveId: '6602d3d4-567b-49f6-a4af-80a7fad059a1',
  },
  {
    RecordID: '18112085297',
    CompanyName: 'Clear Fork Muddy Creek',
    WebsiteURL: '/listing/clear-fork-muddy-creek',
    CreateDate: '2023-11-16 16:38',
    LiveId: '6602d3d4-d8c4-42f5-896e-05a6e681d8bc',
  },
  {
    RecordID: '17838879399',
    CompanyName: 'Beautiful Blue River',
    WebsiteURL: '/listing/beautiful-blue-river',
    CreateDate: '2023-10-27 10:52',
    LiveId: '6602d3d4-ddf0-4574-a784-5567a3a8a8b4',
  },
  {
    RecordID: '17801589110',
    CompanyName: 'North Fork Encampment River  Ranch',
    WebsiteURL: '/listing/north-fork-encampment-river-ranch',
    CreateDate: '2023-10-25 09:57',
    LiveId: '6602d3d4-75ed-4a41-bd27-5d4bfdefa26d',
  },
  {
    RecordID: '17254331785',
    CompanyName: 'Serene South Platte River',
    WebsiteURL: '/listing/serene-south-platte-river',
    CreateDate: '2023-09-07 14:21',
    LiveId: '6602d3d4-9128-46fc-a2af-ff53610fa8e9',
  },
  {
    RecordID: '17049913936',
    CompanyName: 'Bear River Scenic Getaway',
    WebsiteURL: '/listing/bear-river',
    CreateDate: '2023-08-21 12:13',
    LiveId: '6602d3d4-ebcd-4767-bb9e-5b309379e8da',
  },
  {
    RecordID: '17049796997',
    CompanyName: 'Secluded Shoshone River Canyon',
    WebsiteURL: '/listing/secluded-shoshone-river-canyon',
    CreateDate: '2023-08-21 12:08',
    LiveId: '6602d3d4-dd68-4694-b15e-ce20be5e347a',
  },
  {
    RecordID: '16779016336',
    CompanyName: 'Musselshell River Historic Haven',
    WebsiteURL: '/listing/mussleshell-river-historic-haven',
    CreateDate: '2023-07-31 13:14',
    LiveId: '6602d3d4-4408-4cb8-91cd-5582c3df806a',
  },
  {
    RecordID: '16724285053',
    CompanyName: 'Studebaker Bend Colorado River',
    WebsiteURL: '/listing/studebaker-bend-colorado-river',
    CreateDate: '2023-07-26 16:31',
    LiveId: '6602d3d4-5c42-4f23-b4b7-71708e025d43',
  },
  {
    RecordID: '16703942956',
    CompanyName: 'Phillips Creek Chalk Cliffs',
    WebsiteURL: '/listing/phillips-creek-chalk-cliffs',
    CreateDate: '2023-07-25 13:57',
    LiveId: '6602d3d4-0f3f-4a4f-8c89-1733b41ef837',
  },
  {
    RecordID: '16686257042',
    CompanyName: 'Colorado River Grand Hogback',
    WebsiteURL: '/listing/colorado-river-grand-hogback',
    CreateDate: '2023-07-24 07:42',
    LiveId: '6602d3d4-a31a-44a2-bac0-32afe10a218b',
  },
  {
    RecordID: '16446343516',
    CompanyName: 'Whisky Run Ranch',
    WebsiteURL: '/listing/whisky-run-ranch',
    CreateDate: '2023-07-13 14:28',
    LiveId: '6602d3d4-f8b1-4cca-80e8-fb2e8d20aa11',
  },
  {
    RecordID: '16280817774',
    CompanyName: 'Dyer Creek Reserve',
    WebsiteURL: '/listing/dyer-creek-reserve',
    CreateDate: '2023-06-30 11:01',
    LiveId: '6602d3d4-b1d2-49b4-b152-987cb7cbe408',
  },
  {
    RecordID: '16271871021',
    CompanyName: 'Lemhi River Peaceful Valley',
    WebsiteURL: '/listing/lemhi-river-peaceful-valley',
    CreateDate: '2023-06-29 11:42',
    LiveId: '6602d3d4-abf4-4c10-ad00-ee17e16ff7eb',
  },
  {
    RecordID: '16199440218',
    CompanyName: 'Crystal River Redstone Retreat',
    WebsiteURL: '/listing/crystal-river-redstone-preserve',
    CreateDate: '2023-06-22 16:24',
    LiveId: '6602d3d4-a203-43e8-8cd1-ebef00a87749',
  },
  {
    RecordID: '16181601697',
    CompanyName: 'Hot Springs Ranch',
    WebsiteURL: '/listing/hot-springs-ranch',
    CreateDate: '2023-06-21 13:15',
    LiveId: '6602d3d4-2570-46af-bc04-19da2c4e9ef9',
  },
  {
    RecordID: '16181601630',
    CompanyName: 'Teton River Escape',
    WebsiteURL: '/listing/teton-river-escape',
    CreateDate: '2023-06-21 13:14',
    LiveId: '6602d3d4-934e-4df6-a6b9-8e15d50dc17b',
  },
  {
    RecordID: '15901728340',
    CompanyName: 'Pioneer Picnic Grounds at Crab Creek',
    WebsiteURL: '/listing/pioneer-picnic-grounds-at-crab-creek',
    CreateDate: '2023-05-30 11:29',
    LiveId: '6602d3d4-04c8-4b28-9b7e-25cbe8a47121',
  },
  {
    RecordID: '15901728269',
    CompanyName: 'House on the Metolius',
    WebsiteURL: '/listing/house-on-the-metolius',
    CreateDate: '2023-05-30 11:28',
    LiveId: '6602d3d4-8a37-41db-bb29-a0027c149a1c',
  },
  {
    RecordID: '15892603040',
    CompanyName: 'Williamson River Meandering Ranch',
    WebsiteURL: '/listing/williamson-river-meandering-ranch',
    CreateDate: '2023-05-29 14:44',
    LiveId: '6602d3d4-a2ec-494c-9524-3d38a552ccd4',
  },
  {
    RecordID: '15815597961',
    CompanyName: 'Crooked River Basin - Beat 1',
    WebsiteURL: '/listing/crooked-river-basin',
    CreateDate: '2023-05-21 21:06',
    LiveId: '6602d3d4-f185-46ef-9e90-350f3a6157ff',
  },
  {
    RecordID: '15801615739',
    CompanyName: 'Clark Fork Meadows',
    WebsiteURL: '/listing/clark-fork-meadows',
    CreateDate: '2023-05-19 11:19',
    LiveId: '6602d3d4-c8bf-45db-824b-c5d27b1af161',
  },
  {
    RecordID: '15654992736',
    CompanyName: 'Crooked River Trout Camp - Beat 2',
    WebsiteURL: '/listing/crooked-river-trout-camp',
    CreateDate: '2023-05-04 09:20',
    LiveId: '6602d3d4-321d-4bca-98c1-539ef16a4589',
  },
  {
    RecordID: '15598667786',
    CompanyName: 'Clark Fork Peaceful Valley',
    WebsiteURL: '/listing/clark-fork-peaceful-valley',
    CreateDate: '2023-04-27 17:01',
    LiveId: '6602d3d4-3c9c-45ac-96b9-5b30942d1f55',
  },
  {
    RecordID: '15517107033',
    CompanyName: 'The Encampment Escape',
    WebsiteURL: '/listing/the-encampment-escape',
    CreateDate: '2023-04-20 10:53',
    LiveId: '6602d3d4-cfa5-4f17-a1e8-048374991e7e',
  },
  {
    RecordID: '15469320801',
    CompanyName: 'Old Maid Gulch Homestead',
    WebsiteURL: '/listing/old-maid-gulch',
    CreateDate: '2023-04-14 13:34',
    LiveId: '6602d3d4-7cd5-44e5-b146-3a79681252c2',
  },
  {
    RecordID: '15468699665',
    CompanyName: 'North Boulder Creek Canyon ',
    WebsiteURL: '/listing/north-boulder-creek-canyon',
    CreateDate: '2023-04-14 11:52',
    LiveId: '6602d3d4-7f4e-4a6c-bd60-1fab3bee35b5',
  },
  {
    RecordID: '15438034234',
    CompanyName: 'Clark Fork Headwaters',
    WebsiteURL: '/listing/clark-fork-river-headwaters',
    CreateDate: '2023-04-11 13:23',
    LiveId: '6602d3d4-0e21-498b-b613-707f63e9b313',
  },
  {
    RecordID: '15404240752',
    CompanyName: 'North Platte River Bend Ranch',
    WebsiteURL: '/listing/north-platte-river-bend-ranch',
    CreateDate: '2023-04-06 11:48',
    LiveId: '6602d3d4-c7cb-41ab-b3f2-5c7a9ca5489a',
  },
  {
    RecordID: '15403322285',
    CompanyName: 'Coffin Gulch Homestead',
    WebsiteURL: '/listing/coffin-gulch-homestead',
    CreateDate: '2023-04-06 09:42',
    LiveId: '6602d3d4-12cd-421a-901b-29e6ce01d907',
  },
  {
    RecordID: '15370449955',
    CompanyName: 'North Platte Meandering Ranch',
    WebsiteURL: '/listing/north-platte-meandering-ranch',
    CreateDate: '2023-04-03 10:49',
    LiveId: '6602d3d4-0656-4562-ae92-5701616162d1',
  },
  {
    RecordID: '15281553748',
    CompanyName: 'Roaring Fork Scenic Ranch',
    WebsiteURL: '/listing/roaring-fork-scenic-ranch',
    CreateDate: '2023-03-24 09:35',
    LiveId: '6602d3d4-5249-44a7-943a-fcd7358f0c06',
  },
  {
    RecordID: '14941090819',
    CompanyName: 'Slow Eddy Montana Ranch',
    WebsiteURL: '/listing/slow-eddy-montana-ranch',
    CreateDate: '2023-03-03 13:23',
    LiveId: '6602d3d4-801d-4359-b8b2-c9fdcc3c85e1',
  },
  {
    RecordID: '14941282141',
    CompanyName: 'Clark Fork Overlook Ranch',
    WebsiteURL: '/listing/clark-fork-overlook-ranch',
    CreateDate: '2023-03-03 13:09',
    LiveId: '6602d3d4-39b4-4d88-8faa-e0a170b379a3',
  },
  {
    RecordID: '14938566546',
    CompanyName: 'Snake River and Battle Creek Remote Escape - Beat 1',
    WebsiteURL: '/listing/snake-river-and-battle-creek-remote-escape',
    CreateDate: '2023-03-03 10:03',
    LiveId: '6602d3d4-5c24-4409-af27-a9108cce84a2',
  },
  {
    RecordID: '14940102472',
    CompanyName: 'Conejos River',
    WebsiteURL: '/listing/conejos-river',
    CreateDate: '2023-03-03 09:52',
    LiveId: '6602d3d4-a814-4a51-9ac9-379ab23bdfea',
  },
  {
    RecordID: '14938411177',
    CompanyName: 'Rio Grand Ranch',
    WebsiteURL: '/listing/rio-grande-ranch',
    CreateDate: '2023-03-03 09:25',
    LiveId: '6602d3d4-6d93-469d-b94c-94c7890d023b',
  },
  {
    RecordID: '14939065360',
    CompanyName: 'Spring Creeks Ranch',
    WebsiteURL: '/listing/spring-creeks-grassy-meadow',
    CreateDate: '2023-03-03 09:04',
    LiveId: '6602d3d4-73cd-4f12-b018-3f75a4655ee4',
  },
  {
    RecordID: '14938033593',
    CompanyName: 'Diamond Arrow',
    WebsiteURL: '/listing/diamond-arrow',
    CreateDate: '2023-03-03 07:34',
    LiveId: '6602d3d4-d99e-4d53-91f1-4c7224d9e6f1',
  },
  {
    RecordID: '14911783999',
    CompanyName: 'Grand Gunnison River Ranch',
    WebsiteURL: '/listing/grand-gunnison-river-ranch',
    CreateDate: '2023-03-01 09:12',
    LiveId: '6602d3d4-9c7d-4d4f-a52b-fc7a94338102',
  },
  {
    RecordID: '14908103865',
    CompanyName: 'Cochetopa Creek and Cabins',
    WebsiteURL: '/listing/cochetopa-creek-and-cabins',
    CreateDate: '2023-02-28 12:03',
    LiveId: '6602d3d4-7a1b-4ba4-aef3-0d7ab67d2ccb',
  },
  {
    RecordID: '14906746129',
    CompanyName: 'Banana Belt Ranch',
    WebsiteURL: '/listing/banana-belt-ranch-on-arkansas-river',
    CreateDate: '2023-02-28 11:50',
    LiveId: '6602d3d4-4c39-46ae-b48d-46269e71ce1d',
  },
  {
    RecordID: '14905643691',
    CompanyName: 'Arkansas River Canyon',
    WebsiteURL: '/listing/arkansas-river-canyon',
    CreateDate: '2023-02-28 09:31',
    LiveId: '6602d3d4-35b6-4e04-922f-2dc4c7c7e3ec',
  },
  {
    RecordID: '14904729181',
    CompanyName: 'Arkansas Headwaters Getaway',
    WebsiteURL: '/listing/arkansas-headwaters-campout',
    CreateDate: '2023-02-28 08:50',
    LiveId: '6602d3d4-9af5-4b89-8c1d-5c1965434370',
  },
  {
    RecordID: '14904582052',
    CompanyName: 'Scenic Salida Ranch',
    WebsiteURL: '/listing/scenic-salida-ranch',
    CreateDate: '2023-02-28 08:42',
    LiveId: '6602d3d4-b4d6-49ff-9b9b-cb1fb7ec18ef',
  },
  {
    RecordID: '14879318035',
    CompanyName: 'South Platte Hartsel',
    WebsiteURL: '/listing/south-platte-hartsel',
    CreateDate: '2023-02-27 09:24',
    LiveId: '6602d3d4-6558-47d6-93f7-d02c96a41993',
  },
  {
    RecordID: '14878420226',
    CompanyName: 'Colorado River Bend',
    WebsiteURL: '/listing/colorado-river-bend',
    CreateDate: '2023-02-27 07:58',
    LiveId: '6602d3d4-1a08-4e1c-a0ad-38f96ab2eeb8',
  },
  {
    RecordID: '14878319460',
    CompanyName: 'Colorado Cliffs',
    WebsiteURL: '/listing/colorado-cliffs',
    CreateDate: '2023-02-27 07:47',
    LiveId: '6602d3d4-c36a-4861-a034-565b9a5504ea',
  },
  {
    RecordID: '14834318392',
    CompanyName: 'Michigan River Getaway',
    WebsiteURL: '/listing/michigan-river-getaway',
    CreateDate: '2023-02-25 13:19',
    LiveId: '6602d3d4-bed2-44e6-8582-8b9420d8ab18',
  },
  {
    RecordID: '14834099716',
    CompanyName: 'Montana Meadows Ranch',
    WebsiteURL: '/listing/montana-meadows-ranch',
    CreateDate: '2023-02-25 12:46',
    LiveId: '6602d3d4-03c7-473c-b7ce-f8c695d40296',
  },
  {
    RecordID: '14814933031',
    CompanyName: 'Arkansas River Great Escape',
    WebsiteURL: '/listing/arkansas-river-great-escape',
    CreateDate: '2023-02-24 21:13',
    LiveId: '6602d3d4-a0d7-40c5-a4b4-e8af2b9d52f6',
  },
  {
    RecordID: '14813682719',
    CompanyName: 'Feather River Retreat',
    WebsiteURL: '/listing/feather-river-retreat',
    CreateDate: '2023-02-24 13:57',
    LiveId: '6602d3d4-4739-4e43-82ba-600ba5061d8e',
  },
  {
    RecordID: '14791006584',
    CompanyName: 'Fall River Meadows Ranch',
    WebsiteURL: '/listing/fall-river-meadows-ranch',
    CreateDate: '2023-02-23 09:19',
    LiveId: '6602d3d4-1af6-475e-aeaf-58d095e77ade',
  },
];
