import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './IconLocation.module.css';

const IconLocation = props => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={18} height={24} fill="none" {...props}>
      <mask
        id="a"
        width={17}
        height={22}
        x={0}
        y={0}
        maskUnits="userSpaceOnUse"
        style={{
          maskType: 'luminance',
        }}
      >
        <path fill="#fff" fillRule="evenodd" d="M0 0h16.649v22H0V0Z" clipRule="evenodd" />
      </mask>
      <g mask="url(#a)">
        <path
          fill="#0094FF"
          fillRule="evenodd"
          d="M8.324 11.688c1.915 0 3.468-1.54 3.468-3.438 0-1.898-1.553-3.438-3.468-3.438-1.916 0-3.469 1.54-3.469 3.438 0 1.899 1.553 3.438 3.469 3.438Zm-.855 9.868C1.169 12.506 0 11.576 0 8.25 0 3.694 3.727 0 8.324 0c4.598 0 8.325 3.694 8.325 8.25 0 3.326-1.17 4.255-7.47 13.306a1.046 1.046 0 0 1-1.71 0Z"
          clipRule="evenodd"
        />
      </g>
    </svg>
  );
};

IconLocation.defaultProps = { className: null };

IconLocation.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconLocation;
