import { denormalisedResponseEntities } from '../../util/data';
import { storableError } from '../../util/errors';
import { currentUserShowSuccess, fetchCurrentUserOwnListings } from '../../ducks/user.duck';

import { types as sdkTypes } from '../../util/sdkLoader';

const { UUID } = sdkTypes;

// ================ Action types ================ //

export const UPDATE_VARIABLE_PRICING_REQUEST =
  'app/VariablePricingPage/UPDATE_VARIABLE_PRICING_REQUEST';
export const UPDATE_VARIABLE_PRICING_SUCCESS =
  'app/VariablePricingPage/UPDATE_VARIABLE_PRICING_SUCCESS';
export const UPDATE_VARIABLE_PRICING_ERROR =
  'app/VariablePricingPage/UPDATE_VARIABLE_PRICING_ERROR';

export const FETCH_SELECTED_LISTING_VARIABLE_PRICING =
  'app/VariablePricingPage/FETCH_SELECTED_LISTING_VARIABLE_PRICING';

// ================ Reducer ================ //

const initialState = {
  selectedListingId: '-1',
  variablePricing: null,
  updateInProgress: false,
  updateVariablePricingError: null,
  selectedListingVariablePricing: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;

  switch (type) {
    case UPDATE_VARIABLE_PRICING_REQUEST:
      return {
        ...state,
        updateInProgress: true,
        updateVariablePricingError: null,
      };
    case UPDATE_VARIABLE_PRICING_SUCCESS: {
      const { id, attributes } = payload.data;

      return {
        ...state,
        updateInProgress: false,
        variablePricing: attributes.privateData?.variablePricing,
      };
    }
    case UPDATE_VARIABLE_PRICING_ERROR: {
      return {
        ...state,
        variablePricing: null,
        updateInProgress: false,
        updateVariablePricingError: payload,
      };
    }

    case FETCH_SELECTED_LISTING_VARIABLE_PRICING: {
      const { selectedListingVariablePricing, userVariablePricing, selectedListingId } = payload;

      return {
        ...state,
        selectedListingVariablePricing: selectedListingVariablePricing ?? userVariablePricing,
        selectedListingId,
      };
    }
    default:
      return state;
  }
}

// ================ Action creators ================ //

export const updateVariablePricingRequest = params => ({
  type: UPDATE_VARIABLE_PRICING_REQUEST,
  payload: { params },
});

export const updateVariablePricingSuccess = result => ({
  type: UPDATE_VARIABLE_PRICING_SUCCESS,
  payload: result.data,
});

export const updateVariablePricingError = error => ({
  type: UPDATE_VARIABLE_PRICING_ERROR,
  payload: error,
  error: true,
});

export const fetchSelectedListingVariablePricing = payload => ({
  type: FETCH_SELECTED_LISTING_VARIABLE_PRICING,
  payload,
});

export const updateVariablePricing = actionPayload => {
  return (dispatch, getState, sdk) => {
    dispatch(updateVariablePricingRequest(actionPayload));
    const bodyParams = {
      privateData: {
        variablePricing: { ...actionPayload.variablePricing, lastUpdatedAt: new Date() },
      },
    };

    const queryParams = {
      expand: true,
      include: ['profileImage'],
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    };

    if (actionPayload.selectedListingId !== '-1')
      return sdk.ownListings
        .update(
          {
            id: new UUID(actionPayload.selectedListingId),
            ...JSON.parse(JSON.stringify(bodyParams)),
          },
          { expand: true }
        )
        .then(response => {
          dispatch(updateVariablePricingSuccess(response));
          dispatch(fetchCurrentUserOwnListings(''));
          return response;
        })
        .catch(e => {
          dispatch(updateVariablePricingError(storableError(e)));
        });

    return sdk.currentUser
      .updateProfile(JSON.parse(JSON.stringify(bodyParams)), queryParams)
      .then(response => {
        dispatch(updateVariablePricingSuccess(response));

        const entities = denormalisedResponseEntities(response);

        if (entities.length !== 1) {
          throw new Error('Expected a resource in the sdk.currentUser.updateProfile response');
        }

        const currentUser = entities[0];

        dispatch(currentUserShowSuccess(currentUser));
      })
      .catch(e => {
        dispatch(updateVariablePricingError(storableError(e)));
      });
  };
};

export const fetchVariablePricingValueForSelectedListing = actionPayload => (
  dispatch,
  getState,
  sdk
) => {
  const { selectedListingId } = actionPayload;
  const { ownListings, currentUser } = getState().user;

  const userVariablePricing = currentUser.attributes?.profile.privateData.variablePricing;
  const selectedListingVariablePricing =
    ownListings.find(x => x.id.uuid === selectedListingId)?.attributes.privateData
      .variablePricing ?? null;

  const payload = { selectedListingVariablePricing, userVariablePricing, selectedListingId };

  dispatch(fetchSelectedListingVariablePricing(payload));
};
