import React from 'react';
import { string } from 'prop-types';

const IconTrailer = props => {
  return (
    <svg
      width="22"
      height="22"
      xmlns="http://www.w3.org/2000/svg"
      data-name="filled outline"
      viewBox="0 0 512 512"
    >
      <path
        fill="#70a165"
        d="M40 335.3V392h400V231.391A31.391 31.391 0 00408.609 200H142.58a62.606 62.606 0 00-59.965 44.615L72 280l-6.958 5.219A62.6 62.6 0 0040 335.3z"
      ></path>
      <path
        fill="#3f5039"
        d="M424 204.035l-13 29.233A212.034 212.034 0 01296 344v48h144V231.391a31.374 31.374 0 00-16-27.356z"
      ></path>
      <path
        fill="#aad678"
        d="M56 351.3a62.6 62.6 0 0125.042-50.083L88 296l10.615-35.385A62.606 62.606 0 01158.58 216h266.029a31.253 31.253 0 0112.7 2.687 31.389 31.389 0 00-28.7-18.687H142.58a62.606 62.606 0 00-59.965 44.615L72 280l-6.958 5.219A62.6 62.6 0 0040 335.3V392h16z"
      ></path>
      <path fill="#393e4f" d="M400 392a32 32 0 01-64 0z"></path>
      <path fill="#b56b29" d="M480 240H496V448H480z"></path>
      <path fill="#c6ad81" d="M424 136L424 200 200 200 200 80 424 136z"></path>
      <path fill="#a08f6d" d="M424 128L424 160 200 104 200 64 424 128z"></path>
      <path fill="#393e4f" d="M192 200H296V392H192z"></path>
      <circle cx="368" cy="392" r="16" fill="#475784"></circle>
      <path fill="#475784" d="M392 360h48a16 16 0 0116 16 16 16 0 01-16 16h-48v-32z"></path>
      <path fill="#603714" d="M192 360H304V392H192z"></path>
      <path fill="#70a165" d="M296 200H408V392H296z"></path>
      <path fill="#57704c" d="M296 224H408V296H296z"></path>
      <path fill="#b0c0ea" d="M112 392H40a16 16 0 01-16-16 16 16 0 0116-16h72z"></path>
      <path
        fill="#475784"
        d="M26.158 368A15.907 15.907 0 0024 376a16 16 0 0016 16h72v-16H40a15.98 15.98 0 01-13.842-8z"
      ></path>
      <path
        fill="#e0c999"
        d="M174.453 85.6l254.064 63.516A15.68 15.68 0 00448 133.907a15.68 15.68 0 00-11.483-15.107l-252.33-70.093A19.08 19.08 0 00160 67.091 19.081 19.081 0 00174.453 85.6z"
      ></path>
      <path
        fill="#f4e0bd"
        d="M184.187 59.113l252.33 70.092a15.677 15.677 0 0110.6 9.918 15.7 15.7 0 00-10.6-20.324l-252.33-70.092A19.08 19.08 0 00160 67.091a19.041 19.041 0 00.727 5.177 19.09 19.09 0 0123.46-13.155z"
      ></path>
      <path fill="#a08f6d" d="M264 136h8a32 32 0 0132 32h-72a32 32 0 0132-32z"></path>
      <path fill="#b0c0ea" d="M160 288h-56l9.621-33.672a30.785 30.785 0 0129.6-22.328H160z"></path>
      <path fill="#b0c0ea" d="M328 232H376V280H328z"></path>
      <path fill="#475784" d="M320 312H344V328H320z"></path>
      <path fill="#475784" d="M144 312H168V328H144z"></path>
      <path
        fill="#475784"
        d="M168.026 168H200v32h-48v-15.974A16.026 16.026 0 01168.026 168z"
      ></path>
      <path fill="#b56b29" d="M256 240H272V448H256z"></path>
      <path fill="#f4e0bd" d="M256 240L496 240 424 200 200 200 256 240z"></path>
      <path fill="#e0c999" d="M324.819 240H496l-72-40H200a214.748 214.748 0 00124.819 40z"></path>
      <path fill="#57704c" d="M112 376H192V392H112z"></path>
      <path fill="#57704c" d="M296 376H408V392H296z"></path>
      <circle cx="112" cy="392" r="32" fill="#393e4f"></circle>
      <circle cx="112" cy="392" r="16" fill="#475784"></circle>
      <path d="M492.24 233.216L432 195.566v-37.981c.124 0 .249.012.373.012a23.686 23.686 0 006.285-46.506L186.328 41a27.08 27.08 0 10-13.816 52.363L192 98.234V160h-23.974A24.052 24.052 0 00144 184.025V192h-1.42a70.605 70.605 0 00-67.627 50.316l-9.859 32.864-4.852 3.638A70.945 70.945 0 0032 335.3v18.074A24 24 0 0040 400h32.805a40 40 0 0078.39 0H256v48h16v-48h56.805a40 40 0 0078.39 0H440a24 24 0 008-46.624V248h32v200h16V240h-.021a7.982 7.982 0 00-3.739-6.784zM176.393 77.84A11.085 11.085 0 01179.051 56a11.209 11.209 0 013 .411l252.33 70.092a7.68 7.68 0 01-3.918 14.85l-254.07-63.512zM416 154.234V192H208v-89.766zM336 248h32v24h-32zm-8 40h48a8 8 0 008-8v-32h16v136h-96V248h16v32a8 8 0 008 8zm-40 64h-16V248h16zm-32 0h-56V209.434l56 35zm-96-167.975a8.036 8.036 0 018.026-8.025H192v16h-32zM32 376a8.009 8.009 0 018-8h40.022a39.841 39.841 0 00-7.217 16H40a8.009 8.009 0 01-8-8zm80 40a24 24 0 1124-24 24.028 24.028 0 01-24 24zm0-64H48v-16.7a54.866 54.866 0 0121.842-43.684L76.8 286.4a8 8 0 002.863-4.1l10.615-35.385A54.269 54.269 0 01142.58 208H184v176h-32.8a40.068 40.068 0 00-39.2-32zm88 32v-16h56v16zm72-16h16v16h-16zm96 48a24.039 24.039 0 01-22.624-16h45.248A24.039 24.039 0 01368 416zm72-32h-24v-16h24a8 8 0 010 16zm-8-32h-16V248h16zM266.294 232l-38.4-24h193.81l38.4 24z"></path>
      <path d="M232 176h72a8 8 0 008-8 40.045 40.045 0 00-40-40h-8a40.045 40.045 0 00-40 40 8 8 0 008 8zm32-32h8a24.042 24.042 0 0122.629 16h-53.258A24.042 24.042 0 01264 144zM168 288v-56a8 8 0 00-8-8h-16.779a38.949 38.949 0 00-37.293 28.13l-9.62 33.67A8 8 0 00104 296h56a8 8 0 008-8zm-16-8h-37.394l6.707-23.475A22.882 22.882 0 01143.221 240H152z"></path>
      <path d="M320 312H344V328H320z"></path>
      <path d="M144 312H168V328H144z"></path>
    </svg>
  );
};

IconTrailer.defaultProps = { className: null };

IconTrailer.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconTrailer;
