

import React from 'react';
import { string } from 'prop-types';

const IconRainbowTrout = props => {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="22"
    x="0"
    y="0"
    viewBox="0 0 48 48"
  >
    <path
      fill="#00BCD4"
      d="M27 16c-9 0-16 7-16 7l-3 2.003 3 2.148C15.026 30.021 20.018 34 27 34c7 0 17-7 17-10s-10-8-17-8z"
    ></path>
    <path
      fill="#4DD0E1"
      d="M31.572 16.572A18.235 18.235 0 0030 24c0 3.217.922 6.321 2.4 8.935C37.547 31.156 44 26.833 44 24s-7-6.266-12.428-7.428z"
    ></path>
    <circle cx="35" cy="22" r="2" fill="#FFF"></circle>
    <circle cx="35" cy="22" r="1" fill="#0277BD"></circle>
    <g fill="#006064">
      <path d="M27 16c1.436 0 2.997.213 4.572.572-.092-.491-.767-3.805-2.572-5.572-2.438.125-11.451 3.45-14 6 .584.876.983 1.564 1.276 2.124C19.091 17.508 22.824 16 27 16zM4 17c1 1 2 5.674 2 8 0 2.313-1 7-2 8 3-1 6.2-4.268 6.969-5.871l.031.022-2-2.148L11 23s-2-4-7-6zM29.209 36.962c-4.121.157-4.86-.171-6.365-.464.944-1.821 6.453-6.938 7.46-6.418l-.089.471c1.006.521-.063 4.589-1.006 6.411z"></path>
    </g>
    <path
      fill="#0097A7"
      d="M30 24c0-2.625.563-5.18 1.572-7.428-.732-.189-1.965-.357-1.965-.357C28.552 18.7 28 21.313 28 24c0 3.306.814 6.61 2.39 9.523.646-.179 1.38-.332 2.011-.589C30.922 30.321 30 27.217 30 24z"
    ></path>
  </svg>
  );
};

IconRainbowTrout.defaultProps = { className: null };

IconRainbowTrout.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconRainbowTrout;


