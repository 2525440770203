import { getListingsThatCanHostManyAnglers } from '../../util/api';
import { storableError } from '../../util/errors';

// ================ Action types ================ //

export const FETCH_LISTINGS_THAT_CAN_HOST_MANY_ANGLERS_REQUEST = 'app/CorporateandLargeGroupsPage/FETCH_LISTINGS_THAT_CAN_HOST_MANY_ANGLERS_REQUEST';
export const FETCH_LISTINGS_THAT_CAN_HOST_MANY_ANGLERS_SUCCESS = 'app/CorporateandLargeGroupsPage/FETCH_LISTINGS_THAT_CAN_HOST_MANY_ANGLERS_SUCCESS';
export const FETCH_LISTINGS_THAT_CAN_HOST_MANY_ANGLERS_ERROR = 'app/CorporateandLargeGroupsPage/FETCH_LISTINGS_THAT_CAN_HOST_MANY_ANGLERS_ERROR';

// ================ Reducer ================ //

const initialState = {
  listingsThatCanHostManyAnglers: {
    listings: [],
    withReview: true
  },
  fetchListingsThatCanHostManyAnglersInProgress: false,
  fetchListingsThatCanHostManyAnglersError: null,
};

const CorporateandLargeGroupsPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_LISTINGS_THAT_CAN_HOST_MANY_ANGLERS_REQUEST:
      return { ...state, fetchListingsThatCanHostManyAnglersInProgress: true, fetchListingsThatCanHostManyAnglersError: null };
    case FETCH_LISTINGS_THAT_CAN_HOST_MANY_ANGLERS_SUCCESS:
      return { ...state, fetchListingsThatCanHostManyAnglersInProgress: false, listingsThatCanHostManyAnglers: payload };
    case FETCH_LISTINGS_THAT_CAN_HOST_MANY_ANGLERS_ERROR:
      return { ...state, fetchListingsThatCanHostManyAnglersInProgress: false, fetchListingsThatCanHostManyAnglersError: payload };

    default:
      return state;
  }
};

export default CorporateandLargeGroupsPageReducer;

// ================ Action creators ================ //

export const fetchListingsThatCanHostManyAnglersRequest = () => ({ type: FETCH_LISTINGS_THAT_CAN_HOST_MANY_ANGLERS_REQUEST });
export const fetchListingsThatCanHostManyAnglersSuccess = listings => ({ type: FETCH_LISTINGS_THAT_CAN_HOST_MANY_ANGLERS_SUCCESS, payload: listings });
export const fetchListingsThatCanHostManyAnglersError = error => ({
  type: FETCH_LISTINGS_THAT_CAN_HOST_MANY_ANGLERS_ERROR,
  error: true,
  payload: error,
});

// ================ Thunks ================ //

export const fetchListingsThatCanHostManyAnglers = () => (dispatch, getState, sdk) => {
  dispatch(fetchListingsThatCanHostManyAnglersRequest());
  return getListingsThatCanHostManyAnglers({})
    .then(response => {
      dispatch(fetchListingsThatCanHostManyAnglersSuccess(response.data));
    })
    .catch(e => {
      dispatch(fetchListingsThatCanHostManyAnglersError(storableError(e)));
    });
};



export const loadData = (params, search) => dispatch => {

  return Promise.all([
    dispatch(fetchListingsThatCanHostManyAnglers()),
  ])
};
