/**
 * Export loadData calls from ducks modules of different containers
 */
import { loadData as AuthenticationPageLoader } from './AuthenticationPage/AuthenticationPage.duck';
import { loadData as LandingPageLoader } from './LandingPage/LandingPage.duck';
import { setInitialValues as CheckoutPageInitialValues } from './CheckoutPage/CheckoutPage.duck';
import { setInitialValues as CheckoutPageV1InitialValues } from './CheckoutPageV1/CheckoutPage.duck';
import { loadData as CMSPageLoader } from './CMSPage/CMSPage.duck';
import { loadData as ContactDetailsPageLoader } from './ContactDetailsPage/ContactDetailsPage.duck';
import { loadData as EditListingPageLoader } from './EditListingPage/EditListingPage.duck';
import { loadData as EmailVerificationPageLoader } from './EmailVerificationPage/EmailVerificationPage.duck';
import { loadData as InboxPageLoader } from './InboxPage/InboxPage.duck';
import { loadData as ListingPageLoader } from './ListingPage/ListingPage.duck';
import { loadData as ListingPageLoaderV1 } from './ListingPageV1/ListingPage.duck';
import { loadData as ManageListingsPageLoader } from './ManageListingsPage/ManageListingsPage.duck';
import { loadData as ManageArticlesPageLoader } from './ManageArticlesPage/ManageArticlesPage.duck';
import { loadData as PaymentMethodsPageLoader } from './PaymentMethodsPage/PaymentMethodsPage.duck';
import { loadData as PrivacyPolicyPageLoader } from './PrivacyPolicyPage/PrivacyPolicyPage.duck';
import { loadData as ProfilePageLoader } from './ProfilePage/ProfilePage.duck';
import { loadData as SearchPageLoader } from './SearchPage/SearchPage.duck';
import { loadData as StripePayoutPageLoader } from './StripePayoutPage/StripePayoutPage.duck';
import { loadData as TermsOfServicePageLoader } from './TermsOfServicePage/TermsOfServicePage.duck';
import {
  loadData as TransactionPageLoader,
  setInitialValues as TransactionPageInitialValues,
} from './TransactionPage/TransactionPage.duck';
import {
  loadData as TransactionPageV1Loader,
  setInitialValues as TransactionPageV1InitialValues,
} from './TransactionPageV1/TransactionPage.duck';
import { loadData as AnglersPageLoader } from './AnglersPage/AnglersPage.duck';
import { loadData as ArticlesPageLoader } from './ArticlesPage/ArticlesPage.duck';
import { loadData as ArticlePageLoader } from './ArticlePage/ArticlePage.duck';
import { loadData as CorporateandLargeGroupsPageLoader } from './CorporateandLargeGroupsPage/CorporateandLargeGroupsPage.duck';
import { loadData as BookmarkedListingsPageLoader } from './BookmarkedListingsPage/BookmarkedListingsPage.duck';
import { loadData as DiscountCodePageLoader } from './DiscountCodePage/DiscountCodePage.duck';
import { loadData as EditArticlePageLoader } from './EditArticlePage/EditArticlePage.duck';
import { loadData as GoogleCalendarPageLoader } from './GoogleCalendarPage/GoogleCalendarPage.duck';
import { loadData as MyOrdersPageLoader } from './MyOrdersPage/MyOrdersPage.duck';
import { loadData as GiftCardFormPageLoader } from './GiftCardsFormPage/GiftCardFormPage.duck';
import { loadData as SeasonPassPageLoader } from './SeasonPassPage/SeasonPassPage.duck';
import { loadData as GiftCardsTrackingPageLoader } from './GiftCardTrackingPage/GiftCardsTrackingPage.duck';
import { loadData as AssignGiftCardPageLoader } from './AssignGiftCardPage/AssignGiftCardPage.duck';
import { loadData as FeatureControlPageLoader } from './FeatureControlPage/FeatureControlPage.duck';

const getPageDataLoadingAPI = () => {
  return {
    AuthenticationPage: {
      loadData: AuthenticationPageLoader,
    },
    EditArticlePage: {
      loadData: EditArticlePageLoader,
    },
    BookmarkedListingsPage: {
      loadData: BookmarkedListingsPageLoader,
    },
    LandingPage: {
      loadData: LandingPageLoader,
    },
    AnglersPage: {
      loadData: AnglersPageLoader,
    },
    ArticlesPage: {
      loadData: ArticlesPageLoader,
    },
    ArticlePage: {
      loadData: ArticlePageLoader,
    },
    CheckoutPage: {
      setInitialValues: CheckoutPageInitialValues,
    },
    CheckoutPageV1: {
      setInitialValues: CheckoutPageV1InitialValues,
    },
    CMSPage: {
      loadData: CMSPageLoader,
    },
    ContactDetailsPage: {
      loadData: ContactDetailsPageLoader,
    },
    CorporateandLargeGroupsPage: {
      loadData: CorporateandLargeGroupsPageLoader,
    },
    EditListingPage: {
      loadData: EditListingPageLoader,
    },
    EmailVerificationPage: {
      loadData: EmailVerificationPageLoader,
    },
    InboxPage: {
      loadData: InboxPageLoader,
    },
    ListingPage: {
      loadData: ListingPageLoader,
    },
    ListingPageV1: {
      loadData: ListingPageLoaderV1,
    },
    ManageArticlesPage: {
      loadData: ManageArticlesPageLoader,
    },
    ManageListingsPage: {
      loadData: ManageListingsPageLoader,
    },
    PaymentMethodsPage: {
      loadData: PaymentMethodsPageLoader,
    },
    PrivacyPolicyPage: {
      loadData: PrivacyPolicyPageLoader,
    },
    ProfilePage: {
      loadData: ProfilePageLoader,
    },
    SearchPage: {
      loadData: SearchPageLoader,
    },
    StripePayoutPage: {
      loadData: StripePayoutPageLoader,
    },
    TermsOfServicePage: {
      loadData: TermsOfServicePageLoader,
    },
    TransactionPage: {
      loadData: TransactionPageLoader,
      setInitialValues: TransactionPageInitialValues,
    },
    TransactionPageV1: {
      loadData: TransactionPageV1Loader,
      setInitialValues: TransactionPageV1InitialValues,
    },
    DiscountCodePage: {
      loadData: DiscountCodePageLoader,
    },
    GoogleCalendarPage: {
      loadData: GoogleCalendarPageLoader,
    },
    GiftCardFormPage: {
      loadData: GiftCardFormPageLoader,
    },
    MyOrdersPage: {
      loadData: MyOrdersPageLoader,
    },
    SeasonPassPage: {
      loadData: SeasonPassPageLoader,
    },
    GiftCardsTrackingPage: {
      loadData: GiftCardsTrackingPageLoader,
    },
    AssignGiftCardPage: {
      loadData: AssignGiftCardPageLoader,
    },
    FeatureControlPage: {
      loadData: FeatureControlPageLoader,
    },
  };
};

export default getPageDataLoadingAPI;
