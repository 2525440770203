import { USER_TYPES } from '../../config/configDefault';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import {
  GIFT_CARD_PURCHASE_PROCESS_NAME,
  SEASON_PASS_PURCHASE_PROCESS_NAME,
  ZERO_PAYMENT_GIFT_CARD_PURCHASE_PROCESS_NAME,
  ZERO_PAYMENT_SEASON_PASS_PURCHASE_PROCESS_NAME,
} from '../../transactions/transaction';
import { transitions } from '../../transactions/transactionProcessGiftCardPurchase';
import { getPreviousOrders } from '../../util/api';
import { denormalisedResponseEntities } from '../../util/data';
import { storableError } from '../../util/errors';
import { parse } from '../../util/urlHelpers';
// ================ Action types ================ //

export const FETCH_ORDERS_REQUEST = 'app/MyOrdersPage/FETCH_ORDERS_REQUEST';
export const FETCH_ORDERS_SUCCESS = 'app/MyOrdersPage/FETCH_ORDERS_SUCCESS';
export const FETCH_ORDERS_ERROR = 'app/MyOrdersPage/FETCH_ORDERS_ERROR';

export const FETCH_PREVIOUS_ORDERS_REQUEST = 'app/MyOrdersPage/FETCH_PREVIOUS_ORDERS_REQUEST';
export const FETCH_PREVIOUS_ORDERS_SUCCESS = 'app/MyOrdersPage/FETCH_PREVIOUS_ORDERS_SUCCESS';
export const FETCH_PREVIOUS_ORDERS_ERROR = 'app/MyOrdersPage/FETCH_PREVIOUS_ORDERS_ERROR';

const RESULT_PAGE_SIZE = 9;

// ================ Reducer ================ //

const initialState = {
  orders: [],
  previousOrders: [],
  fetchOrdersInProgress: false,
  fetchOrdersError: null,
  pagination: null,
  fetchPreviousOrdersInProgress: false,
  fetchPreviousOrdersError: null,
};

const myOrdersPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_ORDERS_REQUEST:
      return { ...state, fetchOrdersInProgress: true, fetchOrdersError: null };
    case FETCH_ORDERS_SUCCESS:
      return {
        ...state,
        fetchOrdersInProgress: false,
        orders: payload.data,
        pagination: payload.meta,
      };
    case FETCH_ORDERS_ERROR: {
      return {
        ...state,
        fetchOrdersInProgress: false,
        fetchOrdersError: payload,
      };
    }

    case FETCH_PREVIOUS_ORDERS_REQUEST: {
      return { ...state, fetchPreviousOrdersInProgress: true, fetchPreviousOrdersError: null };
    }
    case FETCH_PREVIOUS_ORDERS_SUCCESS:
      return {
        ...state,
        fetchPreviousOrdersInProgress: false,
        previousOrders: payload.data,
      };
    case FETCH_PREVIOUS_ORDERS_ERROR: {
      return {
        ...state,
        fetchPreviousOrdersInProgress: false,
        fetchPreviousOrdersError: payload,
      };
    }

    default:
      return state;
  }
};

export default myOrdersPageReducer;

// ================ Action creators ================ //

export const fetchTransactionRequest = () => ({ type: FETCH_ORDERS_REQUEST });
export const fetchTransactionSuccess = data => ({
  type: FETCH_ORDERS_SUCCESS,
  payload: data,
});
export const fetchTransactionError = error => ({
  type: FETCH_ORDERS_ERROR,
  error: true,
  payload: error,
});

export const fetchPreviousOrdersRequest = () => ({ type: FETCH_PREVIOUS_ORDERS_REQUEST });
export const fetchPreviousOrdersSuccess = data => ({
  type: FETCH_PREVIOUS_ORDERS_SUCCESS,
  payload: data,
});
export const fetchPreviousOrdersError = error => ({
  type: FETCH_PREVIOUS_ORDERS_ERROR,
  error: true,
  payload: error,
});

// ================ Thunks ================ //
export const fetchPreviousOrders = () => (dispatch, getState, sdk) => {
  dispatch(fetchPreviousOrdersRequest());
  return getPreviousOrders()
    .then(response => {
      return dispatch(fetchPreviousOrdersSuccess({ data: response.data }));
    })
    .catch(e => {
      dispatch(fetchPreviousOrdersError(storableError(e)));
      throw e;
    });
};

export const fetchTransaction = page => (dispatch, getState, sdk) => {
  const currentUser = getState().user?.currentUser;

  const isCurrentUserAdminOrLandOwner = () =>
    currentUser?.attributes?.profile?.metadata?.userType === USER_TYPES.admin ||
    currentUser?.attributes?.profile?.metadata?.userType === USER_TYPES.landOwner;

  return sdk.transactions
    .query({
      include: ['listing', 'listing.images', 'provider'],
      processNames: [
        GIFT_CARD_PURCHASE_PROCESS_NAME,
        SEASON_PASS_PURCHASE_PROCESS_NAME,
        ZERO_PAYMENT_GIFT_CARD_PURCHASE_PROCESS_NAME,
        ZERO_PAYMENT_SEASON_PASS_PURCHASE_PROCESS_NAME,
      ],
      lastTransitions: [transitions.AUTO_COMPLETE, transitions.CONFIRM_PAYMENT],
      only: isCurrentUserAdminOrLandOwner() ? 'sale' : 'order',
      page,
      perPage: RESULT_PAGE_SIZE,
    })
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      const entities = denormalisedResponseEntities(response);
      dispatch(fetchTransactionSuccess({ data: entities, meta: response.data.meta }));
      return entities;
    })
    .catch(e => {
      dispatch(fetchTransactionError(storableError(e)));
      throw e;
    });
};

export const loadData = (params, search, config) => dispatch => {
  const queryParams = parse(search);
  const page = queryParams.page || 1;

  return Promise.all([dispatch(fetchTransaction(page)), dispatch(fetchPreviousOrders())]);
};
