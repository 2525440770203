import { getRandomListings, getTopRatedListings, listingReviews } from '../../util/api';
import { convertListingsDataToCardModel, denormalisedResponseEntities } from '../../util/data';
import { storableError } from '../../util/errors';

// ================ Action types ================ //

export const FETCH_RANDOM_LISTINGS_REQUEST = 'app/AnglersPage/FETCH_RANDOM_LISTINGS_REQUEST';
export const FETCH_RANDOM_LISTINGS_SUCCESS = 'app/AnglersPage/FETCH_RANDOM_LISTINGS_SUCCESS';
export const FETCH_RANDOM_LISTINGS_ERROR = 'app/AnglersPage/FETCH_RANDOM_LISTINGS_ERROR';

export const FETCH_TOP_RATED_LISTINGS_REQUEST = 'app/AnglersPage/FETCH_TOP_RATED_LISTINGS_REQUEST';
export const FETCH_TOP_RATED_LISTINGS_SUCCESS = 'app/AnglersPage/FETCH_TOP_RATED_LISTINGS_SUCCESS';
export const FETCH_TOP_RATED_LISTINGS_ERROR = 'app/AnglersPage/FETCH_TOP_RATED_LISTINGS_ERROR';

// ================ Reducer ================ //

const initialState = {
  randomListings: [],
  fetchRandomListingsInProgress: false,
  fetchRandomListingsError: null,
  topRatedListings: [],
  fetchTopRatedListingsInProgress: false,
  fetchTopRatedListingsError: null,
};

const anglersPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_RANDOM_LISTINGS_REQUEST:
      return { ...state, fetchRandomListingsInProgress: true, fetchRandomListingsError: null };
    case FETCH_RANDOM_LISTINGS_SUCCESS: {
      const convertedRandomListings = convertListingsDataToCardModel(payload);
      return {
        ...state,
        fetchRandomListingsInProgress: false,
        randomListings: convertedRandomListings,
      };
    }
    case FETCH_RANDOM_LISTINGS_ERROR:
      return { ...state, fetchRandomListingsInProgress: false, fetchRandomListingsError: payload };

    case FETCH_TOP_RATED_LISTINGS_REQUEST:
      return { ...state, fetchTopRatedListingsInProgress: true, fetchTopRatedListingsError: null };
    case FETCH_TOP_RATED_LISTINGS_SUCCESS: {
      const convertedTopRatedListings = convertListingsDataToCardModel(payload);
      return {
        ...state,
        fetchTopRatedListingsInProgress: false,
        topRatedListings: convertedTopRatedListings,
      };
    }
    case FETCH_TOP_RATED_LISTINGS_ERROR:
      return {
        ...state,
        fetchTopRatedListingsInProgress: false,
        fetchTopRatedListingsError: payload,
      };

    default:
      return state;
  }
};

export default anglersPageReducer;

// ================ Action creators ================ //

export const fetchRandomListingsRequest = () => ({ type: FETCH_RANDOM_LISTINGS_REQUEST });
export const fetchRandomListingsSuccess = listings => ({
  type: FETCH_RANDOM_LISTINGS_SUCCESS,
  payload: listings,
});
export const fetchRandomListingsError = error => ({
  type: FETCH_RANDOM_LISTINGS_ERROR,
  error: true,
  payload: error,
});

export const fetchTopRatedListingsRequest = () => ({ type: FETCH_TOP_RATED_LISTINGS_REQUEST });
export const fetchTopRatedListingsSuccess = listings => ({
  type: FETCH_TOP_RATED_LISTINGS_SUCCESS,
  payload: listings,
});
export const fetchTopRatedListingsError = error => ({
  type: FETCH_TOP_RATED_LISTINGS_ERROR,
  error: true,
  payload: error,
});

// ================ Thunks ================ //

export const fetchRandomListings = () => (dispatch, getState, sdk) => {
  dispatch(fetchRandomListingsRequest());

  return getRandomListings({})
    .then(async response => {
      const entities = denormalisedResponseEntities(response);
      const allIncluded = response.data.included;
      response.data.data.forEach(item =>
        item.relationships?.images?.data.forEach(image => {
          image.included = allIncluded.find(includedItem => includedItem.id.uuid === image.id.uuid);
        })
      );
      dispatch(fetchRandomListingsSuccess(response.data.data || []));
    })
    .catch(e => {
      dispatch(fetchRandomListingsError(storableError(e)));
    });
};

export const fetchTopRatedListings = () => (dispatch, getState, sdk) => {
  dispatch(fetchTopRatedListingsRequest());
  return getTopRatedListings({})
    .then(async response => {
      const allIncluded = response.data.included;
      response.data.data.forEach(item =>
        item.relationships?.images?.data.forEach(image => {
          image.included = allIncluded.find(includedItem => includedItem.id.uuid === image.id.uuid);
        })
      );
      dispatch(fetchTopRatedListingsSuccess(response.data.data || []));
    })
    .catch(e => {
      dispatch(fetchTopRatedListingsError(storableError(e)));
    });
};

export const loadData = (params, search) => dispatch => {
  return Promise.all([dispatch(fetchRandomListings()), dispatch(fetchTopRatedListings())]);
};
