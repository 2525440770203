import { getArticleById, getArticleBySeoTag } from '../../util/api';
import { storableError } from '../../util/errors';

// ================ Action types ================ //

export const FETCH_ARTICLES_REQUEST = 'app/ArticlePage/FETCH_ARTICLES_REQUEST';
export const FETCH_ARTICLES_SUCCESS = 'app/ArticlePage/FETCH_ARTICLES_SUCCESS';
export const FETCH_ARTICLES_ERROR = 'app/ArticlePage/FETCH_ARTICLES_ERROR';

// ================ Reducer ================ //

const initialState = {
  queryInProgress: false,
  queryListingsError: null,
  article: null,
};

const manageArticlePageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_ARTICLES_REQUEST:
      return {
        ...state,
        queryInProgress: true,
      };
    case FETCH_ARTICLES_SUCCESS:
      return {
        ...state,
        article: payload,
        queryInProgress: false,
      };
    case FETCH_ARTICLES_ERROR:
      return { ...state, queryListingsError: payload, queryInProgress: false };

    default:
      return state;
  }
};

export default manageArticlePageReducer;

// ================ Action creators ================ //

export const queryArticleRequest = queryParams => ({
  type: FETCH_ARTICLES_REQUEST,
  payload: { queryParams },
});

export const queryArticleSuccess = payload => ({
  type: FETCH_ARTICLES_SUCCESS,
  payload: payload,
});

export const queryArticleError = e => ({
  type: FETCH_ARTICLES_ERROR,
  error: true,
  payload: e,
});

export const loadData = (params, search) => dispatch => {
  return getArticleBySeoTag({ seoUrl: params.id })
    .then(res => {
      dispatch(queryArticleSuccess(res.data));
    })
    .catch(() => {
      dispatch(queryArticleError(storableError(e)));
    });
};
