import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { types as sdkTypes, createImageVariantConfig } from '../../util/sdkLoader';
import { denormalisedResponseEntities } from '../../util/data';
import { storableError } from '../../util/errors';
import { uniqueId } from 'lodash';
import { getUserSeasonPasses } from '../../util/api';

const { UUID } = sdkTypes;

// ================ Action types ================ //

export const SET_INITIAL_STATE = 'app/ProfilePage/SET_INITIAL_STATE';

export const SHOW_USER_REQUEST = 'app/ProfilePage/SHOW_USER_REQUEST';
export const SHOW_USER_SUCCESS = 'app/ProfilePage/SHOW_USER_SUCCESS';
export const SHOW_USER_ERROR = 'app/ProfilePage/SHOW_USER_ERROR';

export const QUERY_LISTINGS_REQUEST = 'app/ProfilePage/QUERY_LISTINGS_REQUEST';
export const QUERY_LISTINGS_SUCCESS = 'app/ProfilePage/QUERY_LISTINGS_SUCCESS';
export const QUERY_LISTINGS_ERROR = 'app/ProfilePage/QUERY_LISTINGS_ERROR';

export const QUERY_REVIEWS_REQUEST = 'app/ProfilePage/QUERY_REVIEWS_REQUEST';
export const QUERY_REVIEWS_SUCCESS = 'app/ProfilePage/QUERY_REVIEWS_SUCCESS';
export const QUERY_PUSH_REVIEWS_SUCCESS = 'app/ProfilePage/QUERY_PUSH_REVIEWS_SUCCESS';
export const QUERY_REVIEWS_ERROR = 'app/ProfilePage/QUERY_REVIEWS_ERROR';

export const FETCH_CURRENT_USER_SEASON_PASS_REQUEST =
  'app/ProfilePage/FETCH_CURRENT_USER_SEASON_PASS_REQUEST';
export const FETCH_CURRENT_USER_SEASON_PASS_SUCCESS =
  'app/ProfilePage/FETCH_CURRENT_USER_SEASON_PASS_SUCCESS';
export const FETCH_CURRENT_USER_SEASON_PASS_ERROR =
  'app/ProfilePage/FETCH_CURRENT_USER_SEASON_PASS_ERROR';

// ================ Reducer ================ //

const initialState = {
  userId: null,
  userListingRefs: [],
  userShowError: null,
  queryListingsError: null,
  reviews: [],
  queryReviewsError: null,
  getUserSeasonPassInProgress: false,
  getUserSeasonPassError: null,
  usersSeasonPasses: [],
};

export default function profilePageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_STATE:
      return { ...initialState };
    case SHOW_USER_REQUEST:
      return { ...state, userShowError: null, userId: payload.userId };
    case SHOW_USER_SUCCESS:
      return state;
    case SHOW_USER_ERROR:
      return { ...state, userShowError: payload };

    case QUERY_LISTINGS_REQUEST:
      return {
        ...state,

        // Empty listings only when user id changes
        userListingRefs: payload.userId === state.userId ? state.userListingRefs : [],

        queryListingsError: null,
      };
    case QUERY_LISTINGS_SUCCESS:
      return { ...state, userListingRefs: payload.listingRefs };
    case QUERY_LISTINGS_ERROR:
      return { ...state, userListingRefs: [], queryListingsError: payload };
    case QUERY_REVIEWS_REQUEST:
      return { ...state, queryReviewsError: null };
    case QUERY_REVIEWS_SUCCESS:
      return { ...state, reviews: [...payload, ...state.reviews] };
    case QUERY_PUSH_REVIEWS_SUCCESS:
      return { ...state, reviews: [...state.reviews, ...payload] };
    case QUERY_REVIEWS_ERROR:
      return { ...state, reviews: [], queryReviewsError: payload };

    case FETCH_CURRENT_USER_SEASON_PASS_REQUEST:
      return {
        ...state,
        getUserSeasonPassInProgress: true,
        getUserSeasonPassError: null,
      };

    case FETCH_CURRENT_USER_SEASON_PASS_SUCCESS:
      return {
        ...state,
        getUserSeasonPassInProgress: false,
        usersSeasonPasses: payload,
      };

    case FETCH_CURRENT_USER_SEASON_PASS_ERROR:
      return {
        ...state,
        getUserSeasonPassInProgress: false,
        getUserSeasonPassError: payload,
      };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const setInitialState = () => ({
  type: SET_INITIAL_STATE,
});

export const showUserRequest = userId => ({
  type: SHOW_USER_REQUEST,
  payload: { userId },
});

export const showUserSuccess = () => ({
  type: SHOW_USER_SUCCESS,
});

export const showUserError = e => ({
  type: SHOW_USER_ERROR,
  error: true,
  payload: e,
});

export const queryListingsRequest = userId => ({
  type: QUERY_LISTINGS_REQUEST,
  payload: { userId },
});

export const queryListingsSuccess = listingRefs => ({
  type: QUERY_LISTINGS_SUCCESS,
  payload: { listingRefs },
});

export const queryListingsError = e => ({
  type: QUERY_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const queryReviewsRequest = () => ({
  type: QUERY_REVIEWS_REQUEST,
});

export const queryReviewsSuccess = reviews => ({
  type: QUERY_REVIEWS_SUCCESS,
  payload: reviews,
});

export const queryPushReviewsSuccess = reviews => ({
  type: QUERY_PUSH_REVIEWS_SUCCESS,
  payload: reviews,
});

export const queryReviewsError = e => ({
  type: QUERY_REVIEWS_ERROR,
  error: true,
  payload: e,
});

export const fetchCurrentUserSeasonPassRequest = () => ({
  type: FETCH_CURRENT_USER_SEASON_PASS_REQUEST,
});

export const fetchCurrentUserSeasonPassSuccess = payload => ({
  type: FETCH_CURRENT_USER_SEASON_PASS_SUCCESS,
  payload,
});

export const fetchCurrentUserSeasonPassError = e => ({
  type: FETCH_CURRENT_USER_SEASON_PASS_ERROR,
  payload: e,
});

// ================ Thunks ================ //

export const getCurrentUserSeasonPasses = userId => dispatch => {
  dispatch(fetchCurrentUserSeasonPassRequest());

  return getUserSeasonPasses({ userId })
    .then(response => dispatch(fetchCurrentUserSeasonPassSuccess(response.data)))
    .catch(error => dispatch(fetchCurrentUserSeasonPassError(error)));
};

export const queryUserListings = (userId, config) => (dispatch, getState, sdk) => {
  dispatch(queryListingsRequest(userId));

  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const aspectRatio = aspectHeight / aspectWidth;

  return sdk.listings
    .query({
      author_id: userId,
      include: ['author', 'images'],
      'fields.image': [`variants.${variantPrefix}`, `variants.${variantPrefix}-2x`],
      ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
      ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
    })
    .then(response => {
      // Pick only the id and type properties from the response listings
      const listingRefs = response.data.data.map(({ id, type }) => ({ id, type }));

      const reviews = [];
      response.data.data.forEach(element => {
        const userMapper = comment => {
          const authorName = comment.author_name;
          const abbreviatedName =
            authorName.split(' ')?.length > 1
              ? `${authorName.split(' ')[0][0].toUpperCase()}${authorName
                  .split(' ')[1][0]
                  .toUpperCase()}`
              : comment.author_name.substring(0, 2).toUpperCase();

          return {
            attributes: {
              profile: {
                abbreviatedName: abbreviatedName,
                displayName: comment.author_name,
              },
            },
            id: new UUID(`user-${uniqueId()}`),
            type: 'user',
          };
        };

        const reviewMapper = review => {
          if (typeof review === 'undefined') {
            return [];
          }
          return review.comments
            ?.filter(comment => comment.parent_id === '0')
            ?.sort((a, b) => Date.parse(b.date) - Date.parse(a.date))
            ?.map((comment, index) => ({
              id: new UUID(`review-${index}${Math.random()}`),
              attributes: {
                rating: Math.round(Number.parseFloat(comment.rating) ? comment.rating : 1),
                createdAt: new Date(comment.date),
                content: comment.content,
                state: 'public',
                type: 'ofProvider',
              },
              author: userMapper(comment),
            }));
        };
        reviewMapper(element?.attributes?.publicData?.reviewData).forEach(t => reviews.push(t));
      });

      dispatch(queryPushReviewsSuccess(reviews));
      dispatch(addMarketplaceEntities(response));
      dispatch(queryListingsSuccess(listingRefs));
      return response;
    })
    .catch(e => dispatch(queryListingsError(storableError(e))));
};

export const queryUserReviews = userId => (dispatch, getState, sdk) => {
  sdk.reviews
    .query({
      subject_id: userId,
      state: 'public',
      include: ['author', 'author.profileImage'],
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    })
    .then(response => {
      const reviews = denormalisedResponseEntities(response);
      dispatch(queryReviewsSuccess(reviews));
    })
    .catch(e => dispatch(queryReviewsError(e)));
};

export const showUser = userId => (dispatch, getState, sdk) => {
  dispatch(showUserRequest(userId));
  return sdk.users
    .show({
      id: userId,
      include: ['profileImage'],
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    })
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(showUserSuccess());
      return response;
    })
    .catch(e => dispatch(showUserError(storableError(e))));
};

export const loadData = (params, search, config) => (dispatch, getState, sdk) => {
  const userId = new UUID(params.id);

  // Clear state so that previously loaded data is not visible
  // in case this page load fails.
  dispatch(setInitialState());

  return Promise.all([
    dispatch(fetchCurrentUser()),
    dispatch(showUser(userId)),
    dispatch(queryUserListings(userId, config)),
    dispatch(queryUserReviews(userId)),
    dispatch(getCurrentUserSeasonPasses(userId)),
  ]);
};
