import React from 'react';
import { string } from 'prop-types';

const IconCreek = props => {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="22"
    version="1.1"
    viewBox="0 0 16.933 16.933"
  >
    <g>
      <g
        fillOpacity="1"
        fillRule="evenodd"
        stroke="none"
        strokeLinejoin="round"
        strokeOpacity="1"
        strokeWidth="1.058"
        display="inline"
        transform="matrix(1 0 0 1.00003 -60.854 -403.904)"
      >
        <path
          fill="#3297db"
          d="M61.383 409.712v6.35l1.852-1.058 2.117 1.323 2.117-1.323 1.587 1.058 2.117-1.058 2.381 1.058 2.117-1.058 1.587 1.058v-6.35l-1.852-1.058-1.852 1.058-2.116-1.058-1.853 1.058-1.852-1.058-2.116 1.058-2.382-.793z"
          stopColor="#000"
        ></path>
        <g
          fill="#2d3e50"
          strokeDasharray="none"
          strokeDashoffset="0"
          strokeLinecap="round"
          strokeMiterlimit="4"
          clipRule="nonzero"
          color="#000"
          colorInterpolation="sRGB"
          colorInterpolationFilters="linearRGB"
          colorRendering="auto"
          direction="ltr"
          dominantBaseline="auto"
          fontFamily="sans-serif"
          fontSize="medium"
          fontStretch="normal"
          fontStyle="normal"
          fontVariant="normal"
          fontWeight="normal"
          imageRendering="auto"
          letterSpacing="normal"
          shapeRendering="auto"
          textAnchor="start"
          textRendering="auto"
          transform="translate(60.854 123.825)"
          visibility="visible"
          wordSpacing="normal"
          writingMode="lr-tb"
        >
          <path
            d="M2.363 284.3c-.594.02-1.037.35-1.447.63-.41.28-.73.554-.73.554a.53.53 0 00-.059.746.53.53 0 00.746.06s.285-.244.64-.487c.356-.244.838-.444.885-.446.097-.003.373.144.72.41.345.267.754.649 1.38.649.626 0 1.035-.382 1.38-.648.347-.267.623-.414.72-.41.042 0 .291.145.595.408s.668.65 1.274.65.967-.387 1.271-.65c.304-.263.556-.407.598-.409.096-.003.373.144.719.41.346.267.754.649 1.38.649.627 0 1.036-.382 1.381-.648.346-.267.623-.414.72-.41.047.001.528.201.884.445.356.243.64.486.64.486a.53.53 0 00.747-.059.53.53 0 00-.059-.746s-.32-.274-.73-.554-.853-.61-1.448-.63c-.63-.02-1.05.361-1.398.63-.349.268-.635.427-.736.427-.102 0-.388-.159-.737-.427-.349-.269-.767-.65-1.398-.63-.6.02-.953.406-1.254.667-.301.26-.544.39-.58.39-.036 0-.28-.13-.58-.39-.301-.26-.654-.646-1.254-.666-.631-.021-1.05.36-1.399.629-.349.268-.635.427-.736.427-.101 0-.387-.159-.736-.427-.35-.269-.768-.65-1.399-.63z"
            style={{
              lineHeight: "normal",
              fontVariantLigatures: "normal",
              fontVariantPosition: "normal",
              fontVariantCaps: "normal",
              fontVariantNumeric: "normal",
              fontVariantAlternates: "normal",
              fontVariantEastAsian: "normal",
              fontFeatureSettings: "normal",
              fontVariationSettings: "normal",
              WebkitTextIndent: "0",
              textIndent: "0",
              WebkitTextAlign: "start",
              textAlign: "start",
              WebkitTextDecorationLine: "none",
              textDecorationLine: "none",
              WebkitTextDecorationStyle: "solid",
              textDecorationStyle: "solid",
              WebkitTextDecorationColor: "#000000",
              textDecorationColor: "#000000",
              WebkitTextTransform: "none",
              textTransform: "none",
              WebkitTextOrientation: "mixed",
              textOrientation: "mixed",
              whiteSpace: "normal",
              shapePadding: "0",
              shapeMargin: "0",
              inlineSize: "0",
              isolation: "auto",
              mixBlendMode: "normal",
              solidColor: "#000000",
              solidOpacity: "1",
            }}
            baselineShift="baseline"
            display="inline"
            enableBackground="accumulate"
            opacity="1"
            overflow="visible"
            stopColor="#000"
            stopOpacity="1"
            textDecoration="none"
            vectorEffect="none"
          ></path>
          <path
            d="M2.363 287.475c-.594.02-1.037.348-1.447.629-.41.28-.73.556-.73.556a.53.53 0 00-.059.746.53.53 0 00.746.057s.285-.243.64-.486c.356-.244.838-.442.885-.444.097-.003.373.144.72.41.345.267.754.649 1.38.649.626 0 1.035-.382 1.38-.649.347-.266.623-.413.72-.41.042.001.291.145.595.408s.668.65 1.274.65.967-.387 1.271-.65c.304-.263.556-.406.598-.408.096-.003.373.144.719.41.346.267.754.649 1.38.649.627 0 1.036-.382 1.381-.649.346-.266.623-.413.72-.41.047.002.528.2.884.444.356.243.64.486.64.486a.53.53 0 00.747-.057.53.53 0 00-.059-.746s-.32-.276-.73-.556-.853-.61-1.448-.63c-.63-.02-1.05.36-1.398.63-.349.268-.635.43-.736.43-.102 0-.388-.162-.737-.43-.349-.27-.767-.65-1.398-.63-.6.02-.953.406-1.254.667-.301.26-.544.392-.58.392-.036 0-.28-.132-.58-.392-.301-.261-.654-.646-1.254-.666-.631-.021-1.05.36-1.399.629-.349.268-.635.43-.736.43-.101 0-.387-.162-.736-.43-.35-.27-.768-.65-1.399-.63z"
            style={{
              lineHeight: "normal",
              fontVariantLigatures: "normal",
              fontVariantPosition: "normal",
              fontVariantCaps: "normal",
              fontVariantNumeric: "normal",
              fontVariantAlternates: "normal",
              fontVariantEastAsian: "normal",
              fontFeatureSettings: "normal",
              fontVariationSettings: "normal",
              WebkitTextIndent: "0",
              textIndent: "0",
              WebkitTextAlign: "start",
              textAlign: "start",
              WebkitTextDecorationLine: "none",
              textDecorationLine: "none",
              WebkitTextDecorationStyle: "solid",
              textDecorationStyle: "solid",
              WebkitTextDecorationColor: "#000000",
              textDecorationColor: "#000000",
              WebkitTextTransform: "none",
              textTransform: "none",
              WebkitTextOrientation: "mixed",
              textOrientation: "mixed",
              whiteSpace: "normal",
              shapePadding: "0",
              shapeMargin: "0",
              inlineSize: "0",
              isolation: "auto",
              mixBlendMode: "normal",
              solidColor: "#000000",
              solidOpacity: "1",
            }}
            baselineShift="baseline"
            display="inline"
            enableBackground="accumulate"
            opacity="1"
            overflow="visible"
            stopColor="#000"
            stopOpacity="1"
            textDecoration="none"
            vectorEffect="none"
          ></path>
          <path
            d="M2.363 290.65c-.594.02-1.037.349-1.447.63-.41.28-.73.556-.73.556a.53.53 0 00-.059.746.53.53 0 00.746.057s.285-.243.64-.487c.356-.243.838-.441.885-.443.097-.003.373.144.72.41.345.266.754.647 1.38.647.626 0 1.035-.38 1.38-.647.347-.266.623-.413.72-.41.042 0 .291.145.595.408s.668.649 1.274.649.967-.386 1.271-.649c.304-.263.556-.407.598-.408.096-.003.373.144.719.41s.754.647 1.38.647c.627 0 1.036-.38 1.381-.647.346-.266.623-.413.72-.41.047.002.528.2.884.443.356.244.64.487.64.487a.53.53 0 00.747-.057.53.53 0 00-.059-.746s-.32-.276-.73-.557c-.41-.28-.853-.609-1.448-.629-.63-.02-1.05.36-1.398.63-.349.268-.635.427-.736.427-.102 0-.388-.159-.737-.428-.349-.268-.767-.65-1.398-.629-.6.02-.953.406-1.254.666-.301.261-.544.391-.58.391-.036 0-.28-.13-.58-.39-.301-.261-.654-.647-1.254-.667-.631-.02-1.05.36-1.399.63-.349.268-.635.427-.736.427-.101 0-.387-.159-.736-.428-.35-.268-.768-.65-1.399-.629z"
            style={{
              lineHeight: "normal",
              fontVariantLigatures: "normal",
              fontVariantPosition: "normal",
              fontVariantCaps: "normal",
              fontVariantNumeric: "normal",
              fontVariantAlternates: "normal",
              fontVariantEastAsian: "normal",
              fontFeatureSettings: "normal",
              fontVariationSettings: "normal",
              WebkitTextIndent: "0",
              textIndent: "0",
              WebkitTextAlign: "start",
              textAlign: "start",
              WebkitTextDecorationLine: "none",
              textDecorationLine: "none",
              WebkitTextDecorationStyle: "solid",
              textDecorationStyle: "solid",
              WebkitTextDecorationColor: "#000000",
              textDecorationColor: "#000000",
              WebkitTextTransform: "none",
              textTransform: "none",
              WebkitTextOrientation: "mixed",
              textOrientation: "mixed",
              whiteSpace: "normal",
              shapePadding: "0",
              shapeMargin: "0",
              inlineSize: "0",
              isolation: "auto",
              mixBlendMode: "normal",
              solidColor: "#000000",
              solidOpacity: "1",
            }}
            baselineShift="baseline"
            display="inline"
            enableBackground="accumulate"
            opacity="1"
            overflow="visible"
            stopColor="#000"
            stopOpacity="1"
            textDecoration="none"
            vectorEffect="none"
          ></path>
        </g>
      </g>
    </g>
  </svg>
  );
};

IconCreek.defaultProps = { className: null };

IconCreek.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconCreek;
