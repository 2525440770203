import { types as sdkTypes } from '../util/sdkLoader';

const { LatLng, LatLngBounds } = sdkTypes;

// An array of locations to show in the LocationAutocompleteInput when
// the input is in focus but the user hasn't typed in any search yet.
//
// Each item in the array should be an object with a unique `id` (String) and a
// `predictionPlace` (util.types.place) properties.
//
// NOTE: these are highly recommended, since they
//       1) help customers to find relevant locations, and
//       2) reduce the cost of using map providers geocoding API
const defaultLocations = [
  {
    id: 'default-colorado',
    predictionPlace: {
      address: 'Colorado',
      bounds: new LatLngBounds(
        new LatLng(41.003372, -102.041522),
        new LatLng(36.992461, -109.060187)
      ),
    },
  },
  {
    id: 'default-wyoming',
    predictionPlace: {
      address: 'Wyoming',
      bounds: new LatLngBounds(
        new LatLng(45.005815, -104.052245),
        new LatLng(40.994804, -111.054556)
      ),
    },
  },
  {
    id: 'default-montana',
    predictionPlace: {
      address: 'Montana',
      bounds: new LatLngBounds(
        new LatLng(49.0011, -104.039694),
        new LatLng(44.357936, -116.049108)
      ),
    },
  },
  {
    id: 'default-oregon',
    predictionPlace: {
      address: 'Oregon',
      bounds: new LatLngBounds(
        new LatLng(46.301396, -116.463407),
        new LatLng(41.9676592, -124.7060599)
      ),
    },
  },
  {
    id: 'default-idaho',
    predictionPlace: {
      address: 'Idaho',
      bounds: new LatLngBounds(
        new LatLng(49.000846, -111.043547),
        new LatLng(41.988182, -117.243027)
      ),
    },
  },
  {
    id: 'default-california',
    predictionPlace: {
      address: 'California',
      bounds: new LatLngBounds(
        new LatLng(42.009503, -114.131211),
        new LatLng(32.534251, -124.5129241)
      ),
    },
  },
  {
    id: 'default-washington',
    predictionPlace: {
      address: 'Washington',
      bounds: new LatLngBounds(
        new LatLng(49.0121481, -116.916071),
        new LatLng(45.543541, -124.8616723)
      ),
    },
  },
];
export default defaultLocations;
