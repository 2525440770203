import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';

import css from './FrequentlyAskedQuestions.module.css';
import IconCollapse from '../IconCollapse/IconCollapse';
import { object } from 'prop-types';

const FrequentlyAskedQuestions = props => {
  const { items, backgroundColor, backgroundColorCollapse, title } = props;

  const divRef = useRef(null);
  const [isExpanded, setIsExpanded] = useState(Array(items.length).fill(false));

  const handleDivClick = index => {
    setIsExpanded(prevExpanded => {
      const newExpanded = [...prevExpanded];
      newExpanded[index] = !newExpanded[index];
      return newExpanded;
    });
  };
  const defaultTitle = (
    <p className={css.title}>
      Frequently asked <span style={{ color: 'var(--colorBlueButton)' }}>questions.</span>
    </p>
  );
  return (
    <div id="listing-faqs" className={css.root} style={{ backgroundColor }}>
      <div className={css.container}>
        {title ? title : defaultTitle}
        <div style={{ backgroundColor: backgroundColorCollapse }} className={css.collapseContainer}>
          <ul className={css.collapseList}>
            {items
              .filter(i => i.question !== 'Disclaimer')
              .map((item, index) => (
                <li
                  key={index}
                  className={isExpanded[index] ? css.collapsed : ''}
                  onClick={() => handleDivClick(index)}
                >
                  <button>
                    <IconCollapse className={!isExpanded[index] ? css.rotated : ''} />
                  </button>
                  <div className={css.collapseListContentDiv} ref={divRef}>
                    <p className={css.question}>{item.question}</p>
                    {isExpanded[index] && <p className={css.answer}>{item.answer}</p>}
                  </div>
                </li>
              ))}
          </ul>
          {items.find(i => i.question === 'Disclaimer') ? (
            <div>
              <p className={css.disclaimerText}>
                <span>
                  Disclaimer: Passes do not include lodging and camping fees, which are available at
                  an additional cost at certain properties.
                </span>
              </p>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

FrequentlyAskedQuestions.defaultProps = {
  rootClassName: null,
  className: null,
  backgroundColorCollapse: '#f8f9fa',
  backgroundColor: 'white',
  items: [],
  title: '',
};

const { string, arrayOf } = PropTypes;

FrequentlyAskedQuestions.propTypes = {
  rootClassName: string,
  className: string,
  backgroundColorCollapse: string,
  backgroundColor: string,
  items: arrayOf(object).isRequired,
  title: string,
};

export default FrequentlyAskedQuestions;
