import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';
import css from './IconCollapse.module.css';

const IconCollapse = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="8"
      viewBox="0 0 15 8"
      fill="none"
    >
      <path
        id="Vector"
        d="M7.51746 1.70988L5.54153 3.60013L2.39169 6.61967C1.98213 7.01231 1.58284 7.4171 1.15959 7.79029C0.714664 8.18293 0.148808 8.00059 0.018754 7.4402C-0.0109701 7.31119 -0.00526619 7.1758 0.0351868 7.05016C0.0756397 6.92451 0.149136 6.8139 0.246921 6.73151C0.762578 6.2242 1.28128 5.72013 1.80302 5.21931C2.85943 4.20469 3.91698 3.1917 4.97568 2.18032C5.6385 1.54578 6.29676 0.905162 6.96871 0.280347C7.37029 -0.0940564 7.68516 -0.0928408 8.07761 0.280347C9.47399 1.6175 10.8673 2.95952 12.2576 4.30639L14.6625 6.62575C14.9089 6.86279 15.0789 7.1363 14.9625 7.50462C14.7983 8.02247 14.2575 8.15983 13.8308 7.78178C13.4441 7.43898 13.079 7.07066 12.706 6.71084C11.1651 5.227 9.6242 3.74236 8.08331 2.2569C7.89507 2.08429 7.71596 1.90559 7.51746 1.70988Z"
        fill="#0094FF"
      />
    </svg>
  );
};

IconCollapse.defaultProps = { className: null };

IconCollapse.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconCollapse;
