import React from 'react';
import { string } from 'prop-types';

const IconTentCamping = props => {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="22"
    fill="none"
    viewBox="0 0 64 64"
  >
    <path
      fill="url(#paint0_linear)"
      d="M52.71 53.4l2.91-.57L34 19.82v-2.38-.16a2.002 2.002 0 00-4 .2 59.1 59.1 0 001.83 12.15 44.998 44.998 0 004.6 11.32 29.999 29.999 0 007.95 9.17c.3.22.62.43.93.64a19.727 19.727 0 003.92 2l1.22 1.09 1.57-.31c1.168.237 2.359.34 3.55.31a15.68 15.68 0 01-2.86-.45z"
    ></path>
    <path
      fill="url(#paint1_linear)"
      d="M31.93 15.35A2 2 0 0030 17.41v2.58l-1.44 1.23L7.92 52.58l.92 1.17 4.39-1.29-.08.23h.21a16.111 16.111 0 01-5 1.1 16.572 16.572 0 006.13-1h.46l.61-.44a20.144 20.144 0 004-2.32 30 30 0 007.95-9.17 44.998 44.998 0 004.6-11.32A59.103 59.103 0 0034 17.44v-.17a2 2 0 00-2.07-1.92z"
    ></path>
    <path
      fill="url(#paint2_linear)"
      d="M57.3 52.74l-22.71-35 4.23-6.52a2.002 2.002 0 10-3.36-2.18L32 14.42l-3.47-5.34a2.002 2.002 0 10-3.36 2.18l4.23 6.52L6.7 52.74a2 2 0 001.68 3.09h47.25a2 2 0 001.68-3.09h-.01zm-45.25-.91L32 21.12l19.94 30.71H12.05z"
    ></path>
    <path
      fill="url(#paint3_linear)"
      d="M60 55.83H4a2 2 0 110-4h56a2 2 0 010 4z"
    ></path>
    <path
      fill="url(#paint4_linear)"
      d="M61.08 15v-.09a2.005 2.005 0 00-.07-.29s0-.09-.05-.13a2.003 2.003 0 00-.1-.23l-.06-.09a1.999 1.999 0 00-.17-.23 2.006 2.006 0 00-.24-.23 1.997 1.997 0 00-.28-.18 2.002 2.002 0 00-.47-.16h-.14a1.944 1.944 0 00-.51 0c-.173.01-.347.01-.52 0a5.64 5.64 0 01-5.77-5.75A5.46 5.46 0 0153.36 5c.066-.118.12-.242.16-.37v-.09c.028-.098.048-.199.06-.3a1.995 1.995 0 000-.4v-.1a1.997 1.997 0 00-.06-.28 2.007 2.007 0 00-.08-.19c0-.06 0-.12-.07-.17-.07-.05-.07-.11-.11-.16-.04-.05-.07-.11-.11-.16L53 2.66l-.16-.16-.11-.08a2.011 2.011 0 00-.2-.14h-.08a2.001 2.001 0 00-.42-.15h-.17a2 2 0 00-.49 0 9.62 9.62 0 001 19.2 9.529 9.529 0 008.48-5.11c.062-.118.112-.242.15-.37.036-.127.06-.258.07-.39a2 2 0 00.01-.46z"
    ></path>
    <path
      fill="#000"
      d="M44.75 11.62a9.58 9.58 0 017.62-9.4 2.1 2.1 0 00-.31-.11h-.17a2 2 0 00-.49 0 9.62 9.62 0 001 19.2c.31 0 .62 0 .93-.06a9.64 9.64 0 01-8.58-9.63z"
      opacity="0.4"
    ></path>
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="30"
        x2="55.63"
        y1="34.59"
        y2="34.59"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0FC000"></stop>
        <stop offset="0.28" stopColor="#0BA809"></stop>
        <stop offset="0.87" stopColor="#026B21"></stop>
        <stop offset="1" stopColor="#005C27"></stop>
      </linearGradient>
      <linearGradient
        id="paint1_linear"
        x1="214.474"
        x2="894.64"
        y1="1345.24"
        y2="1345.24"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0FC000"></stop>
        <stop offset="0.28" stopColor="#0BA809"></stop>
        <stop offset="0.87" stopColor="#026B21"></stop>
        <stop offset="1" stopColor="#005C27"></stop>
      </linearGradient>
      <linearGradient
        id="paint2_linear"
        x1="332.879"
        x2="2960.28"
        y1="1534.61"
        y2="1534.61"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0FC000"></stop>
        <stop offset="0.28" stopColor="#0BA809"></stop>
        <stop offset="0.87" stopColor="#026B21"></stop>
        <stop offset="1" stopColor="#005C27"></stop>
      </linearGradient>
      <linearGradient
        id="paint3_linear"
        x1="2"
        x2="62"
        y1="53.83"
        y2="53.83"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#31446D"></stop>
        <stop offset="0.41" stopColor="#243355"></stop>
        <stop offset="1" stopColor="#0F152C"></stop>
      </linearGradient>
      <linearGradient
        id="paint4_linear"
        x1="42.75"
        x2="61.09"
        y1="11.65"
        y2="11.65"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FC9512"></stop>
        <stop offset="0.21" stopColor="#FD7B11"></stop>
        <stop offset="0.75" stopColor="#FE3E0E"></stop>
        <stop offset="1" stopColor="#FF260D"></stop>
      </linearGradient>
    </defs>
  </svg>
  );
};

IconTentCamping.defaultProps = { className: null };

IconTentCamping.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconTentCamping;
