import React from 'react';
import { string } from 'prop-types';

const IconFish = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="63"
      height="66"
      version="1"
      viewBox="0 0 63 66"
      color="#17304a"
      fill="#17304a"
    >
      <path
        d="M75 619c-16-5-36-16-42-26-20-26-16-80 7-103 11-11 20-26 20-34 0-7 9-20 21-27 20-14 20-15 3-61-17-45-17-48 1-68 10-11 26-20 36-20 21 0 49 26 49 46 0 21-21 17-27-6s-26-26-42-6c-9 11-10 20-1 36 13 24 17 111 6 128-4 7-15 12-25 12-26 0-41 20-41 57 0 62 56 70 221 28 81-21 118-26 167-21 62 5 182 50 182 67 0 12-24 11-36-1-18-18-127-50-171-50-25 0-88 11-141 24-130 32-152 35-187 25z"
        transform="matrix(.1 0 0 -.1 0 66)"
      ></path>
      <path
        d="M519 465c-3-2-53-9-110-14-82-7-117-15-157-35-57-29-63-40-30-57l22-12-28-22c-24-19-25-23-11-29 9-3 37-6 61-6 44 0 44 0 29-23-8-13-12-27-8-30 15-16 57-5 81 21 23 24 32 27 61 21 109-23 32-139-81-123l-43 6 35-31 35-30-18-31c-11-17-16-34-13-37 7-8 156 90 189 125 38 40 57 93 64 181 8 109 1 131-41 131-18 0-35-2-37-5zm61-15c0-5-17-10-37-9-34 1-35 2-13 9 36 11 50 11 50 0zm-82-40c95-58 95-172-2-256-48-42-113-84-102-65 4 7 2 18-4 26-10 12-9 16 6 21 59 19 76 30 90 56 9 17 13 41 10 54-11 42-48 57-157 62-77 4-97 7-89 17 11 14 3 45-11 45-21 0-7 17 26 33 52 25 75 22 75-9 0-14-5-34-10-45-6-10-7-19-2-19 18 0 32 26 32 62v38h53c34 0 63-7 85-20zm72 10c-19-13-30-13-30 0 0 6 10 10 23 10 18 0 19-2 7-10zM350 265c-7-8-20-15-29-15-12 0-10 5 9 20 28 21 39 18 20-5z"
        transform="matrix(.1 0 0 -.1 0 66)"
      ></path>
      <path
        d="M272 378c5-15 28-18 29-3 0 6-7 12-17 13-10 3-15-1-12-10z"
        transform="matrix(.1 0 0 -.1 0 66)"
      ></path>
    </svg>
  );
};

IconFish.defaultProps = { className: null };

IconFish.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconFish;
