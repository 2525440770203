import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { ACCOUNT_SETTINGS_PAGES } from '../../routing/routeConfiguration';
import { LinkTabNavHorizontal } from '../../components';

import css from './UserNav.module.css';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { ensureCurrentUser, ensureUser } from '../../util/data';
import { USER_TYPES } from '../../config/configDefault';

const UserNav = props => {
  const { className, rootClassName, currentPage, currentUser, width, position } = props;
  const classes = classNames(rootClassName || css.root, className);

  const user = ensureCurrentUser(currentUser);
  const ensuredUser = ensureUser(currentUser);
  const userType = ensuredUser.attributes?.profile?.metadata?.userType ?? '';
  const hasAuthority = userType === USER_TYPES.admin;

  const isCurrentUserAdminOrLandOwner = () =>
    currentUser?.attributes?.profile?.metadata?.userType === USER_TYPES.admin ||
    currentUser?.attributes?.profile?.metadata?.userType === USER_TYPES.landOwner;

  const tabs = [
    {
      text: <FormattedMessage id="UserNav.yourListings" />,
      selected: currentPage === 'ManageListingsPage',
      linkProps: {
        name: 'ManageListingsPage',
      },
      auth: hasAuthority,
    },
    {
      text: <FormattedMessage id="UserNav.myOrdersPage" />,
      selected: currentPage === 'MyOrdersPage',
      disabled: false,
      linkProps: {
        name: 'MyOrdersPage',
      },
      auth: true,
    },
    {
      text: <FormattedMessage id="UserNav.giftCardTrackingPage" />,
      selected: currentPage === 'GiftCardsTrackingPage',
      disabled: false,
      linkProps: {
        name: 'GiftCardsTrackingPage',
      },
      auth: true,
    },
    {
      text: <FormattedMessage id="TopbarDesktop.inbox" />,
      selected: currentPage === 'InboxPage',
      disabled: false,
      linkProps: {
        name: 'InboxPage',
        params: { tab: isCurrentUserAdminOrLandOwner() ? 'sales' : 'orders' },
      },
      auth: true,
    },
    {
      text: <FormattedMessage id="TopbarDesktop.MyArticlesLink" />,
      selected: currentPage === 'ManageArticlesPage',
      linkProps: {
        name: 'ManageArticlesPage',
      },
      auth: hasAuthority,
    },
    {
      text: <FormattedMessage id="TopbarDesktop.assignGiftCard" />,
      selected: currentPage === 'AssignGiftCardPage',
      linkProps: {
        name: 'AssignGiftCardPage',
      },
      auth: hasAuthority,
    },
    {
      text: <FormattedMessage id="TopbarDesktop.BookmarkedListingsLink" />,
      selected: currentPage === 'BookmarkedListingsPage',
      linkProps: {
        name: 'BookmarkedListingsPage',
      },
      auth: true,
    },
    {
      isUserNav: true,
      text: isCurrentUserAdminOrLandOwner() ? (
        <FormattedMessage id="TopbarMobileMenu.reviewsLink" />
      ) : (
        <FormattedMessage id="TopbarDesktop.yourProfile" />
      ),
      selected: currentPage === 'ProfilePage',
      linkProps: {
        name: 'ProfilePage',
        params: { id: user?.id?.uuid },
      },
      auth: true,
    },
    {
      text: <FormattedMessage id="UserNav.variablePricing" />,
      selected: currentPage === 'VariablePricingPage',
      disabled: false,
      linkProps: {
        name: 'VariablePricingPage',
      },
      auth: hasAuthority,
    },
    {
      text: <FormattedMessage id="Discounts" />,
      selected: currentPage === 'DiscountCodePage',
      disabled: false,
      linkProps: {
        name: 'DiscountCodePage',
      },
      auth: hasAuthority,
    },
    {
      text: <FormattedMessage id="UserNav.profileSettings" />,
      selected: currentPage === 'ProfileSettingsPage',
      disabled: false,
      linkProps: {
        name: 'ProfileSettingsPage',
      },
      auth: true,
    },
    {
      text: <FormattedMessage id="UserNav.accountSettings" />,
      selected: ACCOUNT_SETTINGS_PAGES.includes(currentPage),
      disabled: false,
      linkProps: {
        name: 'ContactDetailsPage',
      },
      auth: true,
    },
  ];

  if (!user.id) {
    tabs.splice(
      tabs.findIndex(tab => tab.isUserNav),
      1
    );
  }

  return (
    <LinkTabNavHorizontal
      position={position}
      width={width}
      className={classes}
      tabRootClassName={css.tab}
      tabs={tabs}
      skin="dark"
    />
  );
};

const mapStateToProps = state => {
  const { currentUser } = state.user;

  return {
    currentUser,
  };
};

UserNav.defaultProps = {
  className: null,
  rootClassName: null,
  width: null,
  position: null,
};

const { string } = PropTypes;

UserNav.propTypes = {
  className: string,
  rootClassName: string,
  currentPage: string.isRequired,
  position: string,
  width: string,
};

const UserNavComponent = compose(connect(mapStateToProps))(UserNav);

export default UserNavComponent;
