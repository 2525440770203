import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { getGiftCardOrderHistory, getUserGiftCards } from '../../util/api';
import { denormalisedResponseEntities } from '../../util/data';
import { storableError } from '../../util/errors';
import { parse } from '../../util/urlHelpers';

// ================ Action types ================ //

export const FETCH_TRANSACTION_REQUEST = 'app/GiftCardsTrackingPage/FETCH_TRANSACTION_REQUEST';
export const FETCH_TRANSACTION_SUCCESS = 'app/GiftCardsTrackingPage/FETCH_TRANSACTION_SUCCESS';
export const FETCH_TRANSACTION_ERROR = 'app/GiftCardsTrackingPage/FETCH_TRANSACTION_ERROR';

export const FETCH_GIFT_CARDS_REQUEST = 'app/GiftCardsTrackingPage/FETCH_GIFT_CARDS_REQUEST';
export const FETCH_GIFT_CARDS_SUCCESS = 'app/GiftCardsTrackingPage/FETCH_GIFT_CARDS_SUCCESS';
export const FETCH_GIFT_CARDS_ERROR = 'app/GiftCardsTrackingPage/FETCH_GIFT_CARDS_ERROR';

export const CHANGE_SELECTED_GIFT_CARD = 'app/GiftCardsTrackingPage/CHANGE_SELECTED_GIFT_CARD';

// ================ Reducer ================ //
const RESULT_PAGE_SIZE = 10;

const initialState = {
  transactionData: [],
  transactionDataInProgress: false,
  transactionDataError: null,
  giftCardData: [],
  giftCardDataInProgress: false,
  giftCardDataError: null,
  pagination: null,
  selectedGiftCard: '',
};

const giftCardsTrackingPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_TRANSACTION_REQUEST:
      return { ...state, transactionDataInProgress: true, transactionDataError: null };
    case FETCH_TRANSACTION_SUCCESS: {
      return {
        ...state,
        transactionDataInProgress: false,
        transactionData: payload.data,
        pagination: payload.meta,
      };
    }
    case FETCH_TRANSACTION_ERROR:
      return { ...state, transactionDataInProgress: false, transactionDataError: payload };
    case FETCH_GIFT_CARDS_REQUEST:
      return { ...state, giftCardDataInProgress: true, giftCardDataError: null };
    case FETCH_GIFT_CARDS_SUCCESS: {
      return {
        ...state,
        giftCardDataInProgress: false,
        giftCardData: payload,
      };
    }
    case FETCH_GIFT_CARDS_ERROR:
      return { ...state, giftCardDataInProgress: false, giftCardDataError: payload };

    case CHANGE_SELECTED_GIFT_CARD:
      return { ...state, selectedGiftCard: payload };

    default:
      return state;
  }
};

export default giftCardsTrackingPageReducer;

// ================ Action creators ================ //

export const fetchTransactionRequest = () => ({ type: FETCH_TRANSACTION_REQUEST });
export const fetchTransactionSuccess = data => ({
  type: FETCH_TRANSACTION_SUCCESS,
  payload: data,
});
export const fetchTransactionError = error => ({
  type: FETCH_TRANSACTION_ERROR,
  error: true,
  payload: error,
});

export const fetchGiftCardRequest = () => ({ type: FETCH_GIFT_CARDS_REQUEST });
export const fetchGiftCardSuccess = data => ({
  type: FETCH_GIFT_CARDS_SUCCESS,
  payload: data,
});
export const fetchGiftCardError = error => ({
  type: FETCH_GIFT_CARDS_ERROR,
  error: true,
  payload: error,
});

export const changeSelectedGiftCard = payload => ({
  type: CHANGE_SELECTED_GIFT_CARD,
  payload,
});
// ================ Thunks ================ //

//TODO: This place will be organized according to the BE service to be performed. In this section, transactions made with gift cards should be reflected.:
//Note:Let's pay attention to sending the pagination in this section.

// export const fetchTransactions = page => (dispatch, getState, sdk) => {
//   dispatch(fetchTransactionRequest());
//   const user = getState().user.currentUser;
//   return sdk.transactions
//     .query({
//       include: [
//         'listing',
//         'listing.images',
//         'customer',
//         'customer.profileImage',
//         'provider',
//         'provider.profileImage',
//         'listing',
//         'listing.currentStock',
//         'booking',
//       ],
//       //   processNames: 'gift-card-purchase',
//       lastTransitions: [
//         'transition/auto-complete',
//         'transition/confirm-payment',
//         'transition/request-payment',
//       ],
//       userId: user?.id.uuid,
//       page,
//       perPage: RESULT_PAGE_SIZE,
//     })
//     .then(response => {
//       dispatch(addMarketplaceEntities(response));
//       const entities = denormalisedResponseEntities(response);
//       dispatch(fetchTransactionSuccess({ data: entities, meta: response.data.meta }));
//       return entities;
//     })
//     .catch(e => {
//       dispatch(fetchTransactionError(storableError(e)));
//       throw e;
//     });
// };

export const handleSelectedGiftCardChange = code => dispatch => {
  dispatch(fetchTransactionRequest());
  return getGiftCardOrderHistory({ code })
    .then(response => {
      dispatch(changeSelectedGiftCard(code));
      dispatch(fetchTransactionSuccess({ data: response.data, meta: response.data.meta }));
      return response.data;
    })
    .catch(e => {
      dispatch(fetchTransactionError(storableError(e)));
      throw e;
    });
};

export const fetchGiftCards = () => (dispatch, getState, sdk) => {
  return getUserGiftCards({}).then(response => {
    return dispatch(fetchGiftCardSuccess(response.data));
  });
};

export const loadData = (params, search, config) => dispatch => {
  const queryParams = parse(search);
  const page = queryParams.page || 1;
  return Promise.all([dispatch(handleSelectedGiftCardChange()), dispatch(fetchGiftCards())]);
};
