import React from 'react';
import PropTypes, { object, bool } from 'prop-types';
import Slider from 'react-slick';

import ReviewRating from '../ReviewRating/ReviewRating';
import css from './ReviewsGrid.module.css';
import { NamedLink } from '../../components';
import { createSlug } from '../../util/urlHelpers';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';

const ReviewsGrid = props => {
  const {
    cardsData,
    title,
    alignItems,
    contentTitle,
    backgroundColor,
    textAlign,
    titleColor,
    backgroundImage,
    content,
    rating,
    backgroundColorRoot,
    intl,
    withReview,
  } = props;

  const availabilityBadgeText = intl.formatMessage({
    id: 'ReviewsGridComponent.campingAvailability',
  });

  const gridContainerStyle = {
    backgroundImage: backgroundImage ? `url(${backgroundImage})` : 'none',
  };
  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: cardsData.length > 3 ? 3 : cardsData.length,
    slidesToScroll: 1,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          arrows: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          arrows: true,
        },
      },
    ],
  };

  return (
    <div style={{ backgroundColor: backgroundColorRoot }}>
      <div className={css.root}>
        <p style={{ textAlign: textAlign, color: titleColor }} className={css.title}>
          {title}
        </p>
        <div className={css.sliderContainer}>
          <Slider {...settings}>
            {cardsData.map(item => {
              const maxCharacterCount = 30;
              const maxCharacterCountForLocation = 36;

              const truncatedText =
                item?.contentTitle.length > maxCharacterCount
                  ? `${item?.contentTitle.slice(0, maxCharacterCount)}..`
                  : item?.contentTitle;

              const truncatedLocation =
                item?.location?.length > maxCharacterCountForLocation
                  ? `${item.location.slice(0, maxCharacterCountForLocation)}..`
                  : item.location;

              return (
                <NamedLink
                  className={css.namedListingLink}
                  key={item.listingId}
                  name="ListingPage"
                  params={{ id: item.listingId, slug: createSlug(item?.contentTitle || '') }}
                >
                  <div className={css.sectionItem}>
                    <div className={css.itemContainer} style={{ backgroundColor: backgroundColor }}>
                      {item.backgroundImage && (
                        <div className={css.imgContainer}>
                          <div
                            className={`${css.image} ${withReview ? css.withReview : css.noReview}`}
                            style={{
                              ...gridContainerStyle,
                              backgroundImage: `url(${item.backgroundImage})`,
                            }}
                          ></div>
                          {item.campingAvailable && (
                            <div className={css.campingBadge}>{availabilityBadgeText}</div>
                          )}
                        </div>
                      )}
                      <div className={css.contentContainer}>
                        <div className={css.titleContainer}>
                          <h2 className={css.contentTitle}>{truncatedText}</h2>
                          <h6 className={css.location}>{truncatedLocation}</h6>
                        </div>

                        {withReview && typeof item.content !== 'undefined' ? (
                          <>
                            <p className={css.content}>{item.content}</p>
                            <ReviewRating
                              reviewStarClassName={css.reviewStar}
                              className={css.reviewStars}
                              rating={item.rating}
                            />
                          </>
                        ) : (
                          <>
                            <p className={css.content}>
                              <FormattedMessage id="ReviewGrid.noReview" />
                            </p>
                            <ReviewRating
                              reviewStarClassName={css.reviewStar}
                              className={css.reviewStars}
                              rating={0}
                            />
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </NamedLink>
              );
            })}
          </Slider>
        </div>
      </div>
    </div>
  );
};

ReviewsGrid.defaultProps = {
  rootClassName: null,
  className: null,
  backgroundColor: 'white',
  backgroundColorRoot: '#E7F1F7',
  title: null,
  titleColor: 'var(--colorBlueTextDark)',
  textAlign: 'left',
  alignItems: null,
  contentTitle: 'Diamond Arrow | Carbondale, CO',
  backgroundImage: null,
  content: null,
  reviews: null,
  cardsData: [],
  withReview: true,
};

const { string, number, arrayOf } = PropTypes;

ReviewsGrid.propTypes = {
  rootClassName: string,
  className: string,
  backgroundColor: string,
  backgroundColorRoot: string,
  title: string,
  textAlign: string,
  alignItems: string,
  titleColor: string,
  contentTitle: string,
  backgroundImage: string,
  content: string,
  rating: number,
  cardsData: arrayOf(object).isRequired,
  intl: intlShape.isRequired,
  withReview: bool,
};

export default injectIntl(ReviewsGrid);
