import React from 'react';
import { bool, string } from 'prop-types';
import { isInclusivelyAfterDay, isInclusivelyBeforeDay } from 'react-dates';
import { Field } from 'react-final-form';
import moment from 'moment';

import { useConfiguration } from '../../context/configurationContext';
import DateRangeController from './DateRangeController';

const component = props => {
  const { input, controllerRef, ...rest } = props;
  const { type, checked, ...restOfInput } = input;
  return <DateRangeController ref={controllerRef} {...restOfInput} {...rest} />;
};

const FieldDateRangeController = props => {
  const config = useConfiguration();
  const { disableOutsideRangeControl, isOutsideRange, firstDayOfWeek, ...rest } = props;
  // Outside range -><- today ... today+available days -1 -><- outside range
  const defaultIsOutSideRange = day => {
    const endOfRange = config.stripe?.dayCountAvailableForBooking - 1;
    return (
      !isInclusivelyAfterDay(day, moment()) ||
      !isInclusivelyBeforeDay(day, moment().add(endOfRange, 'days'))
    );
  };
  const defaultFirstDayOfWeek = config.localization.firstDayOfWeek;
  const effectiveFirstDayOfWeek =
    firstDayOfWeek || firstDayOfWeek === 0 ? firstDayOfWeek : defaultFirstDayOfWeek;

  return (
    <Field
      component={component}
      isOutsideRange={
        disableOutsideRangeControl ? () => false : isOutsideRange || defaultIsOutSideRange
      }
      firstDayOfWeek={effectiveFirstDayOfWeek}
      {...rest}
      dates={props.value}
    />
  );
};

FieldDateRangeController.defaultProps = {
  rootClassName: null,
  className: null,
  disableOutsideRangeControl: false,
};

FieldDateRangeController.propTypes = {
  rootClassName: string,
  className: string,
  disableOutsideRangeControl: bool,
};

export default FieldDateRangeController;
